import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { io } from "socket.io-client";
import { API } from "../../service/API";
import { useDispatch, useSelector } from "react-redux";
import Timer from "./components/Timer";
import CasinoBetModal from "./CasinoBetModal";
import CasinoPlacedBet from "./components/CasinoPlaceBet";

export default function BTable() {
  const id = 8;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const resultMapping = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
  };
  // console.log("results", results);

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));
        // console.log("parsed", parsedData[0]);
        console.log("Btable", parsedData[0].reqResponse);
        // console.log("status", reqRes?.data?.data?.t2[0]?.gstatus);

        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      betDetails.nat === filteredData[0].nat //&&
        ? // betDetails.multipler === filteredData[0].rate
          setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  // console.log("history", history);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };
  const [selectedTab, setSelectedTab] = useState("game");

  return (
    <div className="center-main-container casino-page ng-tns-c1798067373-0 ng-star-inserted">
      <div className="center-container ng-tns-c1798067373-0">
        <div className="casino-page-container ng-tns-c1798067373-0 bollywood">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-0">
            <ul className="nav nav-tabs menu-tabs ng-tns-c1798067373-0">
              <li
                onClick={() => setSelectedTab("game")}
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  className={` ng-tns-c1798067373-0
                      ${selectedTab === "game" && "active"}
                      nav-link`}
                >
                  {name}
                </div>
              </li>
              <li className="nav-item ng-tns-c1798067373-0">
                <div
                  onClick={() => setSelectedTab("placedBet")}
                  className={` ng-tns-c1798067373-0 
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                >
                  {" "}
                  Placed Bet ({history?.length})
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c1798067373-0">
              <span className="casino-rid ng-tns-c1798067373-0">
                <a className="d-block ng-tns-c1798067373-0">
                  <small className="ng-tns-c1798067373-0">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c1798067373-0 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c1798067373-0">
            <span className="casino-name ng-tns-c1798067373-0">
              {" "}
              Bollywood Table
              <a className="ms-1 d-xl-inline d-none ng-tns-c1798067373-0">
                <small className="ng-tns-c1798067373-0">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-0">
              <small className="ng-tns-c1798067373-0">
                Round ID:{" "}
                <span className="ng-tns-c1798067373-0 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </small>
              <span className="ng-tns-c1798067373-0 ng-star-inserted">
                {" "}
                | Min: 100 | Max: 300000
              </span>
            </span>
          </div>
          {selectedTab === "game" ? (
            <div className="ng-tns-c1798067373-0 ng-star-inserted">
              <div className="casino-video ng-tns-c1798067373-0">
                <div className="video-box-container ng-tns-c1798067373-0">
                  <div className="casino-video-box ng-tns-c1798067373-0 ng-star-inserted">
                    {/* <iframe
                      className="ng-tns-c1798067373-0"
                      src={CASINO_VIDEO_URL + CASINO_VIDEO.bollyWoodTable}
                    /> */}
                     <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                  </div>
                </div>
                <div className="ng-tns-c1798067373-0 ng-star-inserted">
                  <div className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted">
                    <h5 className="ng-tns-c1798067373-0">Card</h5>
                    <div className="flip-card-container ng-tns-c1798067373-0">
                      <div className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted">
                        <img
                          className="ng-tns-c1798067373-0"
                          src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${reqRes?.data?.data?.t1[0]?.C1}.webp`}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
              </div>

              <div className="casino-detail ng-tns-c1798067373-0">
                <div className="casino-table ng-tns-c1798067373-0 ng-star-inserted">
                  <div className="desktop-design d-lg-block d-none w-100 ng-tns-c1798067373-0">
                    <div className="casino-table-box casino-table-box_top_bg ng-tns-c1798067373-0">
                      <div className="w-100 text-end ng-tns-c1798067373-0">
                        <div className="min_max_collapse ng-tns-c1798067373-0">
                          <a
                            data-bs-toggle="collapse"
                            href="#collapserangebollywood12"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapserangebollywood12"
                            className="minmax-btn collapsed ng-tns-c1798067373-0"
                          >
                            <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                          </a>
                          <div
                            id="collapserangebollywood12"
                            className="range-collpase collapse ng-tns-c1798067373-0"
                          >
                            <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                              <b className="ng-tns-c1798067373-0">Min:</b>
                              <span className="ng-tns-c1798067373-0">
                                {" "}
                                {reqRes?.data?.data?.t2[0]?.min}
                              </span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                              <b className="ng-tns-c1798067373-0">Max:</b>
                              <span className="ng-tns-c1798067373-0">
                                <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                  {reqRes?.data?.data?.t2[0]?.max}
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c1798067373-0">
                          <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                            A.
                          </span>
                          DON{" "}
                          <div className="casino-nation-book d-md-none ng-tns-c1798067373-0" />
                        </div>
                        {reqRes?.data?.data?.t2[0]?.gstatus !== "ACTIVE" ? (
                          <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row ">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.b1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    1
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[0]?.b1}
                                </span>
                              </div>
                              <div
                                className="casino-odds-box lay ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.l1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    7
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[0]?.l1}
                                </span>
                              </div>
                            </div>{" "}
                          </>
                        )}
                        <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 1 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 1 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c1798067373-0">
                          <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                            B.
                          </span>
                          Amar Akbar Anthony{" "}
                          <div className="casino-nation-book d-md-none ng-tns-c1798067373-0" />
                        </div>
                        {reqRes?.data?.data?.t2[1]?.gstatus !== "ACTIVE" ? (
                          <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-0 ">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            {" "}
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-0 "
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.b1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    2
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[1]?.b1}
                                </span>
                              </div>
                              <div
                                className="casino-odds-box lay ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.l1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    8
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[1]?.l1}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 2 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 2 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c1798067373-0">
                          <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                            C.
                          </span>
                          Sahib Bibi Aur Ghula{" "}
                          <div className="casino-nation-book d-md-none ng-tns-c1798067373-0" />
                        </div>
                        {reqRes?.data?.data?.t2[2]?.gstatus !== "ACTIVE" ? (
                          <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[2]?.b1,
                                    reqRes?.data?.data?.t2[2]?.nat,
                                    3
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[2]?.b1}
                                </span>
                              </div>
                              <div
                                className="casino-odds-box lay ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[2]?.l1,
                                    reqRes?.data?.data?.t2[2]?.nat,
                                    9
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[2]?.l1}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 3 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 3 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c1798067373-0">
                          <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                            D.
                          </span>
                          Dharam Veer{" "}
                          <div className="casino-nation-book d-md-none ng-tns-c1798067373-0" />
                        </div>
                        {reqRes?.data?.data?.t2[3]?.gstatus !== "ACTIVE" ? (
                          <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.b1,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    4
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[3]?.b1}
                                </span>
                              </div>
                              <div
                                className="casino-odds-box lay ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[3]?.l1,
                                    reqRes?.data?.data?.t2[3]?.nat,
                                    10
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[3]?.l1}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 4 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 4 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c1798067373-0">
                          <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                            E.
                          </span>
                          Kis KisKo Pyaar Karo{" "}
                          <div className="casino-nation-book d-md-none ng-tns-c1798067373-0" />
                        </div>
                        {reqRes?.data?.data?.t2[4]?.gstatus !== "ACTIVE" ? (
                          <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[4]?.b1,
                                    reqRes?.data?.data?.t2[4]?.nat,
                                    5
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[4]?.b1}
                                </span>
                              </div>
                              <div
                                className="casino-odds-box lay ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[4]?.l1,
                                    reqRes?.data?.data?.t2[4]?.nat,
                                    11
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[4]?.l1}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                        <div className="casino-nation-name ng-tns-c1798067373-0">
                          <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                            F.
                          </span>
                          Ghulam{" "}
                          <div className="casino-nation-book d-md-none ng-tns-c1798067373-0" />
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus !== "ACTIVE" ? (
                          <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                            <div className="casino-odds-box back ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                            <div className="casino-odds-box lay ng-tns-c1798067373-0">
                              <span className="casino-odds ng-tns-c1798067373-0">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row">
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[5]?.b1,
                                    reqRes?.data?.data?.t2[5]?.nat,
                                    6
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[5]?.b1}
                                </span>
                              </div>
                              <div
                                className="casino-odds-box lay ng-tns-c1798067373-0"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[5]?.l1,
                                    reqRes?.data?.data?.t2[5]?.nat,
                                    12
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  {reqRes?.data?.data?.t2[5]?.l1}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 6 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 6 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-box mt-3 ng-tns-c1798067373-0">
                      <div className="casino-table-left-box left-odd-box ng-tns-c1798067373-0">
                        <div className="w-100 text-end ng-tns-c1798067373-0">
                          <div className="min_max_collapse ng-tns-c1798067373-0">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange2btodds"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2btodds"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                            >
                              <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                            </a>
                            <div
                              id="collapserange2btodds"
                              className="range-collpase collapse ng-tns-c1798067373-0"
                            >
                              <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Min:</b>
                                <span className="ng-tns-c1798067373-0">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Max:</b>
                                <span className="ng-tns-c1798067373-0">
                                  <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                    {" "}
                                    50000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="casino-odd-box-container ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="casino-nation-name ng-tns-c1798067373-0">
                            {" "}
                            Odd{" "}
                            <div className="casino-nation-book text-danger d-md-none ng-tns-c1798067373-0" />
                          </div>
                          {reqRes?.data?.data?.t2[6]?.gstatus !== "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0 casino-odds-box-row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-0">
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                <span className="casino-odds ng-tns-c1798067373-0">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-0 casino-odds-box-row">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-0"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[6]?.b1,
                                      reqRes?.data?.data?.t2[6]?.nat,
                                      7
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    {reqRes?.data?.data?.t2[6]?.b1}
                                  </span>
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-0"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[6]?.l1,
                                      reqRes?.data?.data?.t2[6]?.nat,
                                      13
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    {reqRes?.data?.data?.t2[6]?.l1}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="casino-nation-book text-center d-none d-md-block w-100 ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 7 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 7 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-right-box right-odd-box ng-tns-c1798067373-0">
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="w-100 text-end ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                                href="#collapserange2bt14"
                                aria-controls="collapserange2bt14"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                className="range-collpase collapse ng-tns-c1798067373-0"
                                id="collapserange2bt14"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      50000{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {" "}
                            {reqRes?.data?.data?.t2[13]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[13]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <span className="casino-odds ng-tns-c1798067373-0 ">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[13]?.b1,
                                  reqRes?.data?.data?.t2[13]?.nat,
                                  15
                                );
                                setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-0">
                                Dulha Dulhan K-Q
                              </span>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 8 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 8 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="w-100 text-end ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                                href="#collapserange2bt15"
                                aria-controls="collapserange2bt15"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                className="range-collpase collapse ng-tns-c1798067373-0"
                                id="collapserange2bt15"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      50000{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {reqRes?.data?.data?.t2[14]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[14]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <span className="casino-odds ng-tns-c1798067373-0 ">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[14]?.b1,
                                  reqRes?.data?.data?.t2[14]?.nat,
                                  16
                                );
                                setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-0">
                                Barati J-A
                              </span>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 9 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 9 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-box mt-3 ng-tns-c1798067373-0">
                      <div className="casino-table-left-box ng-tns-c1798067373-0">
                        <div className="w-100 text-end ng-tns-c1798067373-0">
                          <div className="min_max_collapse ng-tns-c1798067373-0">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange2asdasd"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange2asdasd"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                            >
                              <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                            </a>
                            <div
                              id="collapserange2asdasd"
                              className="range-collpase collapse ng-tns-c1798067373-0"
                            >
                              <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Min:</b>
                                <span className="ng-tns-c1798067373-0">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Max:</b>
                                <span className="ng-tns-c1798067373-0">
                                  <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                    {" "}
                                    12500{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {" "}
                            {reqRes?.data?.data?.t2[7]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[7]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[7]?.b1,
                                  reqRes?.data?.data?.t2[7]?.nat,
                                  17
                                );
                                setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                              }}
                            >
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                    />
                                  </span>
                                </span>
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 10 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 10 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {" "}
                            {reqRes?.data?.data?.t2[8]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[8]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[8]?.b1,
                                  reqRes?.data?.data?.t2[8]?.nat,
                                  18
                                );
                                setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                              }}
                            >
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-black ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                    />
                                  </span>
                                </span>
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-black ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 11 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 11 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-right-box right-cards ng-tns-c1798067373-0">
                        <div className="w-100 text-end ng-tns-c1798067373-0">
                          <div className="min_max_collapse ng-tns-c1798067373-0">
                            <a
                              data-bs-toggle="collapse"
                              href="#collapserange24cardbt"
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapserange24cardbt"
                              className="minmax-btn collapsed ng-tns-c1798067373-0"
                            >
                              <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                            </a>
                            <div
                              id="collapserange24cardbt"
                              className="range-collpase collapse ng-tns-c1798067373-0"
                            >
                              <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Min:</b>
                                <span className="ng-tns-c1798067373-0">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Max:</b>
                                <span className="ng-tns-c1798067373-0">
                                  <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                    {" "}
                                    12500{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <h4
                          className="w-100 text-center ng-tns-c1798067373-0"
                          style={{ fontSize: 16 }}
                        >
                          <b className="ng-tns-c1798067373-0">Cards 3.75</b>
                        </h4>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[9]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[9]?.gstatus === undefined ? (
                            <>
                              <div className="ng-tns-c1798067373-0 suspended-box">
                                <img
                                  className="ng-tns-c1798067373-0 ng-star-inserted"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[9]?.b1,
                                  reqRes?.data?.data?.t2[9]?.nat,
                                  19
                                );
                                setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            <p className="ng-tns-c1798067373-0">
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 12 && (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.number == 12 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[10]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[10]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[10]?.b1,
                                  reqRes?.data?.data?.t2[10]?.nat,
                                  20
                                );
                                setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                              />
                            </div>
                          )}
                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            <p className="ng-tns-c1798067373-0">
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 13 && (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.number == 13 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[11]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[11]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[11]?.b1,
                                  reqRes?.data?.data?.t2[11]?.nat,
                                  21
                                );
                                setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                              />
                            </div>
                          )}
                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            <p className="ng-tns-c1798067373-0">
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 14 && (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.number == 14 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[12]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[12]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[12]?.b1,
                                  reqRes?.data?.data?.t2[12]?.nat,
                                  22
                                );
                                setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            <p className="ng-tns-c1798067373-0">
                              {thirtyTwoCardStack.map(
                                (ele) =>
                                  ele.number === 15 && (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.number == 15 && ele.amount}
                                    </span>
                                  )
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-design d-lg-none d-block w-100 ng-tns-c1798067373-0">
                    <div className="casino-table-box mobile-akbar ng-tns-c1798067373-0">
                      <div className="w-100 ng-tns-c1798067373-0">
                        <div className="casino-table-header ng-tns-c1798067373-0">
                          <div className="casino-nation-detail ng-tns-c1798067373-0">
                            <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0">
                              <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Min:</b>
                                <span className="ng-tns-c1798067373-0">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Max:</b>
                                <span className="ng-tns-c1798067373-0">
                                  <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                    {" "}
                                    150000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-body ng-tns-c1798067373-0">
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                                  A.
                                </span>
                                DON{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 1 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[0]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[0]?.b1,
                                        reqRes?.data?.data?.t2[0]?.nat,
                                        1
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[0]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[0]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[0]?.l1,
                                        reqRes?.data?.data?.t2[0]?.nat,
                                        7
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[0]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[0]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                                  B.
                                </span>
                                Amar Akbar Anthony{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 2 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 2 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[1]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row ">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[1]?.b1,
                                        reqRes?.data?.data?.t2[1]?.nat,
                                        2
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[1]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[1]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[1]?.l1,
                                        reqRes?.data?.data?.t2[1]?.nat,
                                        8
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[1]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[1]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                                  C.
                                </span>
                                Sahib Bibi Aur Ghula{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 3 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 3 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[2]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row ">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[2]?.b1,
                                        reqRes?.data?.data?.t2[2]?.nat,
                                        3
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[2]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[2]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[2]?.l1,
                                        reqRes?.data?.data?.t2[2]?.nat,
                                        9
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[2]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[2]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                                  D.
                                </span>
                                Dharam Veer{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 4 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 4 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[3]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row ">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[3]?.b1,
                                        reqRes?.data?.data?.t2[3]?.nat,
                                        4
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[3]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[3]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[3]?.l1,
                                        reqRes?.data?.data?.t2[3]?.nat,
                                        10
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[3]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[3]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                                  E.
                                </span>
                                Kis KisKo Pyaar Karo{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 5 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 5 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[4]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row ">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[4]?.b1,
                                        reqRes?.data?.data?.t2[4]?.nat,
                                        5
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[4]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[4]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[4]?.l1,
                                        reqRes?.data?.data?.t2[4]?.nat,
                                        11
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[4]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[4]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                <span className="text-danger ng-tns-c1798067373-0 ng-star-inserted">
                                  F.
                                </span>
                                Ghulam{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 6 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 6 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[5]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row ">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[5]?.b1,
                                        reqRes?.data?.data?.t2[5]?.nat,
                                        6
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[5]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[5]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[5]?.l1,
                                        reqRes?.data?.data?.t2[5]?.nat,
                                        12
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[5]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[5]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-box mobile-akbar ng-tns-c1798067373-0">
                      <div className="w-100 ng-tns-c1798067373-0">
                        <div className="casino-table-header casino-table-header_border mt-3 ng-tns-c1798067373-0">
                          <div className="casino-nation-detail ng-tns-c1798067373-0">
                            <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-0">
                              <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Min:</b>
                                <span className="ng-tns-c1798067373-0">
                                  {" "}
                                  100
                                </span>
                              </span>
                              <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                <b className="ng-tns-c1798067373-0">Max:</b>
                                <span className="ng-tns-c1798067373-0">
                                  <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                    {" "}
                                    50000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-body ng-tns-c1798067373-0">
                          <div className="casino-table-row ng-tns-c1798067373-0 ng-star-inserted">
                            <div className="casino-nation-detail ng-tns-c1798067373-0">
                              <div className="casino-nation-name d-flex w-100 ng-tns-c1798067373-0">
                                {" "}
                                Odd{" "}
                              </div>
                              <div className="casino-nation-book d-md-none ng-tns-c1798067373-0">
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 7 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 7 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                            {reqRes?.data?.data?.t2[6]?.gstatus !== "ACTIVE" ? (
                              <div className="ng-tns-c1798067373-0 casino_odds_row suspended-box">
                                <div className="casino-odds-box back ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <div className="casino-odds-box lay ng-tns-c1798067373-0">
                                  <span className="casino-odds ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="ng-tns-c1798067373-0 casino_odds_row ">
                                  <div
                                    className="casino-odds-box back ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[6]?.b1,
                                        reqRes?.data?.data?.t2[6]?.nat,
                                        7
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[6]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[6]?.b1}
                                    </span>
                                  </div>
                                  <div
                                    className="casino-odds-box lay ng-tns-c1798067373-0"
                                    onClick={() => {
                                      handleModal(
                                        reqRes?.data?.data?.t2[6]?.l1,
                                        reqRes?.data?.data?.t2[6]?.nat,
                                        13
                                      );
                                      setBetIndex(
                                        reqRes?.data?.data?.t2[6]?.sid
                                      );
                                    }}
                                  >
                                    <span className="casino-odds ng-tns-c1798067373-0">
                                      {reqRes?.data?.data?.t2[6]?.l1}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-box mt-3 ng-tns-c1798067373-0">
                      <div className="casino-table-right-box right-odd-box ng-tns-c1798067373-0">
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="w-100 text-end ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                                href="#collapserange2bt14"
                                aria-controls="collapserange2bt14"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                className="range-collpase collapse ng-tns-c1798067373-0"
                                id="collapserange2bt14"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      50000{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {reqRes?.data?.data?.t2[13]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[13]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <span className="casino-odds ng-tns-c1798067373-0 ">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[13]?.b1,
                                  reqRes?.data?.data?.t2[13]?.nat,
                                  15
                                );
                                setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-0">
                                Dulha Dulhan K-Q
                              </span>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {" "}
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 8 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 8 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="w-100 text-end ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                                href="#collapserange2bt15"
                                aria-controls="collapserange2bt15"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                className="range-collpase collapse ng-tns-c1798067373-0"
                                id="collapserange2bt15"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      50000{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {reqRes?.data?.data?.t2[14]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[14]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <span className="casino-odds ng-tns-c1798067373-0 ">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[14]?.b1,
                                  reqRes?.data?.data?.t2[14]?.nat,
                                  16
                                );
                                setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-0">
                                Barati J-A
                              </span>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 9 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 9 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-box ng-tns-c1798067373-0">
                      <div className="casino-table-left-box ng-tns-c1798067373-0">
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="w-100 text-end ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                                href="#collapserangerb8"
                                aria-controls="collapserangerb8"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                className="range-collpase collapse ng-tns-c1798067373-0"
                                id="collapserangerb8"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      12500{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {reqRes?.data?.data?.t2[7]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[7]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[7]?.b1,
                                  reqRes?.data?.data?.t2[7]?.nat,
                                  17
                                );
                                setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                              }}
                            >
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                    />
                                  </span>
                                </span>
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 10 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 10 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="aaa-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          <div className="w-100 text-end ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                                href="#collapserangerb9"
                                aria-controls="collapserangerb9"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                className="range-collpase collapse ng-tns-c1798067373-0"
                                id="collapserangerb9"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      12500{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="casino-odds text-center ng-tns-c1798067373-0">
                            {reqRes?.data?.data?.t2[8]?.b1}
                          </div>
                          {reqRes?.data?.data?.t2[8]?.gstatus != "ACTIVE" ? (
                            <div className="ng-tns-c1798067373-0  casino-odds-box back casino-odds-box-theme  suspended-box">
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-red ng-tns-c1798067373-0">
                                    <i className="fa fa-lock" />
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[8]?.b1,
                                  reqRes?.data?.data?.t2[8]?.nat,
                                  18
                                );
                                setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                              }}
                            >
                              <div className="casino-odds ng-tns-c1798067373-0 ng-star-inserted">
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-black ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                    />
                                  </span>
                                </span>
                                <span className="card-icon ms-1 ng-tns-c1798067373-0">
                                  <span className="card-black ng-tns-c1798067373-0">
                                    <img
                                      className="ng-tns-c1798067373-0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="casino-nation-book text-center ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 11 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 11 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="casino-table-right-box right-cards ng-tns-c1798067373-0">
                        <div className="d-flex w-100 ng-tns-c1798067373-0">
                          <h4
                            className="w-100 text-end ng-tns-c1798067373-0"
                            style={{ fontSize: 13 }}
                          >
                            <b className="ng-tns-c1798067373-0">Cards 3.75</b>
                          </h4>
                          <div className="w-100 text-end bollywood_minmaxcards ng-tns-c1798067373-0">
                            <div className="min_max_collapse ng-tns-c1798067373-0">
                              <a
                                data-bs-toggle="collapse"
                                href="#collapserange2"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapserange2"
                                className="minmax-btn collapsed ng-tns-c1798067373-0"
                              >
                                <i className="fas fa-info-circle ng-tns-c1798067373-0" />
                              </a>
                              <div
                                id="collapserange2"
                                className="range-collpase collapse ng-tns-c1798067373-0"
                              >
                                <span className="min_max_span ms-0 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Min:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    {" "}
                                    100
                                  </span>
                                </span>
                                <span className="min_max_span ms-1 ng-tns-c1798067373-0">
                                  <b className="ng-tns-c1798067373-0">Max:</b>
                                  <span className="ng-tns-c1798067373-0">
                                    <span className="ng-tns-c1798067373-0 ng-star-inserted">
                                      {" "}
                                      12500{" "}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[9]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[9]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[9]?.b1,
                                  reqRes?.data?.data?.t2[9]?.nat,
                                  19
                                );
                                setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 12 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 12 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[10]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[10]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[10]?.b1,
                                  reqRes?.data?.data?.t2[10]?.nat,
                                  20
                                );
                                setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 13 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 13 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[11]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[11]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[11]?.b1,
                                  reqRes?.data?.data?.t2[11]?.nat,
                                  21
                                );
                                setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 14 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 14 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                        <div className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted">
                          {reqRes?.data?.data?.t2[12]?.gstatus !== "ACTIVE" ||
                          reqRes?.data?.data?.t2[12]?.gstatus === undefined ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[12]?.b1,
                                  reqRes?.data?.data?.t2[12]?.nat,
                                  22
                                );
                                setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 15 && (
                                  <span
                                    style={{
                                      color: ele.amount >= 0 ? "green" : "red",
                                    }}
                                  >
                                    {ele.number == 15 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ng-tns-c1798067373-0">
                  <div className="casino-last-result-title ng-tns-c1798067373-0">
                    <span className="ng-tns-c1798067373-0">Last Result</span>
                    <span className="ng-tns-c1798067373-0">
                      <a className="ng-tns-c1798067373-0">View All</a>
                    </span>
                  </div>
                  <div className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted">
                    {results?.data?.result?.map((result, index) => {
                      const buttonValue = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      // console.log("item", item);

                      return (
                        <span
                          className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                          key={index}
                        >
                          <span
                            onClick={() => {
                              setMidRound(result.mid);
                              CasinoGameResult_API(result.mid);
                              setShowresultModal(true);
                            }}
                            className="ng-tns-c1798067373-0"

                          >
                            {buttonValue && <>{resultMapping[result.result]}</>}

                          </span>
                          {/* <span
                          className="ng-tns-c1798067373-0"
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                        >
                          {buttonValue && (
                            <>
                              {buttonValue.nat[buttonValue.nat.length - 1] ==
                              "t"
                                ? "B"
                                : "A"}
                            </>
                          )}
                        </span> */}
                        </span>
                      );
                    })}

                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CasinoPlacedBet bets={history} />
          )}
          <div className="ng-tns-c1798067373-0 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c1798067373-0">
              <table className="table ng-tns-c1798067373-0">
                <thead className="ng-tns-c1798067373-0">
                  <tr className="ng-tns-c1798067373-0">
                    <th className="ng-tns-c1798067373-0">Matched Bet</th>
                    <th className="text-end ng-tns-c1798067373-0">Odds</th>
                    <th className="text-end ng-tns-c1798067373-0">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
