import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoVideo from "./components/CasinoVideo";
import CasinoPlacedBet from "./components/CasinoPlaceBet";

export default function AmarAkhbarAnthoany() {
  const GAME_ID = "AAA";
  const VIDEO_ID = CASINO_VIDEO.amarAkbarAnthony;
  const dispatch = useDispatch();
  const id = 9;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const [name, setName] = useState("");
  const [betIndex, setBetIndex] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const resultMapping = {
    1: "A",
    2: "B",
    3: "C",
  };


  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("teenpattiopen", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus == "ACTIVE" &&
      betDetails.nat === filteredData[0].nat &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [betDetails]);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  // const casinoData = useQuery(
  //   "getCasinoOdds",
  //   () => getCasinoOddsAPI(GAME_ID),
  //   {
  //     refetchInterval: 800,
  //   }
  // );
  // const lastTenResultData = useQuery(
  //   "getResult",
  //   () => getCasinoLastTenResultAPI(GAME_ID),
  //   {
  //     refetchInterval: 1500,
  //   }
  // );
  // const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
  //   refetchInterval: 2500,
  // });
  const [selectedTab, setSelectedTab] = useState("game");
  return (
    <div className="center-main-container casino-page  ng-star-inserted">
      <div className="center-container ">
        <div className="casino-page-container  aaa">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-5">
            <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-5">
              <li
                onClick={() => setSelectedTab("game")}
                className="nav-item ng-tns-c3502172659-5"
              >
                <div
                  className={`ng-tns-c3502172659-5 
                      ${selectedTab === "game" && "active"}
                      nav-link`}
                >
                  Game
                </div>
              </li>
              <li
                onClick={() => setSelectedTab("placedBet")}
                className="nav-item ng-tns-c3502172659-5"
              >
                <div
                  className={`ng-tns-c3502172659-5 
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                >
                  {" "}
                  Placed Bet ({history?.length})
                  {/* ({casinoBets?.data?.response?.length}){" "} */}
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c3502172659-5">
              <span className="casino-rid ng-tns-c3502172659-5">
                <a className="d-block ng-tns-c3502172659-5">
                  <small className="ng-tns-c3502172659-5">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c3502172659-5 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                  {/* {casinoData?.data?.response?.marketId} */}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ">
            <span className="casino-name ">
              {" "}
              Amar Akbar Anthony
              <a className="ms-1 d-xl-inline d-none ">
                <small className="">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ">
              <small className="">
                Round ID:{" "}
                <span className=" ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}

                  {/* 27.241007205229 */}
                </span>
              </small>
              <span className=" ng-star-inserted">
                {" "}
                | Min: 100 | Max: 300000
              </span>
            </span>
          </div>
          {selectedTab === "game" ? (
            <div className=" ng-star-inserted">
              <div className="casino-video ">
                <div className="video-box-container ">
                  <div className="casino-video-box  ng-star-inserted">
                    {/* <CasinoVideo id={VIDEO_ID} /> */}
                    <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                  </div>
                </div>
                <div className=" ng-star-inserted">
                  <div className="casino-video-cards  ng-star-inserted">
                    <h5 className="">Card</h5>
                    <div className="flip-card-container ">
                      <div className="me-lg-2 me-1  ng-star-inserted">
                        <img
                          className=""
                          src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${reqRes?.data?.data?.t1[0]?.C1}.webp`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
              </div>

              <div className="casino-detail ">
                <div className="casino-table casino-table ng-tns-c1798067373-9 ng-star-inserted ng-star-inserted">
                  <div className="casino-table-box casino-tavle-box-background d-lg-flex d-none ">
                    <div className="w-100 text-end ">
                      <div className="min_max_collapse ">
                        <a
                          data-bs-toggle="collapse"
                          href="#collapserange2aaa1"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapserange2aaa1"
                          className="minmax-btn collapsed "
                        >
                          <i className="fas fa-info-circle " />
                        </a>
                        <div
                          id="collapserange2aaa1"
                          className="range-collpase collapse "
                        >
                          <span className="min_max_span ms-0 ">
                            <b className="">Min:</b>
                            <span className=""> 100</span>
                          </span>
                          <span className="min_max_span ms-1 ">
                            <b className="">Max:</b>
                            <span className="">
                              <span className=" ng-star-inserted">
                                {" "}
                                300000{" "}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="casino-odd-box-container  ng-star-inserted">
                      <div className="casino-nation-name ">
                        <span className="text-danger  ng-star-inserted">
                          A.
                        </span>
                        Amar <div className="casino-nation-book d-md-none " />
                      </div>
                      <div className=" casino-odds-box-row">
                        {reqRes?.data?.data?.t2[0]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                          <div className=" casino-odds-box back suspended-box">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[0]?.b1,
                                reqRes?.data?.data?.t2[0]?.nat,
                                1
                              );
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                            className="casino-odds-box back "
                          >
                            <span className="casino-odds ">
                              {reqRes?.data?.data?.t2[0]?.b1}
                            </span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[0]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                          <div className=" casino-odds-box lay suspended-box">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[0]?.l1,
                                reqRes?.data?.data?.t2[0]?.nat,
                                4
                              );
                              setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                            }}
                            className="casino-odds-box lay "
                          >
                            <span className="casino-odds ">
                              {reqRes?.data?.data?.t2[0]?.l1}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="casino-nation-book text-center d-none d-md-block w-100 mb-2 ">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                style={{
                                  color: ele.amount >= 0 ? "green" : "red",
                                }}
                              >
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container  ng-star-inserted">
                      <div className="casino-nation-name ">
                        <span className="text-danger  ng-star-inserted">
                          B.
                        </span>
                        Akbar <div className="casino-nation-book d-md-none " />
                      </div>
                      <div className=" casino-odds-box-row">
                        {reqRes?.data?.data?.t2[1]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                          <div className=" casino-odds-box back suspended-box">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[1]?.b1,
                                reqRes?.data?.data?.t2[1]?.nat,
                                2
                              );
                              setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                            }}
                            className="casino-odds-box back "
                          >
                            <span className="casino-odds ">
                              {reqRes?.data?.data?.t2[1]?.b1}
                            </span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[1]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                          <div className=" casino-odds-box lay suspended-box">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[1]?.b1,
                                reqRes?.data?.data?.t2[1]?.nat,
                                5
                              );
                              setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                            }}
                            className="casino-odds-box lay "
                          >
                            <span className="casino-odds ">
                              {reqRes?.data?.data?.t2[1]?.l1}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="casino-nation-book text-center d-none d-md-block w-100 mb-2 ">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                style={{
                                  color: ele.amount >= 0 ? "green" : "red",
                                }}
                              >
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container  ng-star-inserted">
                      <div className="casino-nation-name ">
                        <span className="text-danger  ng-star-inserted">
                          C.
                        </span>
                        Anthony{" "}
                        <div className="casino-nation-book d-md-none " />
                      </div>
                      <div className=" casino-odds-box-row">
                        {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                          <div className=" casino-odds-box back suspended-box">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[2]?.b1,
                                reqRes?.data?.data?.t2[2]?.nat,
                                2
                              );
                              setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                            }}
                            className="casino-odds-box back "
                          >
                            <span className="casino-odds ">
                              {reqRes?.data?.data?.t2[2]?.b1}
                            </span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                          <div className=" casino-odds-box lay suspended-box">
                            <span className="casino-odds  ng-tns-c3502172659-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[2]?.b1,
                                reqRes?.data?.data?.t2[2]?.nat,
                                5
                              );
                              setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                            }}
                            className="casino-odds-box lay "
                          >
                            <span className="casino-odds ">
                              {reqRes?.data?.data?.t2[2]?.l1}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="casino-nation-book text-center d-none d-md-block w-100 mb-2 ">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                style={{
                                  color: ele.amount >= 0 ? "green" : "red",
                                }}
                              >
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="d-lg-none d-block w-100 ">
                    <div className="casino-table-box mobile-akbar ">
                      <div className="w-100 ">
                        <div className="casino-table-header ">
                          <div className="casino-nation-detail ">
                            <div className="min_max_odds justify-content-start d-lg-none d-flex ">
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b>
                                <span className=""> 100</span>
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    300000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="casino-table-body ">
                          <div className="casino-table-row  ng-star-inserted">
                            <div className="casino-nation-detail ">
                              <div className="casino-nation-name ">
                                <span className="text-danger  ng-star-inserted">
                                  A.
                                </span>
                                Amar{" "}
                                <p className="">
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 1 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 1 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className=" casino_odds_row">
                              {reqRes?.data?.data?.t2[0]?.gstatus ===
                                "SUSPENDED" ||
                              reqRes?.data?.data?.t2[0]?.gstatus ===
                                "CLOSED" ? (
                                <div className=" casino-odds-box back suspended-box">
                                  <span className="casino-odds  ng-tns-c3502172659-2">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0]?.b1,
                                      reqRes?.data?.data?.t2[0]?.nat,
                                      1
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}
                                  className="casino-odds-box back "
                                >
                                  <span className="casino-odds ">
                                    {reqRes?.data?.data?.t2[0]?.b1}
                                  </span>
                                </div>
                              )}

                              {reqRes?.data?.data?.t2[0]?.gstatus ===
                                "SUSPENDED" ||
                              reqRes?.data?.data?.t2[0]?.gstatus ===
                                "CLOSED" ? (
                                <div className=" casino-odds-box lay suspended-box">
                                  <span className="casino-odds  ng-tns-c3502172659-2">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0]?.l1,
                                      reqRes?.data?.data?.t2[0]?.nat,
                                      4
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}
                                  className="casino-odds-box lay "
                                >
                                  <span className="casino-odds ">
                                    {reqRes?.data?.data?.t2[0]?.l1}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="casino-table-row  ng-star-inserted">
                            <div className="casino-nation-detail ">
                              <div className="casino-nation-name ">
                                <span className="text-danger  ng-star-inserted">
                                  B.
                                </span>
                                Akbar{" "}
                                <p className="">
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 2 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 2 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className=" casino_odds_row">
                              {reqRes?.data?.data?.t2[1]?.gstatus ===
                                "SUSPENDED" ||
                              reqRes?.data?.data?.t2[1]?.gstatus ===
                                "CLOSED" ? (
                                <div className=" casino-odds-box back suspended-box">
                                  <span className="casino-odds  ng-tns-c3502172659-2">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1]?.b1,
                                      reqRes?.data?.data?.t2[1]?.nat,
                                      2
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}
                                  className="casino-odds-box back "
                                >
                                  <span className="casino-odds ">
                                    {reqRes?.data?.data?.t2[1]?.b1}
                                  </span>
                                </div>
                              )}

                              {reqRes?.data?.data?.t2[1]?.gstatus ===
                                "SUSPENDED" ||
                              reqRes?.data?.data?.t2[1]?.gstatus ===
                                "CLOSED" ? (
                                <div className=" casino-odds-box lay suspended-box">
                                  <span className="casino-odds  ng-tns-c3502172659-2">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1]?.b1,
                                      reqRes?.data?.data?.t2[1]?.nat,
                                      5
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}
                                  className="casino-odds-box lay "
                                >
                                  <span className="casino-odds ">
                                    {reqRes?.data?.data?.t2[1]?.l1}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="casino-table-row  ng-star-inserted">
                            <div className="casino-nation-detail ">
                              <div className="casino-nation-name ">
                                <span className="text-danger  ng-star-inserted">
                                  C.
                                </span>
                                Anthony{" "}
                                <p className="">
                                  {" "}
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === 3 && (
                                        <span
                                          style={{
                                            color:
                                              ele.amount >= 0 ? "green" : "red",
                                          }}
                                        >
                                          {ele.number == 3 && ele.amount}
                                        </span>
                                      )
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className=" casino_odds_row">
                              {reqRes?.data?.data?.t2[2]?.gstatus ===
                                "SUSPENDED" ||
                              reqRes?.data?.data?.t2[2]?.gstatus ===
                                "CLOSED" ? (
                                <div className=" casino-odds-box back suspended-box">
                                  <span className="casino-odds  ng-tns-c3502172659-2">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2]?.b1,
                                      reqRes?.data?.data?.t2[2]?.nat,
                                      2
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}
                                  className="casino-odds-box back "
                                >
                                  <span className="casino-odds ">
                                    {reqRes?.data?.data?.t2[2]?.b1}
                                  </span>
                                </div>
                              )}

                              {reqRes?.data?.data?.t2[2]?.gstatus ===
                                "SUSPENDED" ||
                              reqRes?.data?.data?.t2[2]?.gstatus ===
                                "CLOSED" ? (
                                <div className=" casino-odds-box lay suspended-box">
                                  <span className="casino-odds  ng-tns-c3502172659-2">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2]?.l1,
                                      reqRes?.data?.data?.t2[2]?.nat,
                                      5
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}
                                  className="casino-odds-box lay "
                                >
                                  <span className="casino-odds ">
                                    {reqRes?.data?.data?.t2[2]?.l1}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-box casino-table-box-margin ">
                    <div className="casino-table-left-box ">
                      <div className="aaa-odd-box  ng-star-inserted">
                        <div className="w-100 text-end  ng-star-inserted">
                          <div className="min_max_collapse ">
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed "
                              href="#collapserange2aaa4"
                              aria-controls="collapserange2aaa4"
                            >
                              <i className="fas fa-info-circle " />
                            </a>
                            <div
                              className="range-collpase collapse "
                              id="collapserange2aaa4"
                            >
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b>
                                <span className=""> 100</span>
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="casino-odds text-center ">
                          {" "}
                          {reqRes?.data?.data?.t2[3]?.b1}
                        </div>
                        {reqRes?.data?.data?.t2[3]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme  suspended-box">
                            <span className="casino-odds">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            className=" back casino-odds-box casino-odds-box-theme"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[3]?.b1,
                                reqRes?.data?.data?.t2[3]?.nat,
                                7
                              );
                              setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                            }}
                          >
                            <span className="casino-odds ">Even</span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center ">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 4 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 4 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="aaa-odd-box  ng-star-inserted">
                        <div className="casino-odds text-center ">
                          {" "}
                          {reqRes?.data?.data?.t2[4]?.b1}{" "}
                        </div>
                        {reqRes?.data?.data?.t2[4]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme  suspended-box">
                            <span className="casino-odds">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            className=" back casino-odds-box casino-odds-box-theme"
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[4]?.b1,
                                reqRes?.data?.data?.t2[4]?.nat,
                                8
                              );
                              setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                            }}
                          >
                            <span className="casino-odds ">Odd</span>
                          </div>
                        )}
                        <div className="casino-nation-book text-center ">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-center-box ">
                      <div className="aaa-odd-box  ng-star-inserted">
                        <div className="w-100 text-end  ng-star-inserted">
                          <div className="min_max_collapse ">
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed "
                              href="#collapserange2aaa6"
                              aria-controls="collapserange2aaa6"
                            >
                              <i className="fas fa-info-circle " />
                            </a>
                            <div
                              className="range-collpase collapse "
                              id="collapserange2aaa6"
                            >
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b>
                                <span className=""> 100</span>
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    25000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="casino-odds text-center ">
                          {reqRes?.data?.data?.t2[5]?.b1}{" "}
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme  suspended-box">
                            <span className="casino-odds">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[5]?.b1,
                                reqRes?.data?.data?.t2[5]?.nat,
                                9
                              );
                              setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                            }}
                            className=" back casino-odds-box casino-odds-box-theme"
                          >
                            <div className="casino-odds  ng-star-inserted">
                              <span className="card-icon ms-1 ">
                                <span className="card-red ">
                                  <img
                                    className=""
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                  />
                                </span>
                              </span>
                              <span className="card-icon ms-1 ">
                                <span className="card-red ">
                                  <img
                                    className=""
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="casino-nation-book text-center ">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 6 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 6 && ele.amount}
                                </span>
                              )
                          )}{" "}
                        </div>
                      </div>
                      <div className="aaa-odd-box  ng-star-inserted">
                        <div className="casino-odds text-center ">
                          {" "}
                          {reqRes?.data?.data?.t2[6]?.b1}
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme  suspended-box">
                            <span className="casino-odds">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[6]?.b1,
                                reqRes?.data?.data?.t2[6]?.nat,
                                10
                              );
                              setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                            }}
                            className=" back casino-odds-box casino-odds-box-theme"
                          >
                            <div className="casino-odds  ng-star-inserted">
                              <span className="card-icon ms-1 ">
                                <span className="card-black ">
                                  <img
                                    className=""
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                  />
                                </span>
                              </span>
                              <span className="card-icon ms-1 ">
                                <span className="card-black ">
                                  <img
                                    className=""
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="casino-nation-book text-center ">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 7 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 7 && ele.amount}
                                </span>
                              )
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-right-box ">
                      <div className="aaa-odd-box  ng-star-inserted">
                        <div className="w-100 text-end  ng-star-inserted">
                          <div className="min_max_collapse ">
                            <a
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              className="minmax-btn collapsed "
                              href="#collapserange2aaa21"
                              aria-controls="collapserange2aaa21"
                            >
                              <i className="fas fa-info-circle " />
                            </a>
                            <div
                              className="range-collpase collapse "
                              id="collapserange2aaa21"
                            >
                              <span className="min_max_span ms-0 ">
                                <b className="">Min:</b>
                                <span className=""> 100</span>
                              </span>
                              <span className="min_max_span ms-1 ">
                                <b className="">Max:</b>
                                <span className="">
                                  <span className=" ng-star-inserted">
                                    {" "}
                                    100000{" "}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="casino-odds text-center ">
                          {" "}
                          {reqRes?.data?.data?.t2[20]?.b1}{" "}
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme  suspended-box">
                            <span className="casino-odds">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[20]?.b1,
                                reqRes?.data?.data?.t2[20]?.nat,
                                11
                              );
                              setBetIndex(reqRes?.data?.data?.t2[20]?.sid);
                            }}
                            className=" back casino-odds-box casino-odds-box-theme"
                          >
                            <span className="casino-odds ">Under 7</span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center ">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 8 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 8 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="aaa-odd-box  ng-star-inserted">
                        <div className="casino-odds text-center ">
                          {" "}
                          {reqRes?.data?.data?.t2[21]?.b1}
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus != "ACTIVE" ? (
                          <div className="casino-odds-box back casino-odds-box-theme  suspended-box">
                            <span className="casino-odds">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);
                              handleModal(
                                reqRes?.data?.data?.t2[21]?.b1,
                                reqRes?.data?.data?.t2[21]?.nat,
                                12
                              );
                              setBetIndex(reqRes?.data?.data?.t2[21]?.sid);
                            }}
                            className=" back casino-odds-box casino-odds-box-theme"
                          >
                            <span className="casino-odds ">Over 7</span>
                          </div>
                        )}

                        <div className="casino-nation-book text-center ">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 9 && (
                                <span
                                  style={{
                                    color: ele.amount >= 0 ? "green" : "red",
                                  }}
                                >
                                  {ele.number == 9 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-full-box aaacards mt-3 ">
                    <div className="w-100 text-end ">
                      <div className="min_max_collapse ">
                        <a
                          data-bs-toggle="collapse"
                          href="#collapserange2"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapserange2"
                          className="minmax-btn collapsed "
                        >
                          <i className="fas fa-info-circle " />
                        </a>
                        <div
                          id="collapserange2"
                          className="range-collpase collapse "
                        >
                          <span className="min_max_span ms-0 ">
                            <b className="">Min:</b> 100{" "}
                          </span>
                          <span className="min_max_span ms-1 ">
                            <b className="">Max:</b>
                            <span className=" ng-star-inserted"> 50000 </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <h4 className="w-100 text-center mb-2 ">
                      <b className=""> {reqRes?.data?.data?.t2[7]?.b1} </b>
                    </h4>
                    {reqRes?.data?.data?.t2
                      ?.slice(7, 20)
                      ?.map((item, index) => (
                        <div
                          className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                          key={index}
                        >
                          {item?.gstatus === "SUSPENDED" ||
                          item?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted "
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                  CARD_ARRAY[index]
                                }.webp`}
                                alt={`Card ${CARD_ARRAY[index]}`}
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                // setShowModal(true);
                                // setBetNum(index + 1);
                                handleModal(item.b1, item.nat, index + 13);
                                setBetIndex(item.sid);
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted "
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                  CARD_ARRAY[index]
                                }.webp`}
                                alt={`Card ${CARD_ARRAY[index]}`}
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === index + 10 && (
                                  <span
                                    style={{
                                      color:
                                        ele.number == index + 10 &&
                                        ele.amount >= 0
                                          ? "green"
                                          : "red",
                                    }}
                                  >
                                    {ele.number == index + 10 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="">
                  <div className="casino-last-result-title ">
                    <span className="">Last Result</span>
                    <span className="">
                      <a className="">View All</a>
                    </span>
                  </div>
                  <div className="casino-last-results  ng-star-inserted">
                    {results?.data?.result?.map((result, index) => {
                      const buttonValue = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      return (
                        <span
                          className="ng-tns-c1798067373-6 result result-b ng-star-inserted"
                          key={index}
                        >
                          <span
                            className="ng-tns-c1798067373-6"
                            onClick={() => {
                              setMidRound(result.mid);
                              CasinoGameResult_API(result.mid);
                              setShowresultModal(true);
                              // setTrophy(buttonValue.nation[0]);
                            }}
                          >
                          {buttonValue && <>{resultMapping[result.result]}</>}
                            {/* {buttonValue && <>{buttonValue.nat[0]}</>} */}
                            {/* {buttonValue ? <>{buttonValue?.nation[0]}</> : "T"} */}
                          </span>
                        </span>
                      );
                    })}
                  
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CasinoPlacedBet bets={history} />
          )}
        </div>
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
