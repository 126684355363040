import React from "react";

export default function CasinoVideo({ id }) {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
      }}
      className=""
      src={`https://winx777.com/casino/?id=${id}`}
    />
  );
}
