import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import Timer from "./components/Timer";
import CasinoBetModal from "./CasinoBetModal";
import CasinoPlacedBet from "./components/CasinoPlaceBet";

export default function TeenPatti() {
  const id = 4;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [realTime, setRealTimeData] = useState(false);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  // console.log("stack_3patti", thirtyTwoCardStack);

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post(
        "/GetCasinoGameResultData",
        {
          mid: mid,
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("CasinoGameResult teen:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("scheduler res", res);
    } catch (e) {
      console.log("Error", e);
    }
  }

  async function Mainwallet_API() {
    try {
      let res = await API.post(
        "/manage_mainwallet_teenpetti",
        {
          priodid: results[0]?.mid,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("mainWallet", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("teen20", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);
  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };
  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nation === betDetails.nat
      );
      // console.log("filter", betDetails,filteredData);
      reqRes?.data?.data?.t2[2]?.gstatus != 0 &&
      betDetails?.nat === filteredData[0]?.nation &&
      betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);
  const [history, setHistory] = useState([]);

  async function ManageMainwallet_API(periodPts) {
    // console.log("periodPts==>", periodPts);
    try {
      // let res = await API.post("/Casino_manage_mainwallet", {
      //   priodid: periodPts,
      // });
      // console.log("mainwall res", res.data.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
      // console.log("data====", data);
      if (data[0][0] !== undefined) {
        if (data[0][0].remark === "Win" || data[0][0].remark === "Loss") {
          ManageMainwallet_API(data[0][0].period);
        }
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (reqRes?.data?.data?.t1[0]?.mid != 0) {
      ResultScheduler_API();
      dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
      const timeId = setTimeout(() => {
        Mainwallet_API();
      }, 2000);

      return () => clearTimeout(timeId);
    }
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);
  const [selectedTab, setSelectedTab] = React.useState("name");

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  return (
    <div className="center-main-container casino-page ng-tns-c3502172659-0 ng-star-inserted">
      <div className="center-container ng-tns-c3502172659-0">
        <div className="casino-page-container ng-tns-c3502172659-0 teenpatti20">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-0">
            <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-0">
              <li className="nav-item ng-tns-c3502172659-0">
                <div
                  onClick={() => setSelectedTab("name")}
                  // className="ng-tns-c3502172659-0 active nav-link">
                  className={` ng-tns-c3502172659-2
                  ${selectedTab === "name" && "active"}
                  nav-link`}
                >
                  {name}
                </div>
              </li>
              <li
                onClick={() => setSelectedTab("placed")}
                className="nav-item ng-tns-c3502172659-2"
              >
                <div
                  className={`ng-tns-c3502172659-2
                      ${selectedTab != "name" && "active"}
                      nav-link`}
                >
                  Placed Bet ({history?.length})
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c3502172659-0">
              <span className="casino-rid ng-tns-c3502172659-0">
                <a className="d-block ng-tns-c3502172659-0">
                  <small className="ng-tns-c3502172659-0">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c3502172659-0 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c3502172659-0">
            <span className="casino-name ng-tns-c3502172659-0">
              {" "}
              20-20 Teen Patti
              <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-0">
                <small className="ng-tns-c3502172659-0">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-0">
              <small className="ng-tns-c3502172659-0">
                Round ID:{" "}
                <span className="ng-tns-c3502172659-0 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </small>
              <span className="ng-tns-c3502172659-0 ng-star-inserted">
                {" "}
                | Min:
                <span className="ng-tns-c3502172659-0 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.min || 0.0}
                </span>
                | Max:
                <span className="ng-tns-c3502172659-0 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.max || 0.0}
                </span>
              </span>
            </span>
          </div>

          {selectedTab === "name" ? (
            <div className="ng-tns-c3502172659-0 ng-star-inserted">
              <div className="casino-video ng-tns-c3502172659-0">
                <div className="video-box-container ng-tns-c3502172659-0">
                  <div className="casino-video-box ng-tns-c3502172659-0 ng-star-inserted">
                    {/* <iframe
                    className="ng-tns-c3502172659-0"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.teenPattiT20}
                  /> */}
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""
                    />
                  </div>
                </div>
                <div className="ng-tns-c3502172659-0 ng-star-inserted">
                  <div className="casino-video-cards ng-tns-c3502172659-0 ng-star-inserted">
                    <div className="ng-tns-c3502172659-0 ng-star-inserted">
                      <h5 className="ng-tns-c3502172659-0 ng-star-inserted">
                        PLAYER A
                      </h5>
                      <div className="flip-card-container ng-tns-c3502172659-0 ng-star-inserted">
                        <div className="me-lg-2 me-1 ng-tns-c3502172659-0 ng-star-inserted">
                          <img
                            className="ng-tns-c3502172659-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        <div className="me-lg-2 me-1 ng-tns-c3502172659-0 ng-star-inserted">
                          <img
                            className="ng-tns-c3502172659-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        <div className="me-lg-2 me-1 ng-tns-c3502172659-0 ng-star-inserted">
                          <img
                            className="ng-tns-c3502172659-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ng-tns-c3502172659-0 ng-star-inserted">
                      <h5 className="ng-tns-c3502172659-0 ng-star-inserted">
                        PLAYER B
                      </h5>
                      <div className="flip-card-container ng-tns-c3502172659-0 ng-star-inserted">
                        <div className="me-lg-2 me-1 ng-tns-c3502172659-0 ng-star-inserted">
                          <img
                            className="ng-tns-c3502172659-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        <div className="me-lg-2 me-1 ng-tns-c3502172659-0 ng-star-inserted">
                          <img
                            className="ng-tns-c3502172659-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        <div className="me-lg-2 me-1 ng-tns-c3502172659-0 ng-star-inserted">
                          <img
                            className="ng-tns-c3502172659-0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
              </div>

              <div className="casino-detail ng-tns-c3502172659-0">
                <div className="casino-table ng-tns-c3502172659-0 ng-star-inserted">
                  <div className="casino-table-box ng-tns-c3502172659-0">
                    <div className="casino-table-left-box w-100 ng-tns-c3502172659-0">
                      <div className="casino-table-header ng-tns-c3502172659-0">
                        <div className="casino-nation-detail ng-tns-c3502172659-0">
                          <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c3502172659-0">
                            <span className="min_max_span ms-0 ng-tns-c3502172659-0">
                              <b className="ng-tns-c3502172659-0">Min:</b>
                              <span className="ng-tns-c3502172659-0">
                                <span className="ng-tns-c3502172659-0 ng-star-inserted">
                                  {reqRes?.data?.data?.t1[0]?.min || 0.0}
                                </span>
                              </span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c3502172659-0">
                              <b className="ng-tns-c3502172659-0">Max:</b>
                              <span className="ng-tns-c3502172659-0">
                                <span className="ng-tns-c3502172659-0 ng-star-inserted">
                                  {reqRes?.data?.data?.t1[0]?.max || 0.0}
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="casino_odds_row ng-tns-c3502172659-0">
                          <div className="casino-odds-box back ng-tns-c3502172659-0">
                            Back
                          </div>
                          <div className="casino-odds-box back ng-tns-c3502172659-0" />
                        </div>
                      </div>
                      <div className="casino-table-body ng-tns-c3502172659-0">
                        <div className="casino-table-row ng-tns-c3502172659-0">
                          <div className="casino-nation-detail ng-tns-c3502172659-0">
                            <div className="casino-nation-name ng-tns-c3502172659-0">
                              {" "}
                              Player A{" "}
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                            <div className="ng-tns-c3502172659-0 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c3502172659-0">
                                <span className="casino-odds ng-tns-c3502172659-0">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box back ng-tns-c3502172659-0">
                                <span className="casino-odds ng-tns-c3502172659-0">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c3502172659-0 casino_odds_row">
                                <div
                                  className="casino-odds-box back ng-tns-c3502172659-0"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0]?.rate,
                                      reqRes?.data?.data?.t2[0]?.nation,
                                      1
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c3502172659-0">
                                    {reqRes?.data?.data?.t2[0]?.rate}
                                  </span>
                                  <p className="ng-tns-c3502172659-0">
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele, index) =>
                                        ele.number === 1 && (
                                          <span
                                            className={`  ${
                                              ele.number == 1 && ele.amount >= 0
                                                ? "success-1"
                                                : "zero"
                                            }`}
                                            key={index}
                                          >
                                            {ele.number == 1 && ele.amount}
                                          </span>
                                        )
                                    )}{" "}
                                  </p>
                                </div>
                                <div
                                  className="casino-odds-box back ng-tns-c3502172659-0"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1]?.rate,
                                      reqRes?.data?.data?.t2[1]?.nation,
                                      2
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c3502172659-0">
                                    {" "}
                                    {reqRes?.data?.data?.t2[1]?.nation}
                                  </span>
                                  <p className="ng-tns-c3502172659-0">
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele, index) =>
                                        ele.number === 2 && (
                                          <span
                                            className={`  ${
                                              ele.number == 2 && ele.amount >= 0
                                                ? "success-1"
                                                : "zero"
                                            }`}
                                            key={index}
                                          >
                                            {ele.number == 2 && ele.amount}
                                          </span>
                                        )
                                    )}{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="casino-table-row ng-tns-c3502172659-0">
                          <div className="casino-nation-detail ng-tns-c3502172659-0">
                            <div className="casino-nation-name ng-tns-c3502172659-0">
                              {" "}
                              Player B{" "}
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                            <div className="ng-tns-c3502172659-0 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c3502172659-0">
                                <span className="casino-odds ng-tns-c3502172659-0">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box back ng-tns-c3502172659-0">
                                <span className="casino-odds ng-tns-c3502172659-0">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c3502172659-0 casino_odds_row">
                                <div
                                  className="casino-odds-box back ng-tns-c3502172659-0"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2]?.rate,
                                      reqRes?.data?.data?.t2[2]?.nation,
                                      3
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c3502172659-0">
                                    {reqRes?.data?.data?.t2[2]?.rate}
                                  </span>
                                  <p className="ng-tns-c3502172659-0">
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele, index) =>
                                        ele.number === 3 && (
                                          <span
                                            className={`  ${
                                              ele.number == 3 && ele.amount >= 0
                                                ? "success-1"
                                                : "zero"
                                            }`}
                                            key={index}
                                          >
                                            {ele.number == 3 && ele.amount}
                                          </span>
                                        )
                                    )}{" "}
                                  </p>
                                </div>
                                <div
                                  className="casino-odds-box back ng-tns-c3502172659-0"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3]?.rate,
                                      reqRes?.data?.data?.t2[3]?.nation,
                                      4
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c3502172659-0">
                                    {" "}
                                    {reqRes?.data?.data?.t2[3]?.nation}
                                  </span>
                                  <p className="ng-tns-c3502172659-0">
                                    {" "}
                                    {thirtyTwoCardStack.map(
                                      (ele, index) =>
                                        ele.number === 4 && (
                                          <span
                                            className={`  ${
                                              ele.number == 4 && ele.amount >= 0
                                                ? "success-1"
                                                : "zero"
                                            }`}
                                            key={index}
                                          >
                                            {ele.number == 4 && ele.amount}
                                          </span>
                                        )
                                    )}{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ng-tns-c3502172659-0">
                  <div className="casino-last-result-title ng-tns-c3502172659-0">
                    <span className="ng-tns-c3502172659-0">Last Result</span>
                    <span className="ng-tns-c3502172659-0">
                      <a className="ng-tns-c3502172659-0">View All</a>
                    </span>
                  </div>
                  <div className="casino-last-results ng-tns-c3502172659-0 ng-star-inserted">
                    {results?.data?.result?.map((result, index) => {
                      const buttonValue = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      return (
                        <span
                          className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                          key={index}
                        >
                          <span
                            className="ng-tns-c1798067373-0"
                            onClick={() => {
                              setMidRound(result.mid);
                              CasinoGameResult_API(result.mid);
                              // setShowresultModal(true);
                            }}
                            style={{ 
                              color:
                                result?.result === "1" ? "#ff4500" : "inherit",
                            }}
                          >
                            {buttonValue && (
                              <>
                                {
                                  buttonValue?.nation[
                                    buttonValue?.nation?.length - 1
                                  ]
                                }
                              </>
                            )}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CasinoPlacedBet bets={history} />
          )}
          <div className="ng-tns-c3502172659-0 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c3502172659-0">
              <table className="table ng-tns-c3502172659-0">
                <thead className="ng-tns-c3502172659-0">
                  <tr className="ng-tns-c3502172659-0">
                    <th className="ng-tns-c3502172659-0">Matched Bet</th>
                    <th className="text-end ng-tns-c3502172659-0">Odds</th>
                    <th className="text-end ng-tns-c3502172659-0">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
