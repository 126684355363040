import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { io } from "socket.io-client";
import { API } from "../../service/API";
import { useDispatch, useSelector } from "react-redux";
import Timer from "./components/Timer";
import CasinoBetModal from "./CasinoBetModal";

export default function WorliMatka() {
  const id = 10;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const resultMapping = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
  };
  // console.log("results", results);

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);
    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));
        // console.log("parsed", parsedData[0]);
        console.log("Btable", parsedData[0].reqResponse);
        // console.log("status", reqRes?.data?.data?.t2[0]?.gstatus);

        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betIndex, betDetails);
      filteredData[0]?.gstatus === "1" //&&
        ? // betDetails.nat === filteredData[0].nat //&&
          // betDetails.multipler === filteredData[0].rate
          setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  // console.log("history", history);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-8 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-8"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-8 worli"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-8"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-8"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 nav-link"
                >
                  {" "}
                  Placed Bet (0){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-8"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-8"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-8"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-8"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-8"
            >
              {" "}
              Instant Worli
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-8"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-8"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8 ng-star-inserted"
              >
                {" "}
                | Min: 10 {/**/}
                {/**/} | Max: 100000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-8 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-8"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-8 ng-star-inserted"
                >
                  <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                  {/* <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.instantWorli}
                  /> */}
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-cards ng-tns-c1798067373-8 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-container ng-tns-c1798067373-8"
                  >
                    {["C1", "C2", "C3"].map((key, index) => {
                      const cardId = reqRes?.data?.data?.t1[0]?.[key];
                      return cardId && cardId !== '1'  ? (
                        <div
                          key={index}
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-8 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-8 ng-star-inserted"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${cardId}.webp`}
                          />
                        </div>
                      ) : null;
                    })}
                    {/**/}

                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-8 ng-star-inserted"
                    >
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-8 ng-star-inserted"
                    >
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                </div>
                {/**/}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="clock ng-tns-c1798067373-8"
              >
                {/**/}
                <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />

                {/**/}
              </div>
            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-8"
            >
              {/**/}

              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-detail ng-tns-c1798067373-8 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table ng-tns-c1798067373-8"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="d-xl-block d-none w-100 ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8 worlibox"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-left ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-title ng-tns-c1798067373-8"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-8"
                          >
                            {" "}
                            9{" "}
                          </b>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "1 Single", 1);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  1
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "2 Single", 2);
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  2
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "3 Single", 3);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  3
                                </span>
                              </div>
                            </>
                          )}

                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "4 Single", 4);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  4
                                </span>
                              </div>
                            </>
                          )}

                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "5 Single", 5);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  5
                                </span>
                              </div>
                            </>
                          )}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "6 Single", 6);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  6
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "7 Single", 7);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  7
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "8 Single", 8);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  8
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "9 Single", 9);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  9
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "0 Single", 10);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  0
                                </span>
                              </div>
                            </>
                          )}
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-right ng-tns-c1798067373-8"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-title ng-tns-c1798067373-8 ng-star-inserted"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-8"
                          >
                            {" "}
                            9{" "}
                          </b>
                        </div>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Line 1 Single", 11);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  Line 1
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                1|2|3|4|5{" "}
                              </span>
                            </div>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Odd Single", 12);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  ODD
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                1|3|5|7|9{" "}
                              </span>
                            </div>
                          )}

                          {/**/}
                        </div>

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Line 2 Single", 13);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  Line 2
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                6|7|8|9|0{" "}
                              </span>
                            </div>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Even Single", 14);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  EVEN
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                2|4|6|8|0{" "}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="text-end worli_welcome ng-tns-c1798067373-8"
                    >
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-8"
                        >
                          welcome single
                        </b>
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="d-xl-none d-block w-100 ng-tns-c1798067373-8"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="worlibox ng-tns-c1798067373-8"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-box-title ng-tns-c1798067373-8"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-8"
                        >
                          {" "}
                          9
                        </b>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8 w-100 worli-left"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "1 Single", 1);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  1
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "2 Single", 2);
                                  setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  2
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "3 Single", 3);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  3
                                </span>
                              </div>
                            </>
                          )}

                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "4 Single", 4);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  4
                                </span>
                              </div>
                            </>
                          )}

                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "5 Single", 5);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  5
                                </span>
                              </div>
                            </>
                          )}
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "6 Single", 6);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  6
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "7 Single", 7);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  7
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "8 Single", 8);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  8
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "9 Single", 9);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  9
                                </span>
                              </div>
                            </>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back ng-tns-c1798067373-8 ng-star-inserted"
                                onClick={() => {
                                  handleModal(9, "0 Single", 10);
                                  setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                }}
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  0
                                </span>
                              </div>
                            </>
                          )}
                          {/**/}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="worli-box-title mt-3 ng-tns-c1798067373-8"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-8 ng-star-inserted"
                        >
                          9{" "}
                        </b>
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                        {/**/}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-8 w-100 worli-right"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="worli-box-row ng-tns-c1798067373-8"
                        >
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Line 1 Single", 11);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  Line 1
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                1|2|3|4|5{" "}
                              </span>
                            </div>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box Worli_odd_box_mobile back ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Odd Single", 12);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  ODD
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                1|3|5|7|9{" "}
                              </span>
                            </div>
                          )}
                          {/**/}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Line 2 Single", 13);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  Line 2
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                6|7|8|9|0{" "}
                              </span>
                            </div>
                          )}
                          {reqRes?.data?.data?.t2[0]?.gstatus !== "1" ? (
                            <>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted  "
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="worli-odd ng-tns-c1798067373-8 ng-star-inserted"
                                >
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="worli-odd-box back Worli_odd_box_mobile ng-tns-c1798067373-8 ng-star-inserted"
                              onClick={() => {
                                handleModal(9, "Even Single", 14);
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-8"
                                >
                                  EVEN
                                </b>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="d-block ng-tns-c1798067373-8 ng-star-inserted"
                              >
                                2|4|6|8|0{" "}
                              </span>
                            </div>
                          )}

                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-8"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-8"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c1798067373-2 ng-star-inserted">
                  {results?.data?.result?.map((result, index) => {
                    const item = reqRes?.data?.data?.t2.find(
                      (item) => item.sid === result?.result
                    );
                    console.log("item", item);

                    return (
                      <span
                        className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                          className="ng-tns-c1798067373-0"
                        >
                          {<>R</>}
                        </span>
                        {/* <span
                          className="ng-tns-c1798067373-0"
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                        >
                          {buttonValue && (
                            <>
                              {buttonValue.nat[buttonValue.nat.length - 1] ==
                              "t"
                                ? "B"
                                : "A"}
                            </>
                          )}
                        </span> */}
                      </span>
                    );
                  })}
                </div>
                {/**/}
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-8 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-8"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-8"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
