import React, { useEffect, useState } from "react";
import moment from "moment";
import { fetchUnsellectedBet } from "../../redux/api/UnsellectedAPI";
import { useSelector } from "react-redux";

export default function UnSetteledBetNew() {
  const [pageSize, setPageSize] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [tableData, setTableData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const userId = user?.data?.resultid;

  useEffect(() => {
    const fetchunsellectedData = async () => {
      try {
        const data = {
          uid: userId,
          fromDate: "",
          toDate: "",
        };
        const response = await fetchUnsellectedBet(data, token);
        setNoOfPages(Math.ceil(response.length / rowsPerPage));

        console.log("response-->", response);
        setTableData(response);
      } catch (error) {
        console.error("Error fetching unsellectedBet", error);
      }
    };

    fetchunsellectedData();
  }, [userId, token]);
  // Update the current page data whenever tableData or pageSize changes
  useEffect(() => {
    setCurrentPageData(tableData.slice(0, pageSize)); // Show only the first page initially
  }, [tableData, pageSize]);

  const handlePageSizeChange = (e) => {
    const newPageSize = Number(e.target.value);
    setRowsPerPage(e.target.value);

    setPageSize(newPageSize);
    setCurrentPageData(tableData.slice(0, newPageSize)); // Reset to show first page data
  };

  useEffect(() => {
    // Calculate start and end index for the current page
    const startIndex = pageNumber * pageSize;
    const endIndex = startIndex + pageSize;
    setCurrentPageData(tableData.slice(startIndex, endIndex));
  }, [tableData, pageNumber, pageSize]);


  const goToFirstPage = () => setPageNumber(0);
  const goToLastPage = () => setPageNumber(noOfPages - 1);
  const goToNextPage = () => {
    if (pageNumber < noOfPages - 1) {
      setPageNumber(pageNumber + 1);
    }
  };
  const goToPrevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const isMobile = window.innerWidth < 786;
  return (
    <div
      className={`col-12 report-main-content m-t-5`}
      style={{
        width: isMobile ? "100%" : "82%",
      }}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Un-Setteled Bet</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5 unsetteledbet">
          <div className="row  row5 mt-2">
            <div className={isMobile ? "col-12 " : "col-2"}>
              <label
                style={{
                  marginLeft: "10px",
                }}
                className="col-form-label"
              >
                Size
              </label>
              <div className={isMobile ? "col-12" : "col-5"}>
                <select
                  onChange={handlePageSizeChange}
                  className="form-control form-control-sm"
                  value={pageSize}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              {/* <div className={isMobile ? "col-12" : "col-5"}>
                <select
                  onChange={(e) => {
                    setPageSize(e.target.value);
                  }}
                  className="form-control form-control-sm"
                  value={pageSize}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div> */}
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={10}
                  className="table b-table table-bordered"
                  id="__BVID__97"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Event Name
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-center"
                      >
                        Nation
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={4}
                        className="text-center"
                      >
                        Event Type
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={5}
                        className="text-center"
                      >
                        Match
                      </th>
                      {/* <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={6}
                        className="text-center"
                      >
                        Side
                      </th> */}
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={7}
                        className="text-center"
                      >
                        Rate
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={8}
                        className="text-right"
                      >
                        Amount
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={9}
                        className="text-center"
                      >
                        Place Date
                      </th>
                      {/* <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={10}
                        className=""
                      >
                        Match Date
                      </th> */}
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {currentPageData?.map((item, index) => (
                      <tr role="row" key={index}>
                        <td role="cell" className="text-left">
                          {item.remark}
                        </td>
                        <td role="cell" className="text-center">
                          {item.team}
                        </td>
                        <td role="cell" className="text-center">
                          {item.type}
                        </td>
                        <td role="cell" className="text-center">
                          {item.name}
                        </td>
                        {/* <td role="cell" className="text-center">
                          {item.rate}
                        </td> */}
                        <td role="cell" className="text-right">
                          {item.back || item.lay}
                        </td>
                        <td
                          role="cell"
                          className={`text-right ${
                            item.loss ? "text-danger" : "text-sucess"
                          }`}
                        >
                          {item?.profit}
                        </td>
                        <td role="cell" className="text-center">
                          {item?.edate}
                        </td>
                        {/* <td role="cell" className="text-center">
                          {moment(item?.created_date + "Z")?.format(
                            "DD:MM:YYYY HH:mm:ss"
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <ul className="pagination mb-0 b-pagination justify-content-center">
                <li
                  className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={goToFirstPage}
                    disabled={pageNumber === 0}
                  >
                    First
                  </button>
                </li>
                <li
                  className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={goToPrevPage}
                    disabled={pageNumber === 0}
                  >
                    Prev
                  </button>
                </li>

                {Array.from({ length: noOfPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      pageNumber === index ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setPageNumber(index)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    pageNumber === noOfPages - 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={goToNextPage}
                    disabled={pageNumber === noOfPages - 1}
                  >
                    Next
                  </button>
                </li>
                <li
                  className={`page-item ${
                    pageNumber === noOfPages - 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={goToLastPage}
                    disabled={pageNumber === noOfPages - 1}
                  >
                    Last
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
