import { CASINO_VIDEO_URL } from "../../utils/constants";
import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import { io } from "socket.io-client";
import { API } from "../../service/API";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import CasinoBetModal from "./CasinoBetModal";
import CasinoPlacedBet from "./components/CasinoPlaceBet";
export default function AndarBahar() {
  const dispatch = useDispatch();

  const id = 14;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  // const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");

  // const { casinobetHistory, loading } = useSelector(
  //   (state) => state.casinobetHistory
  // );

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const [betno, setBetNum] = useState(0);
  const [betDetails, setbetDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);

  // Number of cards to show per slide
  const cardsPerSlide = 3;

  // Total number of cards
  const totalCards = reqRes?.data?.data?.t2?.length;

  // Function to handle "Next" button click
  const handleNext = () => {
    if (currentIndex < totalCards - cardsPerSlide) {
      setCurrentIndex(currentIndex + cardsPerSlide);
    }
  };

  // Function to handle "Previous" button click
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - cardsPerSlide);
    }
  };

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult lucky:", response?.data?.data[0]);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: item?.resultUrlResponse
            ? JSON.parse(item?.resultUrlResponse)
            : null,
        }));

        console.log("Ander1", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0]?.reqResponse);
        setreqRes(parsedData[0]?.reqResponse);
        setName(parsedData[0]?.name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };
  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,

      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nat === filteredData[0]?.nat &&
      betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const [selectedTab, setSelectedTab] = React.useState("game");
  // const isMobile = window.innerWidth < 768;
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-3 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-3"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-3 ab ab3"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-3"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-3"
            >
              <li
                      onClick={() => setSelectedTab("game")}
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-3"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 active nav-link"
                >
                  Game
                </div>
              </li>
              <li
                       onClick={() => setSelectedTab("placed")}
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-3"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 nav-link"
                >
                  {" "}
                  Placed Bet ({history?.length})
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-3"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-3"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-3"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-3"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-3"
            >
              {" "}
              Andar Bahar
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-3"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-3"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-3"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}{" "}
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-3 ng-star-inserted"
              >
                {" "}
                | Min:
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {" "}
                  100{" "}
                </span>
                {/**/}
                {/**/} | Max:
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {" "}
                  150000{" "}
                </span>
                {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          {selectedTab === "game" ? (
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-3 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-video ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="video-box-container ng-tns-c1798067373-3"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-box ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {/* <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.anderBahr1}
                  /> */}
                   <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                </div>
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-3 ng-star-inserted"
              >
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-video-cards ng-tns-c1798067373-3 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="ab-cards-container ng-tns-c1798067373-3 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="row row5 align-items-center ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="col-12 ng-tns-c1798067373-3"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="row row5 mb-1 ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="col-12 ng-tns-c1798067373-3"
                          >
                            Andar
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="col-lg-12 col-12 ng-tns-c1798067373-3"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-result-cards col-lg-12 ng-tns-c1798067373-3"
                            >
                              {/**/}
                              {/**/}
                              <button
                                _ngcontent-ng-c1798067373=""
                                type="button"
                                className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-whi.webp"
                                />
                              </button>
                              {/**/}
                              <button
                                _ngcontent-ng-c1798067373=""
                                type="button"
                                className="ab-next d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_white.webp"
                                />
                              </button>
                              {/**/}
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="casino-result-cardimg andharbahar1 ng-tns-c1798067373-3"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="andharbahar1_slide ng-tns-c1798067373-3"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10CC.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9SS.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7DD.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2HH.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KSS.webp"
                                    />
                                  </div>
                                  {/**/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**/}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="row row5 ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="col-12 ng-tns-c1798067373-3"
                          >
                            Bahar
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="col-lg-12 col-12 ng-tns-c1798067373-3"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-result-cards col-lg-12 ng-tns-c1798067373-3"
                            >
                              {/**/}
                              {/**/}
                              <button
                                _ngcontent-ng-c1798067373=""
                                type="button"
                                className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-whi.webp"
                                />
                              </button>
                              {/**/}
                              <button
                                _ngcontent-ng-c1798067373=""
                                type="button"
                                className="ab-next d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_white.webp"
                                />
                              </button>
                              {/**/}
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="casino-result-cardimg andharbahar1 ng-tns-c1798067373-3"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="andharbahar1_slide ng-tns-c1798067373-3"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5SS.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4HH.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5HH.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KCC.webp"
                                    />
                                  </div>
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                  >
                                    <img
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/JHH.webp"
                                    />
                                  </div>
                                  {/**/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/**/}
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              {/**/}
              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
            </div>
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-detail ng-tns-c1798067373-3"
            >
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-xl-block d-none ng-tns-c1798067373-3"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="andar-box ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ab-title ng-tns-c1798067373-3"
                      >
                        ANDAR
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ab-cards ng-tns-c1798067373-3"
                      >
                        {reqRes?.data?.data?.t2
                          ?.slice(0, 13)
                          ?.map((item, index) => (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
                              key={index}
                            >
                              {item.gstatus == 0 ? (
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3 suspended-box"
                                >
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-3 ng-star-inserted"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                      CARD_ARRAY[index]
                                    }.webp`}
                                    alt={`Card ${CARD_ARRAY[index]}`}
                                  />
                                </div>
                              ) : (
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3"
                                  onClick={() => {
                                    handleModal(item.rate, item.nat, index + 1);
                                    setBetIndex(item.sid);
                                  }}
                                >
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                      CARD_ARRAY[index]
                                    }.webp`}
                                    alt={`Card ${CARD_ARRAY[index]}`}
                                  />
                                </div>
                              )}

                              <div
                                _ngcontent-ng-c1798067373=""
                                className="casino-nation-book ng-tns-c1798067373-3"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === index + 1 && (
                                      <span
                                        _ngcontent-ng-c1798067373=""
                                        className={` ${
                                          ele.number == index + 1 &&
                                          ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == index + 1 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                          ))}

                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="item ng-tns-c1798067373-3 NotclickAble"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              onerror="this.src='assets/images/cards/ab/0.webp'"
                              className="ng-tns-c1798067373-3 ng-star-inserted"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/ab/0.webp"
                            />
                
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-book ng-tns-c1798067373-3"
                          >
                         
                            0
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="bahar-box ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ab-title ng-tns-c1798067373-3"
                      >
                        BAHAR
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ab-cards ng-tns-c1798067373-3"
                      >
                        {reqRes?.data?.data?.t2
                          ?.slice(13, 26)
                          ?.map((item, index) => (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
                              key={index}
                            >
                              {item.gstatus == 0 ? (
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3 suspended-box"
                                >
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-3 ng-star-inserted"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                      CARD_ARRAY[index]
                                    }.webp`}
                                    alt={`Card ${CARD_ARRAY[index]}`}
                                  />
                                </div>
                              ) : (
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-3"
                                  onClick={() => {
                                    handleModal(
                                      item.rate,
                                      item.nat,
                                      index + 14
                                    );
                                    setBetIndex(item.sid);
                                  }}
                                >
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-0 ng-star-inserted"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                      CARD_ARRAY[index]
                                    }.webp`}
                                    alt={`Card ${CARD_ARRAY[index]}`}
                                  />
                                </div>
                              )}

                              <div
                                _ngcontent-ng-c1798067373=""
                                className="casino-nation-book ng-tns-c1798067373-3"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === index + 14 && (
                                      <span
                                        _ngcontent-ng-c1798067373=""
                                        className={` ${
                                          ele.number == index + 14 &&
                                          ele.amount >= 0
                                            ? "success-1"
                                            : "zero"
                                        }`}
                                      >
                                        {ele.number == index + 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </div>
                            </div>
                          ))}

                        {/* <div
                          _ngcontent-ng-c1798067373=""
                          className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="item ng-tns-c1798067373-3 NotclickAble"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              onerror="this.src='assets/images/cards/ab/0.webp'"
                              className="ng-tns-c1798067373-3 ng-star-inserted"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/ab/33.webp"
                            />
                       
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-book ng-tns-c1798067373-3"
                          >
                            {" "}
                            0
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="d-xl-none d-block mobileandarbahar w-100 ng-tns-c1798067373-3"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="andar-boxmob ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="andar_boxmob_title ng-tns-c1798067373-3"
                      >
                        Andar
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="andar-boxmob-slider ng-tns-c1798067373-3"
                      >
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          onClick={handlePrevious}
                          disabled={currentIndex === 0}
                          className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-black.webp"
                          />
                        </button>
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          onClick={handleNext}
                          disabled={currentIndex >= totalCards - cardsPerSlide}
                          className="ab-next d-lg-none d-block ng-tns-c1798067373-3"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_black.webp"
                          />
                        </button>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="draggable ng-tns-c1798067373-3"
                        >
                          {reqRes?.data?.data?.t2
                            ?.slice(0, 13)
                            ?.map((item, index) => (
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-3 ng-star-inserted"
                                key={index}
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="card-odd-box ng-tns-c1798067373-3"
                                >
                                  {item.gstatus == 0 ? (
                                    <div
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3 NotclickAble suspended-box"
                                    >
                                      <img
                                        _ngcontent-ng-c1798067373=""
                                        className="ng-tns-c1798067373-3 ng-star-inserted"
                                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                          CARD_ARRAY[index]
                                        }.webp`}
                                        alt={`Card ${CARD_ARRAY[index]}`}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-3"
                                      onClick={() => {
                                        handleModal(
                                          item.rate,
                                          item.nat,
                                          index + 1
                                        );
                                        setBetIndex(item.sid);
                                      }}
                                    >
                                      <img
                                        _ngcontent-ng-c1798067373=""
                                        className="ng-tns-c1798067373-3 ng-star-inserted"
                                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                          CARD_ARRAY[index]
                                        }.webp`}
                                        alt={`Card ${CARD_ARRAY[index]}`}
                                      />
                                    </div>
                                  )}

                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="casino-nation-book ng-tns-c1798067373-3"
                                  >
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === index + 1 && (
                                          <span
                                            _ngcontent-ng-c1798067373=""
                                            className={` ${
                                              ele.number == index + 1 &&
                                              ele.amount >= 0
                                                ? "success-1"
                                                : "zero"
                                            }`}
                                          >
                                            {ele.number == index + 1 &&
                                              ele.amount}
                                          </span>
                                        )
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                          {/* <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="card-odd-box ng-tns-c1798067373-3"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-3 NotclickAble"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  onerror="this.src='assets/images/cards/ab/0.webp'"
                                  className="ng-tns-c1798067373-3 ng-star-inserted"
                                  src="https://247maharaja.com/assets/images/cards/ab/0.webp"
                                />
                               
                              </div>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="casino-nation-book ng-tns-c1798067373-3"
                              >
                              
                                0
                              </div>
                            </div>
                          </div> */}
                          {/**/}
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="lightgreen_box ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="andar_boxmob_title ng-tns-c1798067373-3"
                      >
                        Bahar
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="andar-boxmob-slider ng-tns-c1798067373-3"
                      >
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-black.webp"
                          />
                        </button>
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          className="ab-next d-lg-none d-block ng-tns-c1798067373-3"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_black.webp"
                          />
                        </button>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="draggable ng-tns-c1798067373-3"
                        >
                        
        {reqRes?.data?.data?.t2
                            ?.slice(13, 26)
                            ?.map((item, index) => (
                              <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-3 ng-star-inserted"
                              key={index}
                            >
                              <div
                               
                                // data-slick-index={index - 5}
                                // aria-hidden="true"
                                // tabIndex={-1}
                                className="card-odd-box ng-tns-c1798067373-3"
                                // style={{ width: 57 }}
                              >
                                {item.gstatus == 0 ? (
                                  <div className="ng-tns-c1798067373-3 NotclickAble suspended-box">
                                    <img
                                      className="ng-tns-c1798067373-3 ng-star-inserted"
                                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                        CARD_ARRAY[index]
                                      }.webp`}
                                      onError={(e) =>
                                        (e.target.src =
                                          "assets/images/cards/ab/0.webp")
                                      }
                                      alt={`Card ${CARD_ARRAY[index]}`}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="ng-tns-c1798067373-3"
                                    onClick={() => {
                                      handleModal(
                                        item.rate,
                                        item.nat,
                                        index + 14
                                      );
                                      setBetIndex(item.sid);
                                    }}
                                  >
                                    <img
                                      className="ng-tns-c1798067373-0 ng-star-inserted"
                                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                        CARD_ARRAY[index]
                                      }.webp`}
                                      onError={(e) =>
                                        (e.target.src =
                                          "assets/images/cards/ab/0.webp")
                                      }
                                      alt={`Card ${CARD_ARRAY[index]}`}
                                    />
                                  </div>
                                )}

                                <div className="casino-nation-book ng-tns-c1798067373-3">
                                  {thirtyTwoCardStack.map(
                                    (ele) =>
                                      ele.number === index + 14 && (
                                        <span
                                          className={` ${
                                            ele.amount >= 0
                                              ? "success-1"
                                              : "zero"
                                          }`}
                                        >
                                          {ele.amount}
                                        </span>
                                      )
                                  )}
                                </div>
                              </div>
                              </div>
                            ))}
                          
                  

                          {/* <div
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3 ng-star-inserted"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              data-slick-index={-5}
                              aria-hidden="true"
                              tabIndex={-1}
                              className="card-odd-box ng-tns-c1798067373-3"
                              style={{ width: 57 }}
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-3 NotclickAble"
                              >
                                <img
                                  _ngcontent-ng-c1798067373=""
                                  onerror="this.src='assets/images/cards/ab/0.webp'"
                                  className="ng-tns-c1798067373-3 ng-star-inserted"
                                  src="https://247maharaja.com/assets/images/cards/ab/0.webp"
                                />
                            
                              </div>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="casino-nation-book ng-tns-c1798067373-3"
                              >
                                
                                0
                              </div>
                            </div>
                          </div> */}
                          {/**/}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div className="ng-tns-c1798067373-2">
                <div className="casino-last-result-title ng-tns-c1798067373-2">
                  <span className="ng-tns-c1798067373-2">Last Result</span>
                  <span className="ng-tns-c1798067373-2">
                    <a className="ng-tns-c1798067373-2">View All</a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c1798067373-2 ng-star-inserted">
                  {results?.data?.result?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      console.log("item", item);

                    return (
                      <span
                        className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                        key={index}
                      >
                        <span
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                             className="ng-tns-c1798067373-0"
                       
                        >
                          {<>R</>}
                        </span>
                        {/* <span
                          className="ng-tns-c1798067373-0"
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                        >
                          {buttonValue && (
                            <>
                              {buttonValue.nat[buttonValue.nat.length - 1] ==
                              "t"
                                ? "B"
                                : "A"}
                            </>
                          )}
                        </span> */}
                      </span>
                    );
                  })}
                </div>
              </div>
              {/*
            
              <div
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-3"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-result-title ng-tns-c1798067373-3"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    Last Result
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      View All
                    </a>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-last-results ng-tns-c1798067373-3 ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 result result-b ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      {" "}
                      R
                    </span>
                  </span>
              
                </div>
                
              </div>
              */}
            </div>
          </div> ) : (
  <CasinoPlacedBet bets={history} />
)}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-3 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-3"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-3"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-3"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-3"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-3"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
