import React, { useState, useEffect } from "react";
import {
  changeButtonValuesAPI,
  getButtonValuesAPI,
} from "../../service/AuthService";
import { API } from "../../service/API";

import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchBetButton } from "../../redux/features/betButtonSlice";

export default function SetButtonValue() {
  // const [buttons, setButtons] = React.useState({});
  // const handleUpdate = (key, value) => {
  //   setButtons((prev) => ({ ...prev, [key]: value }));
  // };
  // const getButtonValues = async () => {
  //   const { response } = await getButtonValuesAPI();
  //   setButtons(response);
  // };
  // const handleSave = async () => {
  //   const { response, code } = await changeButtonValuesAPI(buttons);
  //   if (code === 200) {
  //     toast.success("Button Values Updated Successfully");
  //   }
  //   getButtonValues();
  // };
  // useEffect(() => {
  //   getButtonValues();
  // }, []);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uId = user?.data?.resultid;
  const dispatch = useDispatch();
  const { buttonData } = useSelector((state) => state.buttonData);
  const [data, setData] = useState(buttonData);
  useEffect(() => {
    dispatch(fetchBetButton({ id: uId, token }));
  }, [dispatch, uId]);
  useEffect(() => {
    setData(buttonData);
  }, [buttonData]);
console.log("ddddasa",data);


  const handleInputChange = (event, id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        const sanitizedValue = event.target.value;
        return {
          ...item,
          price_level:
            event.target.name === "price_level"
              ? sanitizedValue.replace(/[^0-9k]/gi, "").substring(0, 9)
              : item.price_level,
          price_value:
            event.target.name === "price_value"
              ? sanitizedValue.replace(/[^0-9]/gi, "").substring(0, 9)
              : item.price_value,
        };
      }
      return item;
    });
    // console.log("update", updatedData);
    setData(updatedData);
  };

  const handleButtonClick = async () => {
    try {
      const promises = data.map(async (item) => {
        let res = await API.post(
          "Update_Button_value_list",
          {
            id: item.id,
            uid: uId,
            price_level: item.price_level,
            price_value: item.price_value,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // console.log("res",res)
        return res.data;
      });
      // console.log("Button_Api_promises", promises);
      const results = await Promise.all(promises);

      const isSuccess = results.every((res) => res.success);
      console.log("isSuccess", isSuccess);
      if (isSuccess) {
        toast.success("Successful");
        // Button_Api();
        dispatch(fetchBetButton({ id: uId, token }));
      } else {
        toast.error("Failed to update some items");
      }
      // setData(res);
    } catch (e) {
      console.log("Error While Fatch Button_Api", e);
    }
  };

  const isMobile = window.innerWidth < 768;
  return (
    <div
      style={{ width: isMobile ? "100%" : "82%" }}
      className={`col-md-12 report-main-content  m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Change Button Values</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5 button-value">
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="button-title">
                <span>
                  <b>Price Label</b>
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="button-title">
                <span>
                  <b>Price Value</b>
                </span>
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
            {data.map((item) => (
              <div className="form-group mb-0" key={item.id}>
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  onChange={(e) =>
                    handleInputChange(e, item.id)
                  }
                
                 value={item?.price_level}
                  // onChange={(e) =>
                  //   handleUpdate("buttonName1", e.target.value)
                  // }
                />
              </div>
            ))}
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
            {data.map((item) => (

              <div className="form-group mb-0"  key={item.id}>
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={item.price_value}
                  onChange={(e) =>
                    handleInputChange(e, item.id)
                  }
                  // maxLength={9}
                  // value={buttons?.buttonValue1}
                  // onChange={(e) =>
                  //   handleUpdate("buttonValue1", e.target.value)
                  // }
                />
              </div>
            ))}
            </div>
          </div>

          <div className="row row5 mt-2">
            <div className="col-12">
              <button
                onClick={() => handleButtonClick()}
                className="btn btn-primary"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
