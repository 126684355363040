import React from "react";
import "../index.scoped.css";

export default function CasinoPlacedBet({ bets }) {
  return (
    <>
      {window.innerWidth < 768 && (
        <div className="ng-tns-c3502172659-3 matchbettable w-100 ng-star-inserted">
          <div className="table-responsive ng-tns-c3502172659-3">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Period</th>
                  <th>Multiplier</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Remark</th>
                  <th>Card</th>
                  {/* <th>Matched Bet</th>
                        <th className="text-end">Odds</th>
                        <th className="text-end">Stake</th> */}
                </tr>
              </thead>
              <tbody>
                {bets.length > 0 ? (
                  bets.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.period}</td>
                      <td>{item.multipler}</td>
                      <td>{item.amount}</td>
                      <td>{item.dd}</td>
                      <td
                        className={
                          item.remark === "Loss" ? "zero" : "success-1"
                        }
                      >
                        {item.remark}
                      </td>
                      <td>{item.card}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Data
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* <table className="table ng-tns-c3502172659-3">
              <thead className="ng-tns-c3502172659-3">
                <tr className="ng-tns-c3502172659-3">
                  <th className="ng-tns-c3502172659-3">Matched Bet</th>
                  <th className="text-end ng-tns-c3502172659-3">Odds</th>
                  <th className="text-end ng-tns-c3502172659-3">Stake</th>
                </tr>
              </thead>
              <tbody className="ng-tns-c3502172659-3">
                {bets?.map((item) => (
                  <tr className="ng-tns-c3502172659-3">
                    <td className="ng-tns-c3502172659-3">{item?.runnerName}</td>
                    <td className="text-end ng-tns-c3502172659-3">
                      {item?.back ? item?.back : item?.lay}
                    </td>
                    <td className="text-end ng-tns-c3502172659-3">
                      {item?.amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
          </div>
        </div>
      )}
    </>
  );
}
