import React from "react";
import { useParams } from "react-router-dom";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import GameSidebar from "../gameComponents/GameSidebar";
import { io } from "socket.io-client"; 
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { applyFancyFor, DEFAULT_BET_AMOUNT } from "../../../utils/constants";
import { getLiveStreamingUrlAPI } from "../../../service/AuthService";
import LadderModal from "../gameComponents/LadderModal";
import { saveTeams } from "../../../redux/features/SaveTeamsSlice";
import { fetchbetStackData } from "../../../redux/api/betStackAPI";
import Modal from "../gameComponents/Modal";

export default function Soccer() {
  const [userBets, setUserBets] = useState([]);
  const [betPayload, setBetPayload] = useState(null);

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";
  // const uid = sessionStorage.getItem("userData");
  const { sessionTeams } = useSelector((state) => state.sessionStack);
  const { bookStack } = useSelector((state) => state.betStack);

  const { oddStack } = useSelector((state) => state.betStack);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  // const token = sessionStorage.getItem("token");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [socketData, setSocketData] = useState({});
  const [realtimeData, setRealtimeData] = useState(false);
  const { id: idOdd } = useSelector((state) => state.auth.currentMatch);
  const [marketType, setmarketType] = useState(null);

  const { id } = useParams();
  const [chID, setchID] = useState("");
  const [betType, setbetType] = useState(null);
  const [betDetails, setbetDetails] = useState({});
  console.log("bet", betDetails);
  console.log("token", token);

  const [countryName, setCountryName] = useState(null);
  const [score, setScore] = useState({});
  const [ShowScore, setShowScore] = useState(false);
  const [activeTab, setActiveTab] = useState("odds");
  const [isTvOpen, setIsTvOpen] = useState(false);
  const {
    ComName,
    openDate,
    eventTypeid,
    match_id,
    name,
    ldate1,
  } = useSelector((state) => state.auth.currentMatch);

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit("CricketAllOddMatchType", id);
  //   cricketMatchesSocket.on("CricketAllOddMatchType_FromAPI", (data) => {
  //     let parsedata = JSON.parse(data);
  //     console.log("match_data--->", parsedata?.data);
  //     if (parsedata.statusCode === 200) {
  //       setSocketData(parsedata?.data);
  //     }
  //     // console.log("socket", socketData);
  //   });
  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, [id]);

  useEffect(() => {
    const FootballMatchesSocket = io(SOCKET_URL);
    FootballMatchesSocket.emit("SoccerAllOddMatchType", id);
    FootballMatchesSocket.on("SoccerAllOddMatchType_FromAPI", (data) => {
      let parsedata = JSON.parse(data);
      console.log("match_football", parsedata);
      if (parsedata.statusCode === 200) {
        setSocketData(parsedata?.data);
      }
    });
    return () => {
      FootballMatchesSocket.disconnect();
    };
  }, [id]);
  
  const date = moment(openDate);
  const adjustedDate = date.subtract(5, "hours").subtract(30, "minutes");
  const formattedTime = adjustedDate.format("h:mm:ss A");
  const adjustedISODate = adjustedDate.toISOString();
  const [modal, setModal] = useState(false);
  const [count, setCount] = useState(0);

  const [dataValue, setDataValue] = useState("");

  const checkValid = () => {
    if (realtimeData && marketType === "Match Odds") {
      const filteredData = socketData?.matchOddsResponseDTO[0]?.oddDatas?.filter(
        (item) => item.rname == dataValue?.team && item.sid == dataValue?.selId
      );
      console.log("filter", filteredData);
      if (filteredData[0]?.l1 === filteredData[0]?.b1) {
        setRealtimeData(false);
        setDataValue({});
      }
      if (betType === "back") {
        if (
          filteredData[0]?.sid == dataValue?.selId &&
          filteredData[0]?.status != "SUSPENDED" &&
          filteredData[0]?.b1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.l1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  const SaveTeams_API = async (mname, team, sid) => {
    dispatch(saveTeams({ id, mname, team, token, sid, uid }));
  };

  useEffect(() => {
    if (
      socketData?.matchOddsResponseDTO &&
      socketData?.matchOddsResponseDTO[0]?.oddDatas &&
      socketData?.matchOddsResponseDTO[0]?.oddDatas.length > 0 &&
      count < 1
    ) {
      socketData?.matchOddsResponseDTO[0]?.oddDatas?.forEach((runner) => {
        SaveTeams_API("Match Odds", runner.rname, runner.sid);
      });
      setCount(count + 1);
    }
  }, [socketData]);

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, socketData]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleClick = (betDetails, bettype, marketType, value) => {
    handleModal(true);
    setRealtimeData(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    // setsubBetDetails(betDetails);
    setDataValue(value);
  };

  const handleFancyClick = (
    betDetails,
    bettype,
    marketType,
    countryName,
    betvalue
  ) => {
    handleModal(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    setCountryName(countryName);
    setDataValue(betvalue);
    setRealtimeData(true);
  };

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId: uid,
        mId: id,
        matchType: "Match Odds",
        token,
      })
    );
  }, [dispatch, id]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://scoreapi.365cric.com/api/match/getStream?Cno=" + id
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        // setData(jsonData.data);
        const chID = jsonData?.result?.channelNo;
        setchID(chID); 
        console.log("jsonData", jsonData);
        console.log("id", id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, chID]);

  useEffect(() => {
    if (eventTypeid == 4) {
      const cricketMatchesSocket = io(SOCKET_URL);
      cricketMatchesSocket.emit("GetIframe", match_id || id, eventTypeid);
      cricketMatchesSocket.on("GetIframe_FromAPI", (data) => {
        // console.log(
        //   "score",
        //   data.data.teams[0].score.split("(")[1].split(")")[0],
        //   data.data.teams[0].score.split("(")[0]
        // );
        console.log("getframedata", data);

        if (
          data.statusCode == 200 &&
          data.data &&
          data.data.message === "Match score fetched." &&
          data.data.data &&
          Object.keys(data.data.data).length > 0
        ) {
          setShowScore(true);
          setScore(data.data.data);
        } else {
          setShowScore(false);
        }
      });

      return () => {
        cricketMatchesSocket.disconnect();
      };
    }
  }, [id, eventTypeid]);

  // ==========================Timer with Moment.js======================

  const calculateTimeLeft = () => {
    const now = moment();
    const difference = moment(adjustedISODate).diff(now);
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(moment.duration(difference).asDays()),
        hours: moment.duration(difference).hours(),
        minutes: moment.duration(difference).minutes(),
        seconds: moment.duration(difference).seconds(),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const { days, hours, minutes, seconds } = timeLeft;

  const checkIfFloat = (value) => {
    return value % 1 != 0 ? parseFloat(value).toFixed(2) : parseInt(value);
  };

  const amountFormatter = (amount, toL = true) => {
    if (amount > 10000000) {
      return amount / 10000000 + "Cr";
    } else if (amount > 100000) {
      return amount / 100000 + "L";
    } else if (amount > 1000) {
      return amount / 1000 + "K";
    } else {
      return amount;
    }
  };

  return (
    <div className="center-main-container detail-page">
      <div className="center-container">
        <div className="detail-page-container">
          <div className="game-header">
            <span>Game event {name}</span>
            <span className="float-right">
              {moment(adjustedISODate).isSameOrAfter(moment())
                ? ldate1
                : "IN-PLAY"}
            </span>

            {/* <span className="float-right">
            {new Date(adjustedDate) >= new Date() ? ldate1 : "IN-PLAY"}
            </span> */}
          </div> 
          {/* <div className="scorecard">
            <iframe
              style={{
                width: "100%",
                height: "auto",
              }}
              // src={`https://scoredata.365cric.com/#/score3/516`}

              src={`https://score.proexch.in/#/score1/${eventTypeid}`}
            />
          </div> */}

          <ul className="nav nav-tabs d-xl-none menu-tabs">
            <li onClick={() => setActiveTab("odds")} className="nav-item">
              <a className="nav-link active" data-bs-toggle="tab">
                Odds
              </a>
            </li>
            <li onClick={() => setActiveTab("bets")} className="nav-item">
              <a className="nav-link" data-bs-toggle="tab">
                Matched Bet
                {/* ({userBets?.length}) */}
              </a>
            </li>
            <li onClick={() => setIsTvOpen(!isTvOpen)} className="nav-item">
              <a className="nav-link">
                <i className="fas fa-tv" />
              </a>
            </li>
          </ul>
          {isTvOpen && (
            <iframe
              style={{
                width: "100%",
                height: "200px",
              }}
              src={`https://video.proexch.in/tv/static?chid=${chID}`}
            />
          )}
          {activeTab == "bets" ? (
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>Matched Bet</th>
                    <th className="text-end">Odds</th>
                    <th className="text-end">Stake</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {userBets?.map((bet) => (
                    <tr className={bet?.back ? "back" : "lay"}>
                      <td>
                        <span className="bet-name">{bet?.runnerName}</span>
                      </td>
                      <td className="text-end">
                        <span className="bet-odd">
                          {bet?.back ? bet?.back : bet?.lay}
                        </span>
                      </td>
                      <td className="text-end">
                        <span className="bet-stake">{bet?.amount}</span>
                      </td>
                    </tr>
                  ))} */}
                </tbody>
              </table>
            </div>
          ) : (
            <>
              {socketData?.matchOddsResponseDTO && (
                <div className="game-market market-4 ">
                  <div className="market-title">
                    <span>MATCH_ODDS</span>
                  </div>
                  <div className="market-header">
                    <div className="market-nation-detail">
                      <span className="market-nation-name">
                        Max:{" "}
                        {/* {amountFormatter(eventExposureAndSettings?.maxStack)} */}
                      </span>
                    </div>
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box back">
                      <b>Back</b>
                    </div>
                    <div className="market-odd-box lay">
                      <b>Lay</b>
                    </div>
                    <div className="market-odd-box" />
                    <div className="market-odd-box no-border" />
                  </div>
                  <div
                    className="market-body "

                    // data-title={gameOdds?.matchOddsResponseDTO[0]?.marketStatus}
                  >
                    {socketData?.matchOddsResponseDTO[0]?.oddDatas?.length > 0 &&
                      socketData?.matchOddsResponseDTO[0]?.oddDatas
                        ?.sort((a, b) => a?.rname?.localeCompare(b.rname))
                        ?.map((item) => (
                          <div
                            className={`market-row ${
                              item?.status?.toUpperCase() !== "ACTIVE"
                                ? "suspended-row"
                                : ""
                            }`}
                          >
                            <div className="market-nation-detail">
                              <span className="market-nation-name">
                                {item?.rname}
                              </span>

                              <div className="market-nation-book">
                                  <div className="market-book">
                                    <span
                                      style={{
                                        color:
                                          oddStack?.filter(
                                            (ele) => ele.team == item?.rname
                                          )[0]?.amount < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {
                                        oddStack?.filter(
                                          (ele) => ele.team == item?.rname
                                        )[0]?.amount
                                      }
                                    </span>
                                  </div>
                                </div>
                            </div>
                            <div
                              className="market-odd-box back2"
                              onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item.sid,
                                    runnerName: item.rname,
                                    handicap: 0,
                                    status: item?.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: item?.b3,
                                    Backprice: item?.b3,
                                    Backsize: item?.bs3,
                                    Layprice1: 0,
                                    Layprice: 0,
                                    Laysize: 0,
                                    min: 0,
                                    max: 0,
                                    mname: "Match Odds",
                                    betRunnerName: item?.rname,
                                  },
                                  "back",
                                  "Match Odds",
                                  {
                                    valueOf: item?.b3,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.b3)}
                              </span>
                              <span className="market-volume">{item?.bs3}</span>
                            </div>
                            <div
                              className="market-odd-box back1"
                              onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item.sid,
                                    runnerName: item.rname,
                                    handicap: 0,
                                    status: item?.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: item?.b2,
                                    Backprice: item?.b2,
                                    Backsize: item?.bs2,
                                    Layprice1: 0,
                                    Layprice: 0,
                                    Laysize: 0,
                                    min: 0,
                                    max: 0,
                                    mname: "Match Odds",
                                    betRunnerName: item?.rname,
                                  },
                                  "back",
                                  "Match Odds",
                                  {
                                    valueOf: item?.b2,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.b2)}
                              </span>
                              <span className="market-volume">{item?.bs2}</span>
                            </div>
                            <div
                              className="market-odd-box back"
                              onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item.sid,
                                    runnerName: item.rname,
                                    handicap: 0,
                                    status: item?.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: item?.b1,
                                    Backprice: item?.b1,
                                    Backsize: item?.bs1,
                                    Layprice1: 0,
                                    Layprice: 0,
                                    Laysize: 0,
                                    min: 0,
                                    max: 0,
                                    mname: "Match Odds",
                                    betRunnerName: item?.rname,
                                  },
                                  "back",
                                  "Match Odds",
                                  {
                                    valueOf: item?.b1,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }
                            >
                              <span className="market-odd">
                                {" "}
                                {checkIfFloat(item?.b1)}
                              </span>
                              <span className="market-volume">{item?.bs1}</span>
                            </div>
                            <div
                              className="market-odd-box lay"
                              onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item?.sid,
                                    runnerName: item?.rname,
                                    handicap: 0,
                                    status: item.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: 0,
                                    Backprice: 0,
                                    Backsize: 0,
                                    Layprice1: item?.l1,
                                    Layprice: item?.l1,
                                    Laysize: item?.ls1,
                                    min: 0,
                                    max: 0,
                                    mname: "Match Odds",
                                    betRunnerName: item.rname,
                                  },
                                  "lay",
                                  "Match Odds",
                                  {
                                    valueOf: item?.l1,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }
                            >
                              <span className="market-odd">
                                {" "}
                                {checkIfFloat(item?.l1)}
                              </span>
                              <span className="market-volume">{item?.ls1}</span>
                            </div>
                            <div
                              className="market-odd-box lay1"
                              onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item?.sid,
                                    runnerName: item?.rname,
                                    handicap: 0,
                                    status: item.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: 0,
                                    Backprice: 0,
                                    Backsize: 0,
                                    Layprice1: item?.l2,
                                    Layprice: item?.l2,
                                    Laysize: item?.ls2,
                                    min: 0,
                                    max: 0,
                                    mname: "Match Odds",
                                    betRunnerName: item.rname,
                                  },
                                  "lay",
                                  "Match Odds",
                                  {
                                    valueOf: item?.l2,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.l2)}
                              </span>
                              <span className="market-volume">{item?.ls2}</span>
                            </div>
                            <div
                              className="market-odd-box lay2"
                              onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item?.sid,
                                    runnerName: item?.rname,
                                    handicap: 0,
                                    status: item.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: 0,
                                    Backprice: 0,
                                    Backsize: 0,
                                    Layprice1: item?.l3,
                                    Layprice: item?.l3,
                                    Laysize: item?.ls3,
                                    min: 0,
                                    max: 0,
                                    mname: "Match Odds",
                                    betRunnerName: item.rname,
                                  },
                                  "lay",
                                  "Match Odds",
                                  {
                                    valueOf: item?.l3,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }
                            >
                              <span className="market-odd">
                                {checkIfFloat(item?.l3)}
                              </span>
                              <span className="market-volume">{item?.ls3}</span>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              )}
              {socketData?.bookMakerOddsResponseDTO &&
                socketData?.bookMakerOddsResponseDTO?.length > 0 && (
                  <div className="game-market market-4 ">
                    <div className="market-title">
                      <span>Bookmaker</span>
                    </div>
                    <div className="market-header">
                      <div className="market-nation-detail">
                        <span className="market-nation-name">
                          Min:{" "}
                          {/* {amountFormatter(
                            eventExposureAndSettings?.minStack
                          )}
                          &nbsp; Max:{" "}
                          {amountFormatter(
                            eventExposureAndSettings?.bookmakerMaxStack
                          )} */}
                        </span>
                      </div>
                      <div className="market-odd-box no-border d-none d-md-block" />
                      <div className="market-odd-box no-border d-none d-md-block" />
                      <div className="market-odd-box back">
                        <b>Back</b>
                      </div>
                      <div className="market-odd-box lay">
                        <b>Lay</b>
                      </div>
                      <div className="market-odd-box" />
                      <div className="market-odd-box no-border" />
                    </div>
                    <div
                      className="market-body "
                      // data-title={
                      //   gameOdds?.bookMakerOddsResponseDTO[0]?.bm1?.marketStatus
                      // }
                    >
                      {socketData?.bookMakerOddsResponseDTO[0]?.bm1?.oddDatas
                        ?.sort((a, b) => a.rname.localeCompare(b.rname))
                        ?.map((item) => (
                          <>
                            {" "}
                            <div
                              className={`market-row ${
                                item?.status?.toUpperCase() != "ACTIVE"
                                  ? "suspended-row"
                                  : ""
                              }`}
                              data-title={item?.status}
                            >
                              <div className="market-nation-detail">
                                <span className="market-nation-name">
                                  {item?.runnerName}
                                </span>
                                <div className="market-nation-book">
                                  <div className="market-book">
                                    <span
                                      style={{
                                        color:
                                          bookStack?.filter(
                                            (ele) => ele.team == item?.rname
                                          )[0]?.amount < 0
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {
                                        bookStack?.filter(
                                          (ele) => ele.team == item?.rname
                                        )[0]?.amount
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="market-odd-box back2 " onClick={() =>
                                  handleClick(
                                    {
                                      marketId: item?.mid,
                                      evntid: eventTypeid,
                                      matchid: id,
                                      selectionId: item.sid,
                                      runnerName: item.rname,
                                      handicap: 0,
                                      status: item?.status,
                                      lastPriceTraded: 0,
                                      totalMatched: 0,
                                      Backprice1: item?.b3,
                                      Backprice: item?.b3,
                                      Backsize: item?.bs3,
                                      Layprice1: 0,
                                      Layprice: 0,
                                      Laysize: 0,
                                      min: 0,
                                      max: 0,
                                      mname: "Bookmaker Market",
                                      betRunnerName: item?.rname,
                                    },
                                    "back",
                                    "Bookmaker Market",
                                    {
                                      valueOf: item?.b3,
                                      selId: item?.sid,
                                      team: item?.rname,
                                    }
                                  )
                                }>
                                <span className="market-odd">
                                  {item?.b3 ? item?.b3 : 0}
                                </span>
                              </div>
                              <div className="market-odd-box  back1 "
                            onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item.sid,
                                  runnerName: item.rname,
                                  handicap: 0,
                                  status: item?.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: item?.b2,
                                  Backprice: item?.b2,
                                  Backsize: item?.bs2,
                                  Layprice1: 0,
                                  Layprice: 0,
                                  Laysize: 0,
                                  min: 0,
                                  max: 0,
                                  mname: "Bookmaker Market",
                                  betRunnerName: item?.rname,
                                },
                                "back",
                                "Bookmaker Market",
                                {
                                  valueOf: item?.b2,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }>
                                <span className="market-odd">
                                  {item?.b2 ? item?.b2 : 0}
                                </span>
                              </div>
                              <div className="market-odd-box back " 
                               onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item.sid,
                                    runnerName: item.rname,
                                    handicap: 0,
                                    status: item?.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: item?.b1,
                                    Backprice: item?.b1,
                                    Backsize: item?.bs1,
                                    Layprice1: 0,
                                    Layprice: 0,
                                    Laysize: 0,
                                    min: 0,
                                    max: 0,
                                    mname: "Bookmaker Market",
                                    betRunnerName: item?.rname,
                                  },
                                  "back",
                                  "Bookmaker Market",
                                  {
                                    valueOf: item?.b1,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }>
                                <span className="market-odd">
                                  {checkIfFloat(item?.b1)}
                                </span>
                                <span className="market-volume">
                                  {amountFormatter(item?.b1)}
                                </span>
                              </div>
                              <div className="market-odd-box lay " 
                             onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item?.sid,
                                  runnerName: item?.rname,
                                  handicap: 0,
                                  status: item.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: 0,
                                  Backprice: 0,
                                  Backsize: 0,
                                  Layprice1: item?.l1,
                                  Layprice: item?.l1,
                                  Laysize: item?.ls1,
                                  min: 0,
                                  max: 0,
                                  mname: "Bookmaker Market",
                                  betRunnerName: item.rname,
                                },
                                "lay",
                                "Bookmaker Market",
                                {
                                  valueOf: item?.l1,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }>
                                <span className="market-odd">
                                  {checkIfFloat(item?.l1)}
                                </span>
                                <span className="market-volume">
                                  {amountFormatter(item?.ls1)}
                                </span>
                              </div>
                              <div className="market-odd-box  lay1 "  
                             onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item?.sid,
                                  runnerName: item?.rname,
                                  handicap: 0,
                                  status: item.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: 0,
                                  Backprice: 0,
                                  Backsize: 0,
                                  Layprice1: item?.l2,
                                  Layprice: item?.l2,
                                  Laysize: item?.ls2,
                                  min: 0,
                                  max: 0,
                                  mname: "Bookmaker Market",
                                  betRunnerName: item.rname,
                                },
                                "lay",
                                "Bookmaker Market",
                                {
                                  valueOf: item?.l2,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            } >
                                <span className="market-odd">
                                  {item?.l2 ? item?.l2 : 0}
                                </span>
                              </div>
                              <div className="market-odd-box lay2 "
                               onClick={() =>
                                handleClick(
                                  {
                                    marketId: item?.mid,
                                    evntid: eventTypeid,
                                    matchid: id,
                                    selectionId: item?.sid,
                                    runnerName: item?.rname,
                                    handicap: 0,
                                    status: item.status,
                                    lastPriceTraded: 0,
                                    totalMatched: 0,
                                    Backprice1: 0,
                                    Backprice: 0,
                                    Backsize: 0,
                                    Layprice1: item?.l3,
                                    Layprice: item?.l3,
                                    Laysize: item?.ls3,
                                    min: 0,
                                    max: 0,
                                    mname: "Bookmaker Market",
                                    betRunnerName: item.rname,
                                  },
                                  "lay",
                                  "Bookmaker Market",
                                  {
                                    valueOf: item?.l3,
                                    selId: item?.sid,
                                    team: item?.rname,
                                  }
                                )
                              }>
                                <span className="market-odd">
                                  {item?.l3 ? item?.l3 : 0}
                                </span>
                              </div>
                            </div>
                            <div className="market-row">
                              <marquee className="market-remark">
                                {item?.remark}
                              </marquee>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                )}
              {socketData?.other_market_odds && (
                <div className="game-market market-4 ">
                  <div className="market-title">
                    <span>TIED MATCH</span>
                  </div>
                  <div className="market-header">
                    <div className="market-nation-detail">
                      <span className="market-nation-name">
                        Max:{" "}
                        {/* {amountFormatter(eventExposureAndSettings?.maxStack)} */}
                      </span>
                    </div>
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box no-border d-none d-md-block" />
                    <div className="market-odd-box back">
                      <b>Back</b>
                    </div>
                    <div className="market-odd-box lay">
                      <b>Lay</b>
                    </div>
                    <div className="market-odd-box" />
                    <div className="market-odd-box no-border" />
                  </div>
                  <div
                    className="market-body "
                    data-title={socketData?.other_market_odds[0]?.mstatus}
                  >
                    {socketData?.other_market_odds[0]?.oddDatas
                      ?.sort((a, b) => a?.rname?.localeCompare(b.rname))
                      ?.map((item) => (
                        <div
                          className={`market-row ${
                            item?.status?.toUpperCase() != "ACTIVE"
                              ? "suspended-row"
                              : ""
                          }`}
                          data-title={item?.status}
                        >
                          <div className="market-nation-detail">
                            <span className="market-nation-name">
                              {item?.rname}
                            </span>

                            <div className="market-nation-book">
                              <div className="market-book">
                                <span
                                // style={{
                                //   color:
                                //     eventExposureAndSettings?.plExposureResponseDTOs
                                //       ?.find(
                                //         (i) =>
                                //           i.marketIdExternal ===
                                //           gameOdds?.other_market_odds[0]
                                //             ?.marketId
                                //       )
                                //       ?.runnerPlExposureDTOS?.find(
                                //         (runner) =>
                                //           runner.selectionId ==
                                //           item.selectionId
                                //       )?.exposure < 0
                                //       ? "red"
                                //       : "green",
                                // }}
                                >
                                  {/* {
                                    eventExposureAndSettings?.plExposureResponseDTOs
                                      ?.find(
                                        (i) =>
                                          i.marketIdExternal ===
                                          gameOdds?.other_market_odds[0]
                                            ?.marketId
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (runner) =>
                                          runner.selectionId ==
                                          item.selectionId
                                      )?.exposure
                                  } */}
                                </span>
                              </div>
                              {/* {socketData?.other_market_odds[0]?.marketId ===
                                betPayload?.marketId && (
                                <span
                                  class={`market-live-book d-none d-xl-block ${
                                    betPayload?.selectionId ===
                                    item?.selectionId
                                      ? "text-success"
                                      : "text-danger"
                                  } `}
                                >
                                  {betPayload?.selectionId ===
                                  item?.selectionId
                                    ? betPayload?.profit
                                    : "-" + betPayload?.loss}
                                </span>
                              )} */}
                            </div>
                          </div>
                          <div className="market-odd-box   back2 "
                           onClick={() =>
                            handleClick(
                              {
                                marketId: item?.mid,
                                evntid: eventTypeid,
                                matchid: id,
                                selectionId: item.sid,
                                runnerName: item.rname,
                                handicap: 0,
                                status: item?.status,
                                lastPriceTraded: 0,
                                totalMatched: 0,
                                Backprice1: item?.b3,
                                Backprice: item?.b3,
                                Backsize: item?.bs3,
                                Layprice1: 0,
                                Layprice: 0,
                                Laysize: 0,
                                min: 0,
                                max: 0,
                                mname: "Tied Match",
                                betRunnerName: item?.rname,
                              },
                              "back",
                              "Tied Match",
                              {
                                valueOf: item?.b3,
                                selId: item?.sid,
                                team: item?.rname,
                              }
                            )
                          }>
                            <span className="market-odd">
                              {checkIfFloat(item?.b3)}
                            </span>
                            <span className="market-volume">{item?.bs3}</span>
                          </div>
                          <div
                            // onClick={() =>
                            //   handleMatchOddClick(
                            //     item?.back2,
                            //     item?.backSize2,
                            //     item
                            //   )
                            // }
                            className="market-odd-box  back1  "
                            onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item.sid,
                                  runnerName: item.rname,
                                  handicap: 0,
                                  status: item?.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: item?.b2,
                                  Backprice: item?.b2,
                                  Backsize: item?.bs2,
                                  Layprice1: 0,
                                  Layprice: 0,
                                  Laysize: 0,
                                  min: 0,
                                  max: 0,
                                  mname: "Tied Match",
                                  betRunnerName: item?.rname,
                                },
                                "back",
                                "Tied Match",
                                {
                                  valueOf: item?.b2,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.b2)}
                            </span>
                            <span className="market-volume">{item?.bs2}</span>
                          </div>
                          <div
                            // onClick={() =>
                            //   handleMatchOddClick(
                            //     item?.back1,
                            //     item?.backSize1,
                            //     item
                            //   )
                            // }
                            onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item.sid,
                                  runnerName: item.rname,
                                  handicap: 0,
                                  status: item?.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: item?.b1,
                                  Backprice: item?.b1,
                                  Backsize: item?.bs1,
                                  Layprice1: 0,
                                  Layprice: 0,
                                  Laysize: 0,
                                  min: 0,
                                  max: 0,
                                  mname: "Tied Match",
                                  betRunnerName: item?.rname,
                                },
                                "back",
                                "Tied Match",
                                {
                                  valueOf: item?.b1,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }
                            className="market-odd-box back   "
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.b1)}
                            </span>
                            <span className="market-volume">{item?.bs1}</span>
                          </div>
                          <div
                            // onClick={() =>
                            //   handleMatchOddClick(
                            //     item?.lay1,
                            //     item?.laySize1,
                            //     item,
                            //     false
                            //   )
                            // }
                            className="market-odd-box lay "
                            onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item?.sid,
                                  runnerName: item?.rname,
                                  handicap: 0,
                                  status: item.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: 0,
                                  Backprice: 0,
                                  Backsize: 0,
                                  Layprice1: item?.l1,
                                  Layprice: item?.l1,
                                  Laysize: item?.ls1,
                                  min: 0,
                                  max: 0,
                                  mname: "Tied Match",
                                  betRunnerName: item.rname,
                                },
                                "lay",
                                "Tied Match",
                                {
                                  valueOf: item?.l1,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.l1)}
                            </span>
                            <span className="market-volume">{item?.ls1}</span>
                          </div>
                          <div
                            // onClick={() =>
                            //   handleMatchOddClick(
                            //     item?.lay2,
                            //     item?.laySize2,
                            //     item,
                            //     false
                            //   )
                            // }
                            className="market-odd-box  lay1  "
                            onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item?.sid,
                                  runnerName: item?.rname,
                                  handicap: 0,
                                  status: item.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: 0,
                                  Backprice: 0,
                                  Backsize: 0,
                                  Layprice1: item?.l2,
                                  Layprice: item?.l2,
                                  Laysize: item?.ls1,
                                  min: 0,
                                  max: 0,
                                  mname: "Tied Match",
                                  betRunnerName: item.rname,
                                },
                                "lay",
                                "Tied Match",
                                {
                                  valueOf: item?.l2,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.l2)}
                            </span>
                            <span className="market-volume">{item?.ls2}</span>
                          </div>
                          <div
                            // onClick={() =>
                            //   handleMatchOddClick(
                            //     item?.lay3,
                            //     item?.laySize3,
                            //     item,
                            //     false
                            //   )
                            // }
                            className="market-odd-box   lay2 "
                            onClick={() =>
                              handleClick(
                                {
                                  marketId: item?.mid,
                                  evntid: eventTypeid,
                                  matchid: id,
                                  selectionId: item?.sid,
                                  runnerName: item?.rname,
                                  handicap: 0,
                                  status: item.status,
                                  lastPriceTraded: 0,
                                  totalMatched: 0,
                                  Backprice1: 0,
                                  Backprice: 0,
                                  Backsize: 0,
                                  Layprice1: item?.l3,
                                  Layprice: item?.l3,
                                  Laysize: item?.ls3,
                                  min: 0,
                                  max: 0,
                                  mname: "Tied Match",
                                  betRunnerName: item.rname,
                                },
                                "lay",
                                "Tied Match",
                                {
                                  valueOf: item?.l3,
                                  selId: item?.sid,
                                  team: item?.rname,
                                }
                              )
                            }
                          >
                            <span className="market-odd">
                              {checkIfFloat(item?.l3)}
                            </span>
                            <span className="market-volume">{item?.ls3}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {socketData?.fancyOdds &&
                socketData?.fancyOdds.length > 0 &&
                socketData?.fancyOdds
                  ?.sort((a, b) => {
                    if (a.gtype.toLowerCase() == "normal") {
                      return -1;
                    }
                    return 1;
                  })
                  ?.map((fancy) => (
                    <div className="game-market market-6">
                      <div className="market-title">
                        <span>{fancy?.gtype}</span>
                      </div>
                      <div className="row row10">
                        <div className="col-md-6">
                          <div className="market-header">
                            <div className="market-nation-detail" />
                            <div className="market-odd-box lay">
                              <b>No</b>
                            </div>
                            <div className="market-odd-box back">
                              <b>Yes</b>
                            </div>
                            <div className="fancy-min-max-box" />
                          </div>
                        </div>
                        <div className="col-md-6 d-none d-xl-block">
                          <div className="market-header">
                            <div className="market-nation-detail" />
                            <div className="market-odd-box lay">
                              <b>No</b>
                            </div>
                            <div className="market-odd-box back">
                              <b>Yes</b>
                            </div>
                            <div className="fancy-min-max-box" />
                          </div>
                        </div>
                      </div>
                      <div className="market-body " data-title={fancy?.status}>
                        <div className="row row10">
                          {fancy?.oddDatas
                            ?.sort((a, b) => {
                              const bandA = a.rname;
                              const bandB = b.rname;

                              let comparison = 0;
                              if (bandA > bandB) {
                                comparison = 1;
                              } else if (bandA < bandB) {
                                comparison = -1;
                              }
                              return comparison;
                            })
                            ?.map((item, index) => (
                              <div className="col-md-6">
                                <div
                                  // className={`fancy-market ${
                                  //   item?.status?.toLowerCase() ==
                                  //     "suspended" ||
                                  //   item?.status?.toLowerCase() ==
                                  //     "ball running" ||
                                  //   item?.status?.toLowerCase() == "inactive"
                                  //     ? "suspended-row"
                                  //     : item?.status?.toLowerCase()
                                  // }`}
                                  data-title={item?.status?.toLowerCase()}
                                >
                                  <div className="market-row">
                                    <div className="market-nation-detail">
                                      <span
                                        // onClick={() => {
                                        //   setShowLadderForMarket(
                                        //     item.marketId
                                        //   );
                                        // }}
                                        className="market-nation-name"
                                      >
                                        {item?.rname}
                                      </span>
                                      {sessionTeams.map((ele, index) => (
                                        <span
                                          key={index}
                                          style={{
                                            color:
                                              ele?.amount >= 0 ? "red" : "green",
                                          }}
                                        >
                                          {item?.rname == ele?.team &&
                                            ele?.amount}
                                        </span>
                                      ))}
                                    </div>
                                    <div
                                      // onClick={() =>
                                      //   handleFancyOddClick(
                                      //     item?.lay1,
                                      //     item?.laySize1,
                                      //     item,
                                      //     fancy?.gameType,
                                      //     false
                                      //   )
                                      // }
                                      onClick={() =>
                                        handleFancyClick(
                                          {
                                            type: "Session Market",
                                            team: item.rname,
                                            back: "0",
                                            lay: item.l1,
                                            BackSize1: "",
                                            LaySize1: item.ls1,
                                            min: "",
                                            max: "",
                                            matchid: id,
                                            ballsess: "0",
                                            sid: item.sid,
                                            srno: item.srno,
                                            WinPerc: "0",
                                            LayPrice2: item.ls1,
                                            LaySize2: item.ls1,
                                            BackPrice2: item.b1,
                                            BackSize2: item.bs1,
                                            GameStatus: item.status,
                                            gtype: "Session Market",
                                            rem: "",
                                            eventID: eventTypeid,
                                          },
                                          "lay",
                                          "Session Market",
                                          item.rname,
                                          {
                                            valueOf: item.l1,
                                            team: item.rname,
                                            sid: item.sid,
                                            status: item.status,
                                          }
                                        )
                                      }
                                      className="market-odd-box lay "
                                    >
                                      <span className="market-odd">
                                        {checkIfFloat(item?.l1)}
                                      </span>
                                      <span className="market-volume">
                                        {amountFormatter(item?.ls1)}
                                      </span>
                                    </div>
                                    <div
                                      // onClick={() =>
                                      //   handleFancyOddClick(
                                      //     item?.back1,
                                      //     item?.backSize1,
                                      //     item,
                                      //     fancy?.gameType,
                                      //     true
                                      //   )
                                      // }
                                      onClick={() =>
                                        handleFancyClick(
                                          {
                                            type: "Session Market",
                                            team: item.rname,
                                            back: item.b1,
                                            BackSize1: item.bs1,
                                            lay: "0",
                                            LaySize1: "",
                                            min: "",
                                            max: "",
                                            lay: "0",
                                            matchid: id,
                                            ballsess: "0",
                                            sid: item.sid,
                                            srno: "0",
                                            WinPerc: "0",
                                            LayPrice2: item.ls1,
                                            LaySize2: item.ls1,
                                            BackPrice2: item.bs1,
                                            BackSize2: item.bs1,
                                            GameStatus: item.status,
                                            gtype: "Session Market",
                                            rem: "",
                                            eventID: eventTypeid,
                                          },
                                          "back",
                                          "Session Market",
                                          item.rname,
                                          {
                                            valueOf: item.b1,
                                            team: item.rname,
                                            sid: item.sid,
                                            status: item.status,
                                          }
                                        )
                                      }
                                      className="market-odd-box back "
                                    >
                                      <span className="market-odd">
                                        {checkIfFloat(item?.b1)}
                                      </span>
                                      <span className="market-volume">
                                        {amountFormatter(item?.bs1)}
                                      </span>
                                    </div>
                                    <div className="fancy-min-max-box">
                                      <div className="fancy-min-max">
                                        <span className="w-100 d-block">
                                          Min: {amountFormatter(item?.min)}
                                        </span>
                                        <span className="w-100 d-block">
                                          Max: {amountFormatter(item?.max)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
            </>
          )}
        </div>
      </div>
      <Modal
      chID={chID}
      modalOpen={modal}
        setModal={setModal}
        setbetType={setbetType}
        setmarketType={setmarketType}
        setbetDetails={setbetDetails}
        betDetails={betDetails}
        betType={betType}
        marketType={marketType}
        // countryName={countryName}
        realtimeData={realtimeData}
        setRealtimeData={setRealtimeData}
        gameEvent={eventTypeid}
      />
      {/* <Modal
      chID={chID}
      modalOpen={modal}
        setModal={setModal}
        setbetType={setbetType}
        setmarketType={setmarketType}
        setbetDetails={setbetDetails}
        betDetails={betDetails}
        betType={betType}
        marketType={marketType}
        // countryName={countryName}
        realtimeData={realtimeData}
        setRealtimeData={setRealtimeData}
        gameEvent={eventTypeid}
      /> */}


      {/* <GameSidebar
        betPayload={betPayload}
        id={id}
        // handleAmountChange={handleAmountChange}
        handleBetPlace={handleBetPlace}
        userBets={userBets}
        betHandler={handleBetPlace}
        setBetPayload={setBetPayload}
        game="soccer"
        gameEvent={eventTypeid}
      /> */}
      {/* <LadderModal
        showLadderForMarket={showLadderForMarket}
        data={getLadderForMarket(showLadderForMarket)}
        onClose={() => setShowLadderForMarket(null)}
        setShowModal={() => setShowLadderForMarket(null)}
      /> */}
    </div>
  );
}
