// authAPI.js
import axios from "axios";
import { API } from "../../service/API";

const authAPI = axios.create();

export const login = (userData) => API.post("/login", userData);

export const AdminAutologin = (userData) =>
  API.post("/AdminUser_login", userData);

export default authAPI;
