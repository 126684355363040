import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";
import CasinoPlacedBet from "./components/CasinoPlaceBet";

export default function Card32B() {
  const id = 15;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [betIndex, setBetIndex] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("32cardB", parsedData[0]);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);
  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: reqRes?.data?.data?.t1[0]?.mid,
      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const [selectedTab, setSelectedTab] = useState("game");

  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-4 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-4"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-4 cards32a cards32b"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-4"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-4"
            >
              <li
                onClick={() => setSelectedTab("game")}
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={` ng-tns-c1798067373-4 
                    ${selectedTab === "game" && "active"}
                    nav-link`}
                >
                  Game
                </div>
              </li>
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-4"
                onClick={() => setSelectedTab("placedBet")}
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-4 
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                >
                  {" "}
                  Placed Bet ({history?.length})
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-4"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-4"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-4"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-4"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-4"
            >
              {" "}
              32 Cards - B
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-4"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-4"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-4"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </small>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          {selectedTab === "game" ? (
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-4 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-video ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="video-box-container ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-video-box ng-tns-c1798067373-4 ng-star-inserted"
                  >
                    {/* <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.thirtyTwoCardB}
                  /> */}
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""
                    />
                  </div>
                  {/**/}
                </div>
                <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />

                {/**/}
              </div>
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-detail ng-tns-c1798067373-4"
              >
                {/**/}

                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table ng-tns-c1798067373-4 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-box ng-tns-c1798067373-4"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-header ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-none d-block ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min-max-dt20 ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-block d-none ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min_max_collapse ng-tns-c1798067373-4"
                            >
                              <a
                                _ngcontent-ng-c1798067373=""
                                data-bs-toggle="collapse"
                                href="#collapserange1"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapserange1"
                                className="minmax-btn ng-tns-c1798067373-4"
                              >
                                <i
                                  _ngcontent-ng-c1798067373=""
                                  className="fas fa-info-circle ng-tns-c1798067373-4"
                                />
                              </a>
                              <div
                                _ngcontent-ng-c1798067373=""
                                id="collapserange1"
                                className="range-collpase collapse ng-tns-c1798067373-4"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-0 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Min:
                                  </b>{" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-1 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Max:
                                  </b>
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    {" "}
                                    500000 {/**/}
                                    {/**/}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-4"
                        >
                          Back
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-4"
                        >
                          Lay
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-body ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Player 8{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 1 ? (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[0]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0].b1,
                                      reqRes?.data?.data?.t2[0].nation,
                                      1
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[0].b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">  {reqRes?.data?.data?.t2[0].bs1} </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0].l1,
                                      reqRes?.data?.data?.t2[0].nation,
                                      5
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[0].l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">   {reqRes?.data?.data?.t2[0].ls1} </p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Player 9{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 2 ? (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[1]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1].b1,
                                      reqRes?.data?.data?.t2[1].nation,
                                      2
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[1].b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">   {reqRes?.data?.data?.t2[1].bs1} </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1].l1,
                                      reqRes?.data?.data?.t2[1].nation,
                                      6
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[1].l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">  {reqRes?.data?.data?.t2[1].ls1}</p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Player 10{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 3 ? (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2].b1,
                                      reqRes?.data?.data?.t2[2].nation,
                                      3
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[2].b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">   {reqRes?.data?.data?.t2[2].bs1} </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2].l1,
                                      reqRes?.data?.data?.t2[2].nation,
                                      7
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[2].l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">   {reqRes?.data?.data?.t2[2].ls1}</p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Player 11{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 4 ? (
                                    <span
                                      style={{
                                        color:
                                          ele.amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[3]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[3]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3].b1,
                                      reqRes?.data?.data?.t2[3].nation,
                                      4
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[3].b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">   {reqRes?.data?.data?.t2[3].bs1} </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3].l1,
                                      reqRes?.data?.data?.t2[3].nation,
                                      8
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[3].l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5">   {reqRes?.data?.data?.t2[3].ls1} </p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-header ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-none d-block ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min-max-dt20 ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-block d-none ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min_max_collapse ng-tns-c1798067373-4"
                            >
                              <a
                                _ngcontent-ng-c1798067373=""
                                data-bs-toggle="collapse"
                                href="#collapserange1"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapserange1"
                                className="minmax-btn ng-tns-c1798067373-4"
                              >
                                <i
                                  _ngcontent-ng-c1798067373=""
                                  className="fas fa-info-circle ng-tns-c1798067373-4"
                                />
                              </a>
                              <div
                                _ngcontent-ng-c1798067373=""
                                id="collapserange1"
                                className="range-collpase collapse ng-tns-c1798067373-4"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-0 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Min:
                                  </b>{" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-1 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Max:
                                  </b>
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    {" "}
                                    500000 {/**/}
                                    {/**/}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-4"
                        >
                          Odd
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-4"
                        >
                          Even
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-body ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              Player 8
                            </div>
                          </div>
                          <div className="ng-tns-c1798067373-4 casino_odds_row ">
                            {reqRes?.data?.data?.t2[4]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[4]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[4].b1,
                                      reqRes?.data?.data?.t2[4].nation,
                                      9
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[4].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 5 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 5 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                            {reqRes?.data?.data?.t2[5]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[5]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[5].b1,
                                      reqRes?.data?.data?.t2[5].nation,
                                      13
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[5].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 9 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 9 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              Player 9
                            </div>
                          </div>
                          <div className="ng-tns-c1798067373-4 casino_odds_row ">
                            {reqRes?.data?.data?.t2[6]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[6]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[6].b1,
                                      reqRes?.data?.data?.t2[6].nation,
                                      10
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[6].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 6 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 6 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                            {reqRes?.data?.data?.t2[7]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[7]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[7].b1,
                                      reqRes?.data?.data?.t2[7].nation,
                                      14
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[7].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 10 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 10 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              Player 10
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino_odds_row ng-tns-c1798067373-4"
                          >
                            {reqRes?.data?.data?.t2[8]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[8]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[8]?.b1,
                                      reqRes?.data?.data?.t2[8]?.nat,
                                      11
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[8].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 7 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 7 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                            {reqRes?.data?.data?.t2[9]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[9]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[9]?.b1,
                                      reqRes?.data?.data?.t2[9]?.nat,
                                      15
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[9].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 11 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 11 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              Player 11
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino_odds_row ng-tns-c1798067373-4"
                          >
                            {reqRes?.data?.data?.t2[9]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[9]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[9]?.b1,
                                      reqRes?.data?.data?.t2[9]?.nat,
                                      12
                                    );
                                    setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[9].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 8 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 8 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                            {reqRes?.data?.data?.t2[10]?.gstatus ===
                              "SUSPENDED" ||
                            reqRes?.data?.data?.t2[10]?.gstatus === "CLOSED" ? (
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-4"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[10]?.b1,
                                      reqRes?.data?.data?.t2[10]?.nat,
                                      16
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[10]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-4">
                                    {reqRes?.data?.data?.t2[10].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-4">
                                    {thirtyTwoCardStack.map(
                                      (ele) =>
                                        ele.number === 12 && (
                                          <span
                                            style={{
                                              color:
                                                ele.amount >= 0
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {ele.number == 12 && ele.amount}
                                          </span>
                                        )
                                    )}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-header ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-none d-block ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min-max-dt20 ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-block d-none ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min_max_collapse ng-tns-c1798067373-4"
                            >
                              <a
                                _ngcontent-ng-c1798067373=""
                                data-bs-toggle="collapse"
                                href="#collapserange1"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapserange1"
                                className="minmax-btn ng-tns-c1798067373-4"
                              >
                                <i
                                  _ngcontent-ng-c1798067373=""
                                  className="fas fa-info-circle ng-tns-c1798067373-4"
                                />
                              </a>
                              <div
                                _ngcontent-ng-c1798067373=""
                                id="collapserange1"
                                className="range-collpase collapse ng-tns-c1798067373-4"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-0 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Min:
                                  </b>{" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-1 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Max:
                                  </b>
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    {" "}
                                    500000 {/**/}
                                    {/**/}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-4"
                        >
                          Back
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box lay ng-tns-c1798067373-4"
                        >
                          Lay
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-body ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Any Three Card Black{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {thirtyTwoCardStack?.map(
                                  (ele) =>
                                    ele.number === 13 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele?.number == 13 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[12]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[12]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[12]?.b1,
                                      reqRes?.data?.data?.t2[12]?.nation,
                                      17
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[12]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[12]?.b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5"> 829649 </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[12]?.l1,
                                      reqRes?.data?.data?.t2[12]?.nation,
                                      18
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[12]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[12]?.l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5"> 145973 </p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Any Three Card Red{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 14 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 14 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[13]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[13]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[13]?.b1,
                                      reqRes?.data?.data?.t2[13]?.nation,
                                      19
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[13]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[13]?.b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5"> 829649 </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[13]?.l1,
                                      reqRes?.data?.data?.t2[13]?.nation,
                                      20
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[13]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[13]?.l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5"> 145973 </p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              Two Black Two Red{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 15 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 15 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[26]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[26]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[26]?.b1,
                                      reqRes?.data?.data?.t2[26]?.nation,
                                      21
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[26]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[26]?.b1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5"> 829649 </p> */}
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[26]?.l1,
                                      reqRes?.data?.data?.t2[26]?.nation,
                                      22
                                    );
                                    setBetIndex(
                                      reqRes?.data?.data?.t2[26]?.sid
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[26]?.l1}
                                  </span>
                                  {/* <p className="ng-tns-c1798067373-5"> 145973 </p> */}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-left-box cards32total ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-header ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-4"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-none d-block ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min-max-dt20 ng-tns-c1798067373-4"
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Min:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                              </span>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                <b
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  Max:{" "}
                                </b>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-4"
                                >
                                  {" "}
                                  500000 {/**/}
                                  {/**/}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="d-lg-block d-none ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="min_max_collapse ng-tns-c1798067373-4"
                            >
                              <a
                                _ngcontent-ng-c1798067373=""
                                data-bs-toggle="collapse"
                                href="#collapserange1"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapserange1"
                                className="minmax-btn ng-tns-c1798067373-4"
                              >
                                <i
                                  _ngcontent-ng-c1798067373=""
                                  className="fas fa-info-circle ng-tns-c1798067373-4"
                                />
                              </a>
                              <div
                                _ngcontent-ng-c1798067373=""
                                id="collapserange1"
                                className="range-collpase collapse ng-tns-c1798067373-4"
                              >
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-0 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Min:
                                  </b>{" "}
                                  100 {/**/}
                                  {/**/}
                                </span>
                                <span
                                  _ngcontent-ng-c1798067373=""
                                  className="min_max_span ms-1 ng-tns-c1798067373-4"
                                >
                                  <b
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    Max:
                                  </b>
                                  <span
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-4"
                                  >
                                    {" "}
                                    500000 {/**/}
                                    {/**/}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-body ng-tns-c1798067373-4"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              8 &amp; 9 Total{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 16 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[24]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[24]?.gstatus === "CLOSED" ? (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 casino_odds_row"
                            >
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 casino_odds_row"
                            >
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-4"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[24]?.b1,
                                    reqRes?.data?.data?.t2[24]?.nat,
                                    23
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[24]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  {reqRes?.data?.data?.t2[24].b1}
                                </span>
                                <p className="ng-tns-c1798067373-4">100</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-table-row ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-detail ng-tns-c1798067373-4"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-nation-name ng-tns-c1798067373-4"
                            >
                              {" "}
                              10 &amp; 11 Total{" "}
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4 text-success"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 16 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[25]?.gstatus ===
                            "SUSPENDED" ||
                          reqRes?.data?.data?.t2[25]?.gstatus === "CLOSED" ? (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 casino_odds_row"
                            >
                              <div className="casino-odds-box back ng-tns-c1798067373-4 suspended-box">
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-4 casino_odds_row"
                            >
                              <div
                                className="casino-odds-box back ng-tns-c1798067373-4"
                                onClick={() => {
                                  handleModal(
                                    reqRes?.data?.data?.t2[25]?.b1,
                                    reqRes?.data?.data?.t2[25]?.nat,
                                    24
                                  );
                                  setBetIndex(reqRes?.data?.data?.t2[25]?.sid);
                                }}
                              >
                                <span className="casino-odds ng-tns-c1798067373-4">
                                  {reqRes?.data?.data?.t2[25].b1}
                                </span>
                                <p className="ng-tns-c1798067373-4">100</p>
                              </div>
                            </div>
                          )}
                        </div>
                        {/**/}
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-full-box mt-3 card32numbers ng-tns-c1798067373-4"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="d-flex ng-tns-c1798067373-4"
                      >
                        <h4
                          _ngcontent-ng-c1798067373=""
                          className="w-100 text-center mb-2 ng-tns-c1798067373-4"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4"
                          >
                            9.5
                          </b>
                        </h4>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse mt-1 me-1 ng-tns-c1798067373-4"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            href="#collapserange5"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapserange4"
                            className="minmax-btn ng-tns-c1798067373-4"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-4"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            id="collapserange5"
                            className="range-collpase collapse ng-tns-c1798067373-4"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-4"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {" "}
                                500000 {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-4 card32numbers-container"
                      >
                        {reqRes?.data?.data?.t2[14]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[14]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[14]?.b1,
                                  reqRes?.data?.data?.t2[14]?.nat,
                                  25
                                );
                                setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                1
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 18 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 18 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {reqRes?.data?.data?.t2[15]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[15]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[15]?.b1,
                                  reqRes?.data?.data?.t2[15]?.nat,
                                  26
                                );
                                setBetIndex(reqRes?.data?.data?.t2[15]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                2
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 19 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 19 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}

                        {reqRes?.data?.data?.t2[16]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[16]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[16]?.b1,
                                  reqRes?.data?.data?.t2[16]?.nat,
                                  27
                                );
                                setBetIndex(reqRes?.data?.data?.t2[16]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                3
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 20 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 20 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {reqRes?.data?.data?.t2[17]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[17]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[17]?.b1,
                                  reqRes?.data?.data?.t2[17]?.nat,
                                  28
                                );
                                setBetIndex(reqRes?.data?.data?.t2[17]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                4
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 21 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 21 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {reqRes?.data?.data?.t2[18]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[18]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[18]?.b1,
                                  reqRes?.data?.data?.t2[18]?.nat,
                                  29
                                );
                                setBetIndex(reqRes?.data?.data?.t2[18]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                5
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 22 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 22 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {reqRes?.data?.data?.t2[19]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[19]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[19]?.b1,
                                  reqRes?.data?.data?.t2[19]?.nat,
                                  30
                                );
                                setBetIndex(reqRes?.data?.data?.t2[19]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                6
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 23 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 23 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {reqRes?.data?.data?.t2[20]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[20]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[20]?.b1,
                                  reqRes?.data?.data?.t2[20]?.nat,
                                  31
                                );
                                setBetIndex(reqRes?.data?.data?.t2[20]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                7
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 24 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 24 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {reqRes?.data?.data?.t2[21]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[21]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[21]?.b1,
                                  reqRes?.data?.data?.t2[21]?.nat,
                                  32
                                );
                                setBetIndex(reqRes?.data?.data?.t2[21]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                8
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 25 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 25 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {reqRes?.data?.data?.t2[22]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[22]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[22]?.b1,
                                  reqRes?.data?.data?.t2[22]?.nat,
                                  33
                                );
                                setBetIndex(reqRes?.data?.data?.t2[22]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                9
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 26 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 26 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {reqRes?.data?.data?.t2[23]?.gstatus === "SUSPENDED" ||
                        reqRes?.data?.data?.t2[23]?.gstatus === "CLOSED" ? (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted suspended-box"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds ng-tns-c1798067373-4"
                            >
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="casino-odds-box back ng-tns-c1798067373-4 ng-star-inserted"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[23]?.b1,
                                  reqRes?.data?.data?.t2[23]?.nat,
                                  34
                                );
                                setBetIndex(reqRes?.data?.data?.t2[23]?.sid);
                              }}
                            >
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="casino-odds ng-tns-c1798067373-4"
                              >
                                0
                              </span>
                              <p
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-4"
                              >
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 27 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 27 && ele.amount}
                                      </span>
                                    )
                                )}
                              </p>
                            </div>
                          </>
                        )}
                        {/**/}
                        {/**/}
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="remark text-right pr-2 w-100 ng-tns-c1798067373-4"
                  />
                </div>

                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-last-result-title ng-tns-c1798067373-4"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Last Result
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-4"
                      >
                        View All
                      </a>
                    </span>
                  </div>
                  <div className="casino-last-results ng-tns-c1798067373-5 ng-star-inserted">
                    {results?.data?.result?.map((result, index) => {
                      const buttonValue = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      return (
                        <span
                          className="ng-tns-c1798067373-5 result result-b ng-star-inserted"
                          key={index}
                        >
                          <span className="ng-tns-c1798067373-5">
                            {" "}
                            {buttonValue && (
                              <>{buttonValue?.nation?.split(" ")[1]}</>
                            )}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          ) : (
            <CasinoPlacedBet bets={history} />
          )}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-4 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-4"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-4"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
