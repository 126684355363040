import { useSelector } from "react-redux";
import {
  casinoPL,
  cricketBookMakerPL,
  cricketFancyPL,
} from "../../utils/profitLoss";
import {store} from "../../redux/store";

export const getOddForEntity = (
  entity,
  odds,
  isBack = true,
  giveSize = false
) => {
  let data = odds?.data?.response?.oddDetailsDTOS?.find(
    (odd) => odd.runnerName?.toLowerCase() === entity?.toLowerCase()
  );
  if (data) {
    if (giveSize) {
      return isBack ? data.backSize1 : data.laySize1;
    }
    return isBack ? data.back1 : data.lay1;
  }
};

export const CARD_ARRAY = [
  "A",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
];

export const setBet = (runnerName, casinoData, dispatch, type = "back") => {
  const selectedEventForBet_ = store.getState().gameData.selectedEventForBet;
  let matchData = casinoData?.data?.response;
  let data = matchData?.oddDetailsDTOS?.find(
    (odd) => odd.runnerName?.toLowerCase() === runnerName?.toLowerCase()
  );

  if (!matchData) return;
  var game = "casino";
  let amount = selectedEventForBet_?.amount
    ? selectedEventForBet_?.amount
    : 100;
  let selectedEventForBet = {
    marketId: matchData?.marketId,
    type: type?.startsWith("lay") ? "lay" : "back",
    selectedOdd: data?.back1,
    selectedId: data.selectionId,
    profit: getProfit(amount, game, {
      selectedOdd: data[type + "1"],
      type: type,
      amount: amount,
    }).profit,
    loss: getProfit(amount, game, {
      selectedOdd: data[type + "1"],
      type: type,
      amount: amount,
    }).loss,
    amount: amount,
    runnerName: data.runnerName,
    isYesAndNo: false,
  };
  if (selectedEventForBet?.selectedOdd == 0) {
    return;
  }
  dispatch({
    type: "gameData/setSelectedEventForBet",
    payload: selectedEventForBet,
  });
};
export const getProfit = (selectedAmount, game, selectedEventForBet) => {
  let profit;
  let loss;
  let selectedAmount_ = selectedEventForBet.amount;
  if (game == "cricket" && selectedEventForBet.isBookmaker) {
    profit = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketBookMakerPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "casino" || game == "tennis" || game == "soccer") {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else if (game == "cricket" && selectedEventForBet.isFancy) {
    profit = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = cricketFancyPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  } else {
    profit = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).profit;
    loss = casinoPL(
      selectedEventForBet.selectedOdd,
      selectedAmount_,
      selectedEventForBet.type
    ).loss;
  }
  return {
    profit: parseFloat(profit).toFixed(2),
    loss: parseFloat(loss).toFixed(2),
  };
};



export const CASINO_VIDEO = {
  thirtyTwoCardA: "3055",
  dragonTiger2020: "3035",
  dragonTiger1Day: "3057",
  bollyWoodTable: "3041",
  amarAkbarAnthony: "3056",
  instantWorli: "3040",
  teenPattiT20: "3030",
  teenPatti1Day: "3031",
  teenPattiTest: "3048",
  poker2020: "3052",
  poker1Day: "3051",
  anderBahr1: "3053",
  lucky7A: "3058",
  thirtyTwoCardB: "3034",
  race2020: "3036",
  fiveFiveCricket: "3042",
  anderBahr2: "3043",
  lucky7B: "3032",
  openTeenPatti: "3049",
  dragonTiger22020: "3059",
  twentyTwentyCricket: "3045",
  superOver: "3060",
  baccarat1: "3044",
  baccarat2: "3033",
  casinoWar: "3038",
  twentyTwentyDTL: "30470",
  sixPlayerPoker: "30500",
};
