import React from "react";

export default function TermsAndCondition() {
  return (
    <div id="root">
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          inset: 16,
          pointerEvents: "none",
        }}
      />
      <div className="wrapper">
        <div className="about-us-container">
          <div className="container">
            <div className="text-center logo">
              <img src="https://sitethemedata.com/sitethemes/jsk2468.com/front/logo.png" />
            </div>
            <div>
              <h2 className="page-title">Terms and Conditions</h2>
              <h4>1. About</h4>
              <h5>The site is operated by it's own group of companies.</h5>
              <h4>2. The Basics</h4>
              <h5>
                The Rules are a binding agreement between Us and You and by
                clicking that You accept the Rules when creating Your Account
                You agree that You have read and accepted the Rules. If You do
                not agree with the Rules, You should not register or continue
                to use the Website. Everything changes and We may change these
                Rules at any time. Where We do so We will contact You and
                inform You in advance of the new Rules being implemented.
              </h5>
              <h4>3. Definitions</h4>
              <h5>
                Being transparent is key. Here are some explanations of some
                terms used throughout the Rules. "Bonus" shall cover all
                promotional offers that gift a tangible reward, including but
                not limited to: welcome offers, reload bonuses, deposit
                bonuses, free spins, free bets, and safe bets. "Games" are
                Casino, Live Casino, Sportsbook and any other games on Our
                Site (including mobile or any other platform) and ’Game’ can
                mean any one of them. We can remove or add Games to the Site
                at any time. "Rules" are these terms, conditions and
                regulations which You agree to when using the Site. "Site" and
                "Website" and including, if applicable, any mobile and mobile
                application version thereof or any other subdomains of
                website'. "We", "Us", "Our" The Site is run by -, a company
                registered in -, "You" is you using the Site. "Your" has its
                usual meaning in relation to you. "Your Account" is your
                player account on the Site. "Totalisator" (also "Tote") refers
                to the betting pool usually operated by the race organiser,
                which forms the basis of the odds calculation (also known as
                pari-mutuel betting).
              </h5>
              <h4>4. Who Can Play</h4>
              <h5>To play the games on the site You must be:</h5>
              <ul>
                <li>
                  -&gt; over 18 years of age or above the legal age for
                  gambling in the country You are playing from;
                </li>
                <li>
                  -&gt; an actual person. You cannot be a company or other
                  legal entity;
                </li>
                <li>
                  -&gt; playing the Games for yourself and in a
                  non-professional capacity;
                </li>
                <li>-&gt; legally allowed to play Games on the Site;</li>
                <li>
                  -&gt; not resident in: Afghanistan, Algeria, Angola,
                  Australia, Bahrain, Belgium, China, Cuba, Curacao, Czech
                  Republic, Denmark, Eritrea, Estonia, Ethiopia, France,
                  Georgia, Greece, Guadeloupe, Guyane (French Guiana), Hong
                  Kong, Hungary, Indonesia, Iran, Iraq, Italy, Jordan, Kuwait,
                  Latvia, Libya, Lithuania, Malaysia, Martinique, Mauritania,
                  Mauritius, Morocco, Norfolk Island, N. Korea, Oman,
                  Pakistan, Portugal, Qatar, Rwanda, Saudi Arabia, Slovenia,
                  Somalia, South Sudan, Spain, Sudan, Syria, Tunisia, Turkey,
                  UAE, United States and its dependencies, military bases and
                  territories including but not limited to American Samoa,
                  Guam, Marshall Islands, N. Mariana Islands, Puerto Rico, and
                  Virgin Islands, Yemen.
                </li>
              </ul>
              <h4>5. Age Verification</h4>
              <h5>
                Your age must be verified within 72 hours of Your first
                deposit on the Site. If Your age is not verified within this
                time, Your Account may be frozen and no further gambling will
                be permitted until We have successfully verified Your age. We
                may withhold any funds in Your Account until Your age is
                successfully verified. If You are found to be under 18, We
                will return any deposits and void all winnings and/or bonuses.
              </h5>
              <h4>6. Other Forms of Verification</h4>
              <h5>
                {" "}
                We are under a strict duty as a gambling operator, in
                accordance with various laws and regulations, to ensure that
                You are who You claim to be and that the funds that You use to
                play are Your funds and are earned legitimately. We may
                therefore perform third party searches against Your name,
                compare the personal information You have provided, or We
                otherwise hold against other databases and make such enquiries
                and request such information and documents as We require to
                validate Your Account and the funds being used to play in that
                account. Failure to provide any information asked of You to
                enable us to comply with Our obligations under such laws and
                regulations (or providing incorrect information) will result
                in Your Account being closed and/or You being reported to the
                relevant regulatory body (including, without any further
                notice to You, criminal and serious crime monitoring/reporting
                agencies in any territory).
              </h5>
              <h4>6.1 Play</h4>
              <h5>
                6.1.1 We have a lot of Games on the Site. Simply go to the
                games lobby and start playing by clicking on the game of Your
                choice.
              </h5>
              <h5>
                6.1.2 You must always play the Games on the Site in line with
                these Rules and any special Rules that apply to Games offered
                on the Site. Different Rules apply to different Games (Where
                such rules apply, please click on the link for each Game to
                see to the rules that apply to that Game):
              </h5>
              <ul>
                <li>-&gt; Casino</li>
                <li>-&gt; Live casino</li>
                <li>-&gt; Sportsbook</li>
                <li>-&gt; Virtual Sports</li>
                <li>-&gt; Fantasy Games</li>
                <li>-&gt; Others </li>
              </ul>
              <h4>6.2 Cancellation</h4>
              <h5>
                6.2.1 We do not accept cancellations of bets or stakes on any
                Games except for in exceptional circumstances. Such
                cancellation is solely at Our discretion.
              </h5>
              <h5>
                6.2.2 We do not issue refunds except under exceptional
                circumstances, which is solely at Our discretion.
              </h5>
              <h4>7. General Rules For You</h4>
              <h5>
                7.1 You must register and play on Your own behalf only and not
                on behalf of anyone else.
              </h5>
              <h5>
                7.2 You must keep Your login details secure and not share them
                with anyone. If you share them, even unintentionally, You are
                responsible for any abuse or misuse of Your Account.
              </h5>
              <h5>
                7.3 You must keep Your registration information up to date at
                all times.
              </h5>
              <h5>
                7.4 If You realise that the information provided at
                registration is incorrect, You must tell Us immediately or
                immediately amend Your information.
              </h5>
              <h5>
                7.5 It is Your responsibility to know if Your activity on the
                Site is legal in the country from which You are playing.
              </h5>
              <h5>
                7.6 You are only allowed one Account on this Site. If you have
                more than one Account, You must immediately tell Us.
              </h5>
              <h5>
                7.7 Only one Account for each household is allowed. If You
                realise that there is more than one Account at your household,
                You must immediately tell Us.
              </h5>
              <h5>
                7.8 You may play any Game on the Site, as long as You have
                enough money on Your Account.
              </h5>
              <h5>
                7.9 You are not allowed to transfer or receive funds from one
                Account to another and/or to transfer, sell or buy Accounts.
              </h5>
              <h5>
                7.10 You agree that We can check You are who You claim to be.
                You agree to give Us all documents We request to prove Your
                registration information and payment details, such as (by way
                of example only) a copy of Your passport, utility bills or
                bank statements.
              </h5>
              <h5>
                7.11 You cannot use any technical error or vulnerability to
                Your advantage. We will be entitled to cancel and reclaim all
                winnings gained in this manner.
              </h5>
              <h5>
                7.12 You will not be involved in any fraudulent, collusive,
                fixing or other unlawful activity in relation to Your (or
                third party) use of the Site. You shall not use any software
                assisted methods or techniques or hardware devices to aid of
                Your participation in the Games on the Site.
              </h5>
              <h5>
                7.13 You agree that we may also share Your information with
                other people or businesses in order to carry out Our business
                and deliver the Site to You.
              </h5>
              <h5>
                7.14 You agree that all deposits/withdrawals made to/from Your
                player Account, are effected to/from bank account(s),
                debit/credit card(s) or any other payment options available in
                Your country, which belong solely to You and are in Your name.
              </h5>
              <h5>
                7.15 In the event that streaming services are made available
                to You, so that You can have access to data or live/real time
                audio-visual coverage, You (i) agree to comply with any terms
                and conditions that will be disclosed to You in connection
                with such streaming services, (ii) acknowledge that all
                intellectual property rights in such material belong to third
                parties and agree that such material is made available to You
                solely for Your personal non-commercial use, and (iii) further
                agree that You may only download such material on to your
                personal computer and any other use is strictly prohibited,
                (iv) agree in particular not to (and agree not to assist or
                facilitate any third party to) copy, reproduce, transmit,
                publish, display, distribute, commercially exploit, tamper
                with or create derivative works of any data or live/real time
                audio-visual coverage provided to You.
              </h5>
              <h4>8. What We Can Do</h4>
              <h5>8.1 We can refuse to open an Account for any reason.</h5>
              <h5>
                8.2 We can suspend or close Your Account for any breach or
                suspected breach of this Agreement or other valid reason. Upon
                closure of Your Account, the full balance in Your Account will
                be paid to You unless there is a reason for such funds to be
                withheld.
              </h5>
              <h5>
                8.3 Whilst We understand that mistakes and typos happen, if We
                find the information given at the time of registration to be
                incorrect, this gives Us the right to close Your Account and
                keep any funds in it. Likewise, if You fail to pass any
                personal identification verification procedures We will also
                close Your Account and withhold any winnings.
              </h5>
              <h5>
                8.4 If You try to open any other Account, on top of Your first
                Account, We will block or close the Account. We may also block
                or close Your first Account. If We leave Your first Account
                open Your remaining deposits from any other Accounts, if any,
                will be transferred to Your first Account. We will deduct an
                administrative fee of 10% (minimum EUR 50) for every other
                Account opened. We may also deduct further fees under these
                Rules.
              </h5>
              <h5>
                8.5 If We find there is more than one Account registered for
                Your household We will block or close any Accounts for that
                household.
              </h5>
              <h5>
                8.6 We may share Your personal and payment information with
                other people or businesses to check that You are who You say
                You are and for any verification procedures that We require.
              </h5>
              <h5>
                8.7 We do not give credit. Any balance on Your Account will
                not bear interest. We will not be treated as a financial
                institution.
              </h5>
              <h5>
                8.8 If You are found to be using the Website in any way for
                which it is not intended in order to gain money, We will
                adjust Your Account to remove any money gained from such
                activity and the Account may be permanently closed.
              </h5>
              <h5>
                8.9 We can refuse, terminate or limit any bet or wager for any
                reason.
              </h5>
              <h5>
                8.10 We will declare a bet or stake partially or fully void
                (and/or close Your Account) if We believe that any of the
                following apply:
              </h5>
              <ul>
                <li>
                  -&gt; You, or people associated with You may have directly
                  or indirectly influenced the outcome of an event;
                </li>
                <li>
                  -&gt; You, or people associated with You may have access to
                  insider knowledge that can influence the outcome of an
                  event;
                </li>
                <li>
                  -&gt; You, or people associated with You are engaged in
                  arbitrage or matched betting;
                </li>
                <li>
                  -&gt; You and or people associated with You directly or
                  indirectly ignore the Rules;
                </li>
                <li>
                  -&gt; The result of an event has been directly or indirectly
                  affected by criminal activity;
                </li>
                <li>
                  -&gt; A significant change in odds happened, for example due
                  to a public announcement in relation to an event;
                </li>
                <li>
                  -&gt; Bets have been offered, placed and/or accepted that We
                  would not have accepted, due to a technical issue affecting
                  the Site at that point in time or a mistake, misprint and/or
                  anything which is outside Our control.
                </li>
              </ul>
              <h5>
                8.11 We may close Your Account, cancel and/or refund any of
                Your winnings found in the “Available to Bet” balance, (after
                the deduction of any withdrawal charges), withhold winnings
                and/or apply debits as required (in relation to damage caused
                by You) without any obligation to state a reason or give any
                notice. If We do this, all valid contractual obligations in
                place at the time of closure will be honoured.
              </h5>
              <h5>
                8.12 If We suspect that You are involved in illegal or
                fraudulent activities relating to Your use of the Site (or a
                third party’s use of the Site) or are in trouble with
                creditors or are involved in any other behaviour detrimental
                to the Site, We may freeze or close Your Account and/or cancel
                stakes.
              </h5>
              <h5>
                8.13 We offer fair, open and transparent progressive jackpots
                on specific games for players who use its services for
                entertainment purposes only. In cases where We suspect that a
                game’s or promotion’s intended spirit is abused, We reserve
                the right to suspend, or cancel entirely, Your access to the
                game in question. Should We find it necessary, We may also
                close Your Account with immediate effect. Where Your Account
                has been terminated, We shall not be liable for any losses up
                to the point the Account was closed, nor will We consider any
                refunds on balances remaining on the Account. ‘Abuse’ is
                defined as, but not limited to, software-aided play on
                multiple games concurrently to generate more rounds of a game
                that could not be done naturally by a human player playing
                alone. Also, the practice of ‘cherry-picking’ games with
                ‘value’ jackpots, to the rank exclusion of all other games,
                will be considered an abuse of the spirit in which the jackpot
                is intended. In all cases where abuse has been proven beyond a
                reasonable doubt, We retain the right to confiscate all of the
                winnings and any balance remaining on the Account.
              </h5>
              <h5>
                8.14 All transactions will be monitored and/or reviewed to
                prevent money laundering.
              </h5>
              <h4>9. Payment Rules</h4>
              <h5>
                9.1 Making Deposits Payment methods can be found under both
                “My Account” and “Deposit” pages of the Site. The payment
                methods made available to You may change. Some payment methods
                may not be available in all countries. When choosing a payment
                method, You agree to the terms of that payment method, You
                agree to any charges that apply, You agree that the payment
                method is valid and You agree that the payment account or card
                belongs to You and that You have the right to use the payment
                method. The billing descriptor for all Bank Card payments will
                show up as it's own website. It is unlawful to deposit money
                which has been obtained illegally. We accept payments made in
                EUR, BRL, USD or such other currency as is made available in
                the currency drop down from time to time. You must choose one
                currency as the currency of Your Account. Any payments
                received in a currency other than the one chosen by you will
                be converted into the currency of Your Account at the exchange
                rate We choose. Also, any exchange premiums are payable by
                you. When payment is made using a credit card, We may request
                a certified copy of that credit card (front and back). Please
                ensure that only the first 6 digits and the last 4 digits of
                the card are visible and that the CVV /CVV2 number on the back
                of the card is also not visible. We do not accept cash
                directly or cash delivered to any payment solution provider.
              </h5>
              <h5>
                9.2 Payouts When the outcome of a Game You take part in is
                determined, or when We confirm the result of an event, any
                winnings will be made available to you on Your Account. If We
                mistakenly credit Your Account with winnings that do not
                belong to You for any reason, these will be taken back from
                Your Account. If You have withdrawn these winnings before We
                become aware of this error, You must immediately inform Us and
                will be required to pay this back. If You do not We will take
                all legal action available to us to recover this debt.
              </h5>
              <h5>
                9.3 Withdrawals You may withdraw any amount up to the
                “Available to Bet” balance in Your Account by issuing a valid
                notice of withdrawal. Notices for withdrawals must be made on
                the Site only. If You want to withdraw all Your funds, You
                must first cancel any outstanding stakes. You agree that funds
                in Your Account must only be used for playing the Games found
                on the Site. If You request a withdrawal without turning over
                an amount that is equal or more than the amount deposited, We
                reserve the right to enforce a turnover of the full or part of
                the previous deposit(s) made and/or charge a fee on the
                withdrawal requested. We can reject a withdrawal request if We
                suspect that funds are being withdrawn for any fraudulent or
                money laundering reasons. Prior to accepting a withdrawal, We
                may request that You provide legal identification, for example
                certified copies of passports, ID cards or other such
                documentation as We feel is required in the circumstances. We
                may also carry out phone verification, face verification or
                other such verification as is required to ensure that You are
                who You say You are. If You withdraw an amount which
                cumulatively exceed EUR 2,000 (or the equivalent in Your
                Account currency) We will carry out additional verification
                procedures. We may also carry out these verification
                procedures even in the case of lower withdrawals. Where a
                withdrawal is to be made using a credit card, We may request a
                certified copy of that credit card (front and back). Please
                ensure that only the first 6 digits and the last 4 digits of
                the card are visible and that the CVV /CVV2 number on the back
                of the card is also not visible. All withdrawals must be done
                through the same payment method chosen by You when placing a
                deposit, unless We decide otherwise or are unable to do so. If
                You deposit using a number of payment methods, We reserve the
                right to split Your withdrawal across such payment methods and
                process each part through the respective payment method at Our
                discretion and in accordance with anti-money laundering
                policies and regulations. The time for a withdrawal request to
                be finalised may vary due to circumstances, however a
                withdrawal attempt should be approved or denied within three
                (3) business days. When a withdrawal is cancelled, the funds
                are returned back to your account and you can make use of
                those funds accordingly on the account. webiste does not
                assume any responsibility for any funds lost during gameplay
                following a withdrawal cancellation either by you or by us.
                All winnings of 20,000 EUR or more (excluding progressive
                jackpot wins), we reserve the right to divide the payout into
                ten installments, paid with 10 per cent of the winning sum
                every month until the full amount is paid out. It is at the
                players discretion to request a withdrawal every month until
                the funds are entirely paid out. We may request that You
                redirect Your withdrawal or cancel it or request that it is
                redirected to a different method of withdrawal in accordance
                with anti-money laundering polices and/or regulations.
                Cancellation of a withdrawal (whether this was made by You or
                Us) does not entitle You to any compensation should You choose
                to play with the funds in Your Account before any withdrawal
                is completed. We reserve the right to charge an administrative
                fee in the event that You proceed with more than 30 approved
                withdrawals/payout requests during one (1) calendar month. The
                administrative fee shall be 2% of the transaction value but no
                less than EUR 5. It shall be charged on each withdrawal
                following the 30th withdrawal within each given month.
              </h5>
              <h5>
                9.4 Chargebacks If a chargeback on a card or depositing method
                You have used is requested by the payment method provider, We
                may take the following steps:
              </h5>
              <ul>
                <li>-&gt; We may block Your Account;</li>
                <li>
                  -&gt; We may take any such action as we are entitled to by
                  law;
                </li>
                <li>
                  -&gt; A chargeback notice may be issued and sent to You,
                  using Your registration details;
                </li>
                <li>
                  -&gt; We will use the same payment method as chosen by You
                  when placing a deposit in order to issue the chargeback. If
                  We are unable to confirm Your identity and the payment
                  method used in order to credit the Account in question, We
                  will send out two reminder emails which will be subject to
                  an administrative fee of fifty (50) euros each, which will
                  be withdrawn from Your Account funds unrelated to the
                  chargeback. The balance of Your Account may turn negative in
                  cases of chargeback.
                </li>
              </ul>
              <h4>10. Closing Your Account</h4>
              <h5>
                10.1 You can close Your Account at any time, by contacting
                support directly on support email.
              </h5>
              <h5>10.2 When You close Your Account:</h5>
              <ul>
                <li>
                  -&gt; You must first cancel any outstanding offers for
                  stakes that You have made;
                </li>
                <li>
                  -&gt; You must contact support and specifically request the
                  closure of Your Account;
                </li>
                <li>
                  -&gt; We will transfer all funds from Your Account (subject
                  to the deduction of relevant withdrawal charges).
                </li>
              </ul>
              <h5>
                10.3 If You want to recover funds from closed, blocked or
                excluded Accounts, You need to contact support.
              </h5>
              <h5>
                10.4 If You are closing Your Account because You have a
                problem with gambling addiction, please refer to Our
                Responsible Gaming Rules.
              </h5>
              <h4>11. Dormant &amp; Inactive Accounts</h4>
              <h5>
                11.1 An Inactive Account is an Account having a real money
                balance that has not been accessed for an uninterrupted period
                of 12 months.
              </h5>
              <h5>
                11.2 A Dormant Account is an Account having a real money
                balance that has not been accessed for an uninterrupted period
                of 30 Months.
              </h5>
              <h5>
                11.3 Once Your Account is inactive, We will contact You via
                e-mail to inform You that We will charge a EUR 5.00 per month
                administrative fee, unless You login to Your Account.
              </h5>
              <h5>
                11.4 If You log-in before Your Account becomes dormant, Your
                Account will no longer be charged the administrative fee. You
                may also request a full refund of the administrative fees
                charged up until then by contacting support. The period of
                inactivity starts to run again from Your last log-in date.
              </h5>
              <h5>
                11.5 If, Your Account becomes dormant, and We are still unable
                to reach You, We, if required, in accordance with the
                applicable law (depending on where You are resident), shall
                transfer Your remaining balance to the relevant regulatory
                body.
              </h5>
              <h4>12. Bonuses</h4>
              <h5>
                12.1 Any Bonus is only available once per: person, family,
                household, address, e-mail address, credit card number, bank
                account, telephone number, computer/device and/or IP address.
              </h5>
              <h5>
                12.2 Any “welcome” Bonuses are only available to new customers
                who open an Account on the Site and who have never held an
                Account on the Site before, have never received a welcome
                offer of any kind and who are invited to take part in a
                welcome offer by virtue of it being specifically offered to
                them when they register on the Site
              </h5>
              <h5>
                12.3 Some Bonuses must be manually activated by You in Your
                Account. Unless stated otherwise, You can do this after
                logging into Your Account and depositing the amount required
                to access the Bonus. Such Bonuses can only be activated when
                real money is available in Your Account. If You play with any
                of Your deposited funds prior to claiming the Bonus, lost
                funds cannot be used to activate the Bonus.
              </h5>
              <h5>
                12.4 When You claim a Bonus, Your real money, the Bonus and
                all winnings generated from them will remain as Bonus money
                and may not be withdrawn or wagered on other products until
                wagering requirements are met.
              </h5>
              <h5>
                12.5 All Bonuses have a wagering requirement that needs to be
                completed within a given time frame before any potential
                winnings and/or bonuses can be withdrawn. Failure to meet the
                required wagering requirements and/or forfeit of an active
                Bonus for any reason may result in the forfeit of all winnings
                attained from Your real money as well as Your Bonus and bonus
                winnings attained throughout the wagering process of the
                Bonus. Any attempt to withdraw any funds or cash out any
                placed bets prior to the complete fulfilment of the Bonus
                wagering requirement, may result in the Bonus and any winnings
                attained (including winnings that originated from Your real
                money used to get the Bonus) being forfeited. System bets do
                not count towards the wagering requirements. Only bets that
                have been placed and settled during the campaign period will
                be considered towards the wagering requirements. Any bets that
                are placed and settled outside this period will not count.
              </h5>
              <h5>
                12.6 Any evidence or reasonable suspicion that specific bets
                were placed on any Game (i.e. betting in proportions on
                different outcomes in the same hand to create "action")
                without risk will not qualify for any wagering requirement.
                Examples of bets without risk include betting on red and black
                simultaneously in Roulette, and betting player and banker
                simultaneously in Baccarat. If You are found to be colluding
                with other players, for example, one person betting on the
                player, the other betting on the banker, You will have Your
                bets cancelled, Account closed and all deposits made by You
                may be forfeited.
              </h5>
              <h5>
                12.7 If You cancel the Bonus You will lose the Bonus and all
                winnings generated from the Bonus.
              </h5>
              <h5>
                12.8 We have the right to refuse any player a Bonus or We may
                alter the Bonus (including cancellation), if We believe that:
              </h5>
              <ul>
                <li>
                  -&gt; The Bonus is being abused (Our definition of abuse
                  includes but is not limited to a player making use of
                  promotional offers without ever risking their own funds);
                </li>
                <li>
                  -&gt; Technical issues have occurred in relation to the
                  Bonus;
                </li>
                <li>
                  -&gt; The Bonus is, for any reason, not able to be conducted
                  as planned.
                </li>
              </ul>
              <h5>
                12.9 We offer promotions in good faith to players who use Our
                services for entertainment purposes. We reserve the right to
                cancel or remove a player’s access to these games in the case
                of suspected abuse of this offer and also, should we deem it
                necessary, to terminate the offender’s Account with immediate
                effect. In the case of an offender’s Account being closed, We
                will not be liable to refund any losses made by a player on
                any Games or to refund any balances on the player’s Account.
                ‘Abuse’ includes but is not limited to using software and/or
                opening multiple accounts in order to gain an unfair advantage
                on a Bonus. In all cases of abuse, We retain the right to
                withhold all winnings and any balances remaining on Your
                Account.
              </h5>
              <h5>
                12.10 All Bonuses are intended for recreational players and/or
                as tokens of appreciation to Our customers and We therefore
                reserve the right to restrict eligibility to participate in
                all or part of any Bonus.
              </h5>
              <h5>
                12.11 The maximum withdrawal amount allowed from winnings
                earned from any Bonus or free spins offer before a deposit has
                been made is €100 or the equivalent in Your Account currency
              </h5>
              <h5>
                12.12 Unless stated otherwise on the specific bonus landing
                pages, all Bonuses in the Casino have got a max bet
                restriction in place of €5 per game round or €.50c bet per
                line (or currency equivalent) and failure to adhere to this
                max bet rule may result in Account closure and forfeit of the
                Bonus and any winnings attained during the wagering of the
                Bonus. For currency equivalent, the following conversion rates
                shall apply unless specifically stated otherwise in the Bonus
                Terms and Conditions:
              </h5>
              <ul>
                <li>-&gt; BRL: R$1 = €4</li>
                <li>-&gt; USD: €1 = $1</li>
              </ul>
              <h5>
                12.13 Irregular Play At any time, We may decide to review all
                records and play transactions and/or to monitor Your use of
                any Bonus or Bonuses. If Irregular play is suspected, We
                reserve the right to disqualify You from any free spins
                promotion or Bonus or other form of promotion and/or withhold
                all deposits and/or winnings and/or close Your Account.
                Irregular play is considered bonus abuse and is not permitted
                on the Site. Irregular play can include (but is not limited
                to): low margin betting, equal betting, zero risk bets or
                hedge betting. These are considered Irregular Play when done
                purposely to exploit Bonuses, in addition the following types
                of play are considered irregular play:
              </h5>
              <ul>
                <li>
                  -&gt; Increasing the balance and then changing Your gaming
                  pattern significantly (bet, game type, bet structure or
                  other pattern of betting) to complete any wagering
                  requirements of any Bonus;
                </li>
                <li>
                  -&gt; Using any betting system or placing even money bets on
                  any game of chance; and / or
                </li>
                <li>
                  -&gt; Using the “Double-Up” or gamble feature within a game
                  to increase win values;
                </li>
                <li>
                  -&gt; Increasing Your balance after placing high value bets
                  and thereafter proceeding to place bets of less than half of
                  the prior bet value, in order to meet bonus wagering
                  requirements;
                </li>
                <li>
                  -&gt; Wagering on Games that do not contribute towards
                  wagering requirements or Games with a 0% wagering weight
                  applied to them as specified in the specific bonus landing
                  page terms;
                </li>
                <li>
                  -&gt; Wagering on games with bonus money to build up value,
                  lose the bonus and then cash out on the built-up value with
                  a secondary deposit;
                </li>
                <li>
                  -&gt; Short term, repetitive and/or unnecessary deposits and
                  withdrawals in order to generate more bonuses.
                </li>
              </ul>
              <h5>
                12.14 Unless provided otherwise in the bonus scheme, all
                bonuses for stakes, winnings, etc. only apply for starting
                price and fixed odds bets and not for multiples, ante-post
                bets or Tote betting. Should multiples be part of a bonus
                scheme, the respective bonus shall relate to the final
                winnings amount and not to every leg's winning.
              </h5>
              <h5>
                12.15 Only one Bonus shall be granted per operation (e.g.,
                bet, deposit). Should a Customer qualify for several Bonuses,
                only the Bonus with the highest Bonus amount shall be granted.
              </h5>
              <h4>13. Responsible Gaming</h4>
              <h5>
                13.1 Gambling can be addictive. If You need some help to
                overcome such addiction, kindly follow this link which will
                redirect You to Our Responsible Gaming page on the Site.
                Please always gamble responsibly.
              </h5>
              <h5>
                13.2 Limits You may impose limits on Your Account. These
                limits are:
              </h5>
              <h5>
                13.2.1 Deposit Limit You may choose to limit Your maximum
                losses. This limit will automatically block any further
                deposits made if the deposit limit set has been exceeded for
                that selected time frame.
              </h5>
              <h5>
                13.2.2 Session Time Limit You may choose to limit the time You
                spend enjoying Our Games. This pop up will appear, reminding
                You of the time limit You imposed on yourself and will allow
                you to log You out of Your Account or continue enjoying Our
                games. Kindly contact support to be able to set this limit.
                Where a session time limit falls on the hour, this pop up will
                be replaced by the Reality Check if you are resident outside
                of the United Kingdom. If You change Your mind, these limits
                can be changed by letting Us know via e-mail. Increasing or
                revoking a limit shall have effect only after seven (7) days;
                whereas decreasing a limit shall have effect immediately.
              </h5>
              <h5>
                13.3 Reality Check If You are resident outside of the United
                Kingdom and only when playing on casino Games on the Site, a
                pop up will appear which will pause Your Game to remind You
                that you have been playing for one hour. This will be done
                every hour. This pop up will remind You of how much You lost
                and how much You won. You will then select to continue to
                enjoy Our Games or log out. This pop up might appear later
                than the automatic one hour schedule if Your Game does not
                allow for temporary suspension. This pop up will appear
                immediately after Your Game ends once You have come back to
                Our lobby. We will not always be able to apply the Session
                Time Limit or Reality Check on the time promised. For example,
                if You play auto spin games, live games or games which are not
                hosted on Our Site We will be unable to apply the Session Time
                Limit or Reality Check reminders until the game has finished
                or You return to Our lobby. You can deactivate the Reality
                Check at any time by opting-out on the pop up. If You would
                like to reactivate this, kindly contact support or refer to
                the ‘My Account’ tab.
              </h5>
              <h5>13.4 Self-Exclusion and Cool Off</h5>
              <h5>
                13.4.1 You may choose to completely exclude yourself from
                playing any of the Games on Our Site. To do so You must login
                and go to the Self-Exclusion page. For residents outside of
                the United Kingdom, You stipulate the period You wish to be
                self-excluded for and Your Account will automatically be
                reactivated. If You are resident in the United Kingdom,
                self-exclusion will apply for a minimum of 6 months and You
                must then contact customer support to have Your Account
                reactivated.
              </h5>
              <h5>
                13.4.2 You will not be able to open any Account with the Site
                should You opt for selfexclusion. You will also forfeit any
                entry into any competition or draw which was entered into
                prior to such self –exclusion as well as forfeiting any
                noncash prizes or invitations which have been granted to You
                or offered to You prior to such self-exclusion but which have
                not yet been redeemed or utilised by You (for example, where
                tickets for an event have been offered but the event is to
                take place during a self-exclusion period, the invitation is
                automatically withdrawn upon self-exclusion) . This is to
                prevent gambling being promoted to You in a time where You
                have taken some time to assess Your gambling behaviour.
              </h5>
              <h5>
                13.4.3 You may also choose to “cool off” if you are a UK
                resident. You may want to take a break from gambling and a
                ‘cooling off period’ can be set for a period of: 24 hours, 1
                week, 3 weeks or 6 weeks. Please contact support or visit this
                link when logged in to request a cool off period. At the end
                of any cool off period, Your Account will automatically be
                reactivated.
              </h5>
              <h5>
                13.5 Other If We believe that gambling will cause You
                financial or personal difficulties, We may close Your Account.
                You must not open a new Account. If You do so, it will be at
                Your own risk and We cannot be held liable.
              </h5>
              <h4>14. Miscarried and Aborted Games</h4>
              <h5>
                We do everything We can not to have any technical foul ups,
                but this isn’t always possible. We therefore need to tell You
                the following:
              </h5>
              <h5>
                14.1 We, Our suppliers and/or licensors are not liable for any
                downtime, server disruptions, lagging, or any technical or
                political disturbance to Game play. Refunds may be given
                solely at the discretion of the management;
              </h5>
              <h5>
                14.2 We, Our suppliers and/or licensors accept no liability
                for any damages or losses which are deemed or alleged to have
                arisen out of or in connection with the Site or its content,
                including and without limitation to:
              </h5>
              <ul>
                <li>
                  -&gt; Delays or interruptions in operation or transmission;
                </li>
                <li>-&gt; Loss or corruption of data;</li>
                <li>-&gt; Communication or lines failure;</li>
                <li>-&gt; Any site or content misuse;</li>
                <li>-&gt; Any errors or omissions in content;</li>
              </ul>
              <h5>
                14.3 In the event of a casino system malfunction all wagers
                are void;
              </h5>
              <h5>
                14.4 If Your Game miscarries because of a failure of the
                system, We shall refund the amount wagered in Your Game by
                crediting it to Your Account or, if Your Account no longer
                exists, by paying it to You directly, in an approved manner.
                If You have accrued credit at the time the Game got
                miscarried, the monetary value of the credit will be paid out
                to Your Account or, if Your Account no longer exists, it will
                be to You directly, in an approved manner;
              </h5>
              <h5>
                14.5 We shall take all reasonable steps to ensure that Our
                computer system enables Your participation in a Game. If after
                You have made a wager game play is interrupted by a failure of
                the telecommunications system or a failure of Your computer
                system, the Game will resume as at the exact time immediately
                before the interruption occurred. If however, such resumption
                is not possible We shall:
              </h5>
              <ul>
                <li>-&gt; Ensure that the Game is terminated;</li>
                <li>
                  -&gt; Refund the amount of the wager to You by placing it in
                  the Your Account.
                </li>
              </ul>
              <h4>15. How to Complain</h4>
              <h5>
                15.1 Ensuring the ultimate Customer experience is in Our DNA
                and very much part of Our mind-set, however We appreciate that
                from time to time complaints need to be addressed. On the rare
                occasion this happens You can get hold of Us through the
                following communication channels:
              </h5>
              <ul>
                <li>-&gt; E-mail Us on: support mail</li>
                <li>-&gt; Chat with us on Site.</li>
              </ul>
              <h5>
                15.2 So that We can deal with Your complaint quickly and
                efficiently, please provide Us with clear information about
                Your identity as well as all relevant details giving rise to
                the issue. This will enable Us to promptly resolve a reported
                matter and reach an amicable settlement.
              </h5>
              <h5>
                15.3 Any claim you might have regarding any transaction must
                be made within six months after the transaction, payment
                and/or settlement took place/should have taken place;
                otherwise we will not consider the claim. Once a claim is
                received, we will review any queried or disputed transactions
                and let you know the outcome. Our decision on this is final,
                in simple terms, what we say goes.
              </h5>
              <h4>16. When Talking to Us</h4>
              <h5>
                16.1 When You contact Us, You will not make statements which:
              </h5>
              <ul>
                <li>-&gt; Are sexually explicit or grossly offensive;</li>
                <li>-&gt; Use derogatory terms and/or incite hatred;</li>
                <li>
                  -&gt; Are abusive, defamatory or otherwise harass, threaten
                  or cause distress or inconvenience to our support agents.
                </li>
              </ul>
              <h5>
                16.2 We record or keep copies of all communications that You
                have with Our support agents.
              </h5>
              <h4>17. When Talking to Each Other</h4>
              <h5>
                17.1 We may provide You with a chat facility to talk to other
                players. When contacting each other, You shall not make
                statements or transmit content which:
              </h5>
              <ul>
                <li>-&gt; Is sexually explicit or grossly offensive;</li>
                <li>-&gt; Uses derogatory terms and/or incites hatred;</li>
                <li>-&gt; Encourages or facilitates antisocial behaviour;</li>
                <li>
                  -&gt; Encourages or facilitates any illegal activities;
                </li>
                <li>
                  -&gt; Harasses, threatens or causes distress or
                  inconvenience to other players;
                </li>
                <li>
                  -&gt; Influences other player’s actions through intimidation
                  or abuse;
                </li>
                <li>
                  -&gt; Disrupts the normal flow of dialogue, makes statements
                  that are abusive, defamatory, harassing or insulting to the
                  users of the Site;
                </li>
                <li>
                  -&gt; Advertises, promotes or otherwise relates to any other
                  online entities including forums;
                </li>
                <li>
                  -&gt; Is about the [removed duplicated “the”] Site, or any
                  other Internet site(s) connected to the Site and is untrue
                  and/or malicious and/or damaging to the Site;
                </li>
              </ul>
              <h5>
                17.2 You will not collude in any way through the chat rooms.
              </h5>
              <h5>
                17.3 Any suspicious chats will be reported to the Regulator
                and all relevant authorities. Be careful what You post.
              </h5>
              <h5>
                17.4 We review all chats and keep a log and record of all
                statements. Your use of the chat facility should be for
                recreational and socialising purposes only.
              </h5>
              <h5>
                17.5 If You breach any of the provisions relating to the chat
                facility, We may remove the chat room or immediately close
                Your Account. If We do, We shall refund any funds which may be
                in Your Account over and above any amount which may be owed to
                Us (if any).
              </h5>
              <h5>
                17.6 We will not be liable if damage arises out of such chat
                functionality. You agree to compensate Us against any damage
                arising out of Your illegal, unlawful or inappropriate conduct
                or arising out of violation of these chat Rules.
              </h5>
              <h4>
                18. Privacy Policy You can read our Privacy Statement here
              </h4>
              <h5>
                You should be aware that acceptance of the Rules includes full
                acceptance of the terms of Our Privacy Statement. We therefore
                ask that You read the Privacy Statement carefully.
              </h5>
              <h4>19. Your Liability to Us</h4>
              <h5>
                19.1 You enter the Site and participate in the Games at Your
                own risk. The Site and the Games are provided ‘as is’. The
                only promises We make in relation to the Site are detailed in
                these Rules. We do not warrant (in simple language, We do not
                legally promise) that:
              </h5>
              <ul>
                <li>
                  -&gt; The software or the Site is fit for purpose and free
                  from errors;
                </li>
                <li>
                  -&gt; The Site and/or Games will be accessible without
                  interruptions.
                </li>
              </ul>
              <h5>
                19.2 We shall not be liable for anything, this includes any:
                loss, costs, expenses or damages, whether direct, indirect,
                special, consequential, incidental or otherwise arising in
                relation to Your use of the Site or Your participation in the
                Games.
              </h5>
              <h5>
                19.3 You hereby agree to fully indemnify Us (in simple
                language, compensate Us for any loss) and directors,
                employees, partners, and service providers for any: cost,
                expense, loss, damages, claims and liabilities howsoever
                caused that may arise in relation to Your use of the Site or
                participation in the Games. If You don’t agree to this, You
                should not use the Site.
              </h5>
              <h4>20. Breaches, Penalties and Termination</h4>
              <h5>
                20.1 If we suspect that You have breached these Rules, We may
                refuse to open, suspend or close Your Account. We may also
                withhold any money in Your Account (including the deposit) and
                set off any money or damages due to Us against the money in
                Your Account.
              </h5>
              <h5>
                20.2 We also have the right to freeze or terminate Your
                Account or cancel any stakes if:
              </h5>
              <h5>
                We suspect that You are engaged in illegal or fraudulent
                activities;
              </h5>
              <h5>
                You are having problems with creditors or otherwise which are
                detrimental to Our business.
              </h5>
              <h5>
                You are having problems with creditors or otherwise which are
                detrimental to Our business.
              </h5>
              <h5>20.3 Our decision is final.</h5>
              <h4>21. Anti-money Laundering Reporting</h4>
              <h5>
                21.1 As a gaming operator We need to comply with the
                Prevention of Money Laundering Act and any associated
                Regulations. We shall check all transactions and report any
                suspicious transaction to the relevant competent authorities
                in Malta (or other such territory as may be relevant to Your
                transactions).
              </h5>
              <h5>
                21.2 f You become aware of any suspicious activity relating to
                any of the Games on the Site, You must report this to Us
                immediately.
              </h5>
              <h5>
                21.3 We may suspend, block or close Your Account and withhold
                funds, in accordance with the Prevention of Money Laundering
                Act.
              </h5>
              <h4>22. Intellectual Property</h4>
              <h5>
                22.1 We are the sole owner of the trademark ‘website’ and it's
                logo. Any unauthorised use of the ‘website’ trademark and it's
                logo may result in prosecution.
              </h5>
              <h5>
                22.2 Website is Our uniform resource locator and no
                unauthorised use may be made of this URL on any other Site or
                digital platform without Our prior written consent. Links to
                the Site and any of the pages therein may not be included in
                any other Site without Our prior written consent.
              </h5>
              <h5>
                22.3 We are the owner or the rightful licensee of the rights
                to the technology, software and business systems used within
                the Site.
              </h5>
              <h5>
                22.4 The contents and structure of the Site pages are subject
                to copyright © and database rights in the name of - all rights
                reserved. The copyright in this Site including all: text,
                graphics, code, files and links belongs to Us and the Site may
                not be reproduced, transmitted or stored in whole or in part
                without Our written consent. Your registration and use of the
                Site does not confer any rights whatsoever to the intellectual
                property contained in the Site.
              </h5>
              <h5>
                22.5 You agree not to use any automatic or manual device to
                monitor Our web pages or any content therein. Any unauthorised
                use or reproduction may be prosecuted.
              </h5>
              <h4>23. Severability</h4>
              <h5>
                If any provision of these Rules is held to be illegal or
                unenforceable, such provision shall be severed from these
                Rules and all other provisions shall remain in force
                unaffected by such severance.
              </h5>
              <h4>24. Entire Agreement and Admissibility</h4>
              <h5>
                24.1 The Rules are the entire agreement between us with
                respect to this Site and, except in the case of fraud,
                overrule all prior communication and proposals, whether
                electronic, oral or written, between us.
              </h5>
              <h5>
                24.2 A printed version of these Rules and any notice given in
                electronic form shall be admissible in judicial or
                administrative proceedings.
              </h5>
              <h4>25. Assignment</h4>
              <h5>
                We can assign or transfer this agreement. You cannot assign or
                transfer this agreement.
              </h5>
              <h4>26. Insolvency</h4>
              <h5>
                We hold all customer funds in a separate bank account. You
                must be aware that these funds are not protected in the case
                of insolvency and therefore in the event that We should go
                insolvent, Your funds may not be returned to You. For
                customers in the United Kingdom, We have a duty to inform You
                that this level of protection is classed as the basic level.
                For more information, please see here.
              </h5>
              <h4>27. Applicable Law, Jurisdiction and Language</h4>
              <h5>
                27.1 These Rules are published in a number of languages. It is
                only the English version that forms the legal basis of the
                relationship between us, but all versions reflect the same
                principles. Translations into other languages are made as a
                service and in good faith, in the event of any discrepancy
                between an English version and a translation, the English
                version shall prevail.
              </h5>
              <h4>28. Appendix</h4>
              <h5>
                28.1 Game Specific Rules The Rules included in this section
                relate to specific Games. We sometimes use Games provided by
                other companies and they ask us to include these rules in our
                own Rules. There are sometimes Rules which are so specific to
                a Game, you only need to be aware of those Rules if you play
                that Game. All of these rules have been dropped in this
                section. By playing any of the Games you will be agreeing to
                these Game Rules.
              </h5>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
}
