import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";
import CasinoPlacedBet from "./components/CasinoPlaceBet";

export default function TeenPattiTest() {
  const id = 12;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);
  const [betIndex, setBetIndex] = useState("");

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const sectionMap = {
    11: "T",
    21: "L",
    31: "D",
  };

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("32cardA", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);
  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };
  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.tsection === betIndex
      );

      console.log("filter", filteredData, betDetails);

      (filteredData[0]?.tstatus == "True" ||
        filteredData[0]?.lstatus == "True" ||
        filteredData[0]?.dstatus == "True") &&
      betDetails.nation === filteredData[0].nation &&
      (betDetails.multipler === filteredData[0].trate ||
        betDetails.multipler === filteredData[0].lrate ||
        betDetails.multipler === filteredData[0].drate)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);
  const [selectedTab, setSelectedTab] = React.useState("game");

  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-6 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-6"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-page-container ng-tns-c1798067373-6 teenpattitest"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-6"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-6"
            >
              <li
                onClick={() => setSelectedTab("game")}
                className="nav-item ng-tns-c3502172659-2"
              >
                <div
                  className={` ng-tns-c3502172659-2
                    ${selectedTab === "game" && "active"}
                    nav-link`}
                >
                  Game
                </div>
              </li>
              <li
                onClick={() => setSelectedTab("placed")}
                className="nav-item ng-tns-c3502172659-2"
              >
                <div
                  className={`ng-tns-c3502172659-2
                      ${selectedTab != "game" && "active"}
                      nav-link`}
                >
                  Placed Bet ({history?.length})
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-6"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-6"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-6"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-6"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-6"
            >
              {" "}
              Test Teen Patti
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-6"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-6"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-6"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 ng-star-inserted"
                >
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
                {/**/}
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-6 ng-star-inserted"
              >
                {" "}
                | Min: 100 {/**/}
                {/**/} | Max: 300000 {/**/}
                {/**/}
              </span>
              {/**/}
            </span>
          </div>
          {/**/}
          {/**/}
          {selectedTab === "game" ? (
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-6 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-video ng-tns-c1798067373-6"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="video-box-container ng-tns-c1798067373-6"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-video-box ng-tns-c1798067373-6 ng-star-inserted"
                  >
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""
                    />
                    {/* <iframe
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.teenPattiTest}
                  /> */}
                  </div>
                  {/**/}
                  <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6 ng-star-inserted"
                >
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-video-cards ng-tns-c1798067373-6 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6 ng-star-inserted"
                    >
                      <h5
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        {" "}
                        TIGER
                      </h5>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-container ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6HH.webp"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/ADD.webp"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        {/**/}
                      </div>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6 ng-star-inserted"
                    >
                      {/**/}
                      <h5
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        LION
                      </h5>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-container ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8CC.webp"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        {/**/}
                      </div>
                      {/**/}
                      {/**/}
                      {/**/}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6 ng-star-inserted"
                    >
                      {/**/}
                      {/**/}
                      <h5
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        {" "}
                        DRAGON
                      </h5>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-container ng-tns-c1798067373-6 ng-star-inserted"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4HH.webp"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="me-lg-2 me-1 ng-tns-c1798067373-6 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/1.webp"
                          />
                        </div>
                        {/**/}
                      </div>
                      {/**/}
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                  {/**/}
                </div>
                {/**/}
              </div>
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-detail ng-tns-c1798067373-6"
              >
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table ng-tns-c1798067373-6 ng-star-inserted"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-full-box ng-tns-c1798067373-6"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header test-teenpatti-header-back ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-6"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              {" "}
                              100 {/**/}
                              {/**/}
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-6"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-6"
                            >
                              {" "}
                              300000 {/**/}
                              {/**/}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Back
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-header ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-detail ng-tns-c1798067373-6"
                      />
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Tiger
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Lion
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds-box back ng-tns-c1798067373-6"
                      >
                        Dragon
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-table-body ng-tns-c1798067373-6"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Winner
                          </div>
                        </div>
                        <div className="ng-tns-c1798067373-6 casino_odds_row">
                          {reqRes?.data?.data?.t2[0]?.tstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[0].trate,
                                  reqRes?.data?.data?.t2[0].nation,
                                  1
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[0]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[0].trate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 1 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 1 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[0]?.lstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[0].lrate,
                                  reqRes?.data?.data?.t2[0].nation,
                                  7
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[0]?.lsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[0].lrate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 7 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 7 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[0]?.dstatus !== "True" ? (
                            <div className="casino-odds-box suspended-box back ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[0].drate,
                                  reqRes?.data?.data?.t2[0].nation,
                                  14
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[0]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[0].drate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 14 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 14 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </div>

                        {/* <div
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-6 casino_odds_row suspended-box"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            2.94
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            {" "}
                            0{" "}
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            2.94
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            {" "}
                            0{" "}
                          </p>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-odds-box back ng-tns-c1798067373-6"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="casino-odds ng-tns-c1798067373-6"
                          >
                            2.94
                          </span>
                          <p
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-6"
                          >
                            {" "}
                            0{" "}
                          </p>
                        </div>
                      </div> */}
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Pair
                          </div>
                        </div>
                        <div className="ng-tns-c1798067373-6 casino_odds_row">
                          {reqRes?.data?.data?.t2[1]?.tstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[1].trate,
                                  reqRes?.data?.data?.t2[1].nation,
                                  2
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[1]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[1].trate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 2 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 2 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[1]?.lstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[1].lrate,
                                  reqRes?.data?.data?.t2[1].nation,
                                  8
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[1]?.lsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[1].lrate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 8 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 8 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[1]?.dstatus !== "True" ? (
                            <div className="casino-odds-box suspended-box back ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[1].drate,
                                  reqRes?.data?.data?.t2[1].nation,
                                  15
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[1]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[1].drate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 15 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 15 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Flush
                          </div>
                        </div>
                        <div className="ng-tns-c1798067373-6 casino_odds_row">
                          {reqRes?.data?.data?.t2[2]?.tstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[2].trate,
                                  reqRes?.data?.data?.t2[2].nation,
                                  3
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[2]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[2].trate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 3 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 3 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[2]?.lstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[2].lrate,
                                  reqRes?.data?.data?.t2[2].nation,
                                  9
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[2]?.lsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[2].lrate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 9 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 9 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[2]?.dstatus !== "True" ? (
                            <div className="casino-odds-box suspended-box back ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[2].drate,
                                  reqRes?.data?.data?.t2[2].nation,
                                  16
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[2]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[2].drate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 16 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 16 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Staright
                          </div>
                        </div>
                        <div className="ng-tns-c1798067373-6 casino_odds_row">
                          {reqRes?.data?.data?.t2[3]?.tstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[3].trate,
                                  reqRes?.data?.data?.t2[3].nation,
                                  4
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[3]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[3].trate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 4 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 4 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[3]?.lstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[3].lrate,
                                  reqRes?.data?.data?.t2[3].nation,
                                  10
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[3]?.lsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[3].lrate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 10 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 10 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[3]?.dstatus !== "True" ? (
                            <div className="casino-odds-box suspended-box back ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[3].drate,
                                  reqRes?.data?.data?.t2[3].nation,
                                  17
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[3]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[3].drate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 17 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 17 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Trio
                          </div>
                        </div>
                        <div className="ng-tns-c1798067373-6 casino_odds_row">
                          {reqRes?.data?.data?.t2[4]?.tstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[4].trate,
                                  reqRes?.data?.data?.t2[4].nation,
                                  5
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[4]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[4].trate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 5 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 5 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[4]?.lstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[4].lrate,
                                  reqRes?.data?.data?.t2[4].nation,
                                  11
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[4]?.lsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[4].lrate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 11 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 11 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[4]?.dstatus !== "True" ? (
                            <div className="casino-odds-box suspended-box back ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[4].drate,
                                  reqRes?.data?.data?.t2[4].nation,
                                  18
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[4]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[4].drate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 18 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 18 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-table-row ng-tns-c1798067373-6"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-nation-detail ng-tns-c1798067373-6"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="casino-nation-name ng-tns-c1798067373-6"
                          >
                            Straight Flush
                          </div>
                        </div>
                        <div className="ng-tns-c1798067373-6 casino_odds_row">
                          {reqRes?.data?.data?.t2[5]?.tstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[5].trate,
                                  reqRes?.data?.data?.t2[5].nation,
                                  6
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[5]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[5].trate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 6 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 6 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[5]?.lstatus !== "True" ? (
                            <div className="casino-odds-box back suspended-box ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[5].lrate,
                                  reqRes?.data?.data?.t2[5].nation,
                                  12
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[5]?.lsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[5].lrate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 12 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 12 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}

                          {reqRes?.data?.data?.t2[5]?.dstatus !== "True" ? (
                            <div className="casino-odds-box suspended-box back ng-tns-c1798067373-6">
                              <span className="casino-odds ng-tns-c1798067373-6">
                                <i className="fa fa-lock" />
                              </span>
                            </div>
                          ) : (
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-6"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[5].drate,
                                  reqRes?.data?.data?.t2[5].nation,
                                  19
                                );
                                setBetIndex(
                                  reqRes?.data?.data?.t2[5]?.tsection
                                );
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-6">
                                {reqRes?.data?.data?.t2[5].drate}
                              </span>
                              <p className="ng-tns-c1798067373-6">
                                {" "}
                                {thirtyTwoCardStack.map(
                                  (ele) =>
                                    ele.number === 19 && (
                                      <span
                                        style={{
                                          color:
                                            ele.amount >= 0 ? "green" : "red",
                                        }}
                                      >
                                        {ele.number == 19 && ele.amount}
                                      </span>
                                    )
                                )}{" "}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="remark text-right px-2 ng-tns-c1798067373-6"
                  />
                </div>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <div className="ng-tns-c1798067373-0">
                  <div className="casino-last-result-title ng-tns-c1798067373-0">
                    <span className="ng-tns-c1798067373-0">Last Result</span>
                    <span className="ng-tns-c1798067373-0">
                      <a className="ng-tns-c1798067373-0">View All</a>
                    </span>
                  </div>
                  <div className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted">
                    {results?.data?.result?.map((result, index) => {
                      return (
                        <span
                          className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                          key={index}
                        >
                          <span
                            className="ng-tns-c1798067373-0"
                            onClick={() => {
                              setMidRound(result.mid);
                              CasinoGameResult_API(result.mid);
                              setShowresultModal(true);
                              // setTrophy(sectionMap[result?.result]);
                            }}
                            style={{ 
                              color:
                                result?.result === "11" ? "#ff4500" : "inherit",
                            }}
                          >
                            {sectionMap[(result?.result)]}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <CasinoPlacedBet bets={history} />
          )}
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-6 d-none matchbettable w-100 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-6"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-6"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-6"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-6"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-6"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-6"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-6"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
          {/**/}
          {/**/}
        </div>
        {/**/}
      </div>
      <CasinoBetModal
        id={id}
        name={name}
        modalOpen={showModal}
        betDetails={betDetails}
        setbetDetails={setbetDetails}
        setShowModal={setShowModal}
        betHistory={history}
        realTime={realTime}
        setRealTimeData={setRealTimeData}
      />
    </div>
  );
}
