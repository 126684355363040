import { AXIOS_INSTANCE } from "..";
import { CASINO_SERVICE_URL } from "../../utils/constants";

export const getCasinoOddsAPI = async (game) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${CASINO_SERVICE_URL}/odds`, {
      params: {
        casinoGames: game,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getCasinoLastTenResultAPI = async (game) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/last-10-results`,
      {
        params: {
          casinoGames: game,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getCasinoBetsAPI = async (game) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(`${CASINO_SERVICE_URL}/my-bet`, {
      params: {
        casinoGames: game,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getCasinoExposureAPI = async (marketId) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CASINO_SERVICE_URL}/pl-exposure`,
      {
        params: {
          marketId: marketId,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const casinoBetAPI = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CASINO_SERVICE_URL}/bet/place`,
      payload
    );
    return data;
  } catch (error) {
    return error;
  }
};