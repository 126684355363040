import { logoutUser } from "../features/authSlice";

export const AuthInterpertor =
  ({ dispatch }) =>
  (next) =>
  (action) => {

    // console.log("action",action)
    if (
      action.payload?.showableMessage === "Please login to access this resource"
    ) {
      dispatch(logoutUser());
      sessionStorage.clear();
    } else {
      next(action);
    }
  };
