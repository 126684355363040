import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { CARD_ARRAY, CASINO_VIDEO, getOddForEntity } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { io } from "socket.io-client";
import { API } from "../../service/API";
import Timer from "./components/Timer";
import CasinoBetModal from "./CasinoBetModal";
import CasinoPlacedBet from "./components/CasinoPlaceBet";
export default function AndarBahar2() {
  const GAME_ID = "ABJ";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  // const casinoData = useQuery(
  //   "getCasinoOdds",
  //   () => getCasinoOddsAPI(GAME_ID),
  //   {
  //     refetchInterval: 800,
  //   }
  // );
  // const lastTenResultData = useQuery(
  //   "getResult",
  //   () => getCasinoLastTenResultAPI(GAME_ID),
  //   {
  //     refetchInterval: 1500,
  //   }
  // );
  // const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
  //   refetchInterval: 2500,
  // });

  const id = 3;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  // const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);
  const [betIndex, setBetIndex] = useState("");

  // const { casinobetHistory, loading } = useSelector(
  //   (state) => state.casinobetHistory
  // );

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult lucky:", response?.data?.data[0]);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: item?.resultUrlResponse
            ? JSON.parse(item?.resultUrlResponse)
            : null,
        }));

        console.log("AB2", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nat === filteredData[0]?.nat &&
      betDetails?.multipler === filteredData[0]?.b1
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };
  const [selectedTab, setSelectedTab] = React.useState("game");

  return (
    <div className="center-main-container casino-page ng-tns-c1798067373-2 ng-star-inserted">
      <div className="center-container ng-tns-c1798067373-2">
        <div className="casino-page-container ng-tns-c1798067373-2 ab2">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-2">
            <ul className="nav nav-tabs menu-tabs ng-tns-c1798067373-2">
              <li className="nav-item ng-tns-c1798067373-2"  onClick={() => setSelectedTab("game")}>
                <div className="ng-tns-c1798067373-2 active nav-link">
                  {name}
                </div>
              </li>
              <li className="nav-item ng-tns-c1798067373-2"   onClick={() => setSelectedTab("placed")}>
                <div className="ng-tns-c1798067373-2 nav-link">
                  {" "}
                  Placed Bet  ({history?.length})
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c1798067373-2">
              <span className="casino-rid ng-tns-c1798067373-2">
                <a className="d-block ng-tns-c1798067373-2">
                  <small className="ng-tns-c1798067373-2">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c1798067373-2 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c1798067373-2">
            <span className="casino-name ng-tns-c1798067373-2">
              {" "}
              Andar Bahar 2
              <a className="ms-1 d-xl-inline d-none ng-tns-c1798067373-2">
                <small className="ng-tns-c1798067373-2">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-2">
              <small className="ng-tns-c1798067373-2">
                Round ID:{" "}
                <span className="ng-tns-c1798067373-2 ng-star-inserted">
                  {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                </span>
              </small>
              <span className="ng-tns-c1798067373-2 ng-star-inserted">
                {" "}
                | Min: {reqRes?.data?.data?.t1[0]?.min || 0}| Max:{" "}
                {reqRes?.data?.data?.t1[0]?.max || 0}
              </span>
            </span>
          </div>
          {selectedTab === "game" ? (
          <div className="ng-tns-c1798067373-2 ng-star-inserted">
            <div className="casino-video ng-tns-c1798067373-2">
              <div className="video-box-container ng-tns-c1798067373-2">
                <div className="casino-video-box ng-tns-c1798067373-2 ng-star-inserted">
                  {/* <iframe
                    className="ng-tns-c1798067373-2"
                    src={CASINO_VIDEO_URL + CASINO_VIDEO.anderBahr2}
                  /> */}
                   <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                </div>
              </div>
              <div className="ng-tns-c1798067373-2 ng-star-inserted">
                <div className="casino-video-cards ng-tns-c1798067373-2 ng-star-inserted">
                  <div className="ab-cards-container ng-tns-c1798067373-2">
                    <div className="row row5 align-items-center ng-tns-c1798067373-2">
                      <div className="col-1 ng-tns-c1798067373-2">
                        <div className="row row5 ng-tns-c1798067373-2">
                          <div className="col-12 ng-tns-c1798067373-2">
                            <b className="ng-tns-c1798067373-2">A</b>
                          </div>
                        </div>
                        <div className="row row5 ng-tns-c1798067373-2">
                          <div className="col-12 ng-tns-c1798067373-2">
                            <b className="ng-tns-c1798067373-2">B</b>
                          </div>
                        </div>
                      </div>
                      <div className="col-2 ng-tns-c1798067373-2">
                        <div className="flip-card ng-tns-c1798067373-2">
                          <div className="flip-card-inner ng-tns-c1798067373-2">
                            <div className="flip-card-front ng-tns-c1798067373-2">
                              <img
                                className="ng-tns-c1798067373-2"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/QHH.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-9 ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="row row5 mb-1 ng-tns-c1798067373-2">
                          <div className="col-lg-2 col-3 ng-tns-c1798067373-2">
                            <div className="flip-card ng-tns-c1798067373-2">
                              <div className="flip-card-front ng-tns-c1798067373-2">
                                <img
                                  onerror="this.src='assets/images/cards/1.webp'"
                                  className="ng-tns-c1798067373-2"
                                  src="https://247maharaja.com/assets/images/cards/1.webp"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-10 col-9 ng-tns-c1798067373-2">
                            <div className="casino-result-cards col-lg-12 ng-tns-c1798067373-2">
                              <div className="casino-result-cardimg ng-tns-c1798067373-2">
                                <div className="andar-bhar2-cards-slider ng-tns-c1798067373-2">
                                  <div className="draggable ng-tns-c1798067373-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row5 ng-tns-c1798067373-2">
                          <div className="col-lg-2 col-3 ng-tns-c1798067373-2">
                            <div className="flip-card ng-tns-c1798067373-2">
                              <div className="flip-card-inner ng-tns-c1798067373-2">
                                <div className="flip-card-front ng-tns-c1798067373-2">
                                  <img
                                    onerror="this.src='assets/images/cards/1.webp'"
                                    className="ng-tns-c1798067373-2"
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KSS.webp"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-10 col-9 ng-tns-c1798067373-2">
                            <div className="casino-result-cards col-lg-12 ng-tns-c1798067373-2">
                              <div className="casino-result-cardimg ng-tns-c1798067373-2">
                                <div className="andar-bhar2-cards-slider ng-tns-c1798067373-2">
                                  <div className="draggable ng-tns-c1798067373-2" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
            </div>

            <div className="casino-detail ng-tns-c1798067373-2">
              <div className="casino-detail ng-tns-c1798067373-2 ng-star-inserted">
                <div className="casino-table ng-tns-c1798067373-2">
                  <div className="casino-table-full-box ng-tns-c1798067373-2">
                    <div className="playera-bets ng-tns-c1798067373-2">
                      <div className="playera-title ng-tns-c1798067373-2">
                        A
                      </div>
                      <div className="player-sa ng-tns-c1798067373-2 ng-star-inserted">
                        {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                          <div className="player-sa-box ng-tns-c1798067373-2 suspended-box">
                            <span className="ng-tns-c1798067373-2">
                              <div className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[0]?.nat}
                              </div>
                              <div className="casino-volume ng-tns-c1798067373-2">
                                {" "}
                                <i className="fa fa-lock" />
                              </div>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="player-sa-box ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[0]?.b1,
                                  reqRes?.data?.data?.t2[0]?.nat,
                                  1
                                );
                                setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                              }}
                            >
                              <span className="ng-tns-c1798067373-2">
                                <div className="casino-odds ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[0]?.nat}
                                </div>
                                <div className="casino-volume ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[0]?.b1}
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele, index) =>
                              ele.number === 1 && (
                                <span
                                  className={` ${
                                    ele.number == 1 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                  key={index}
                                >
                                  {ele.number == 1 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>

                      <div className="player-bet ng-tns-c1798067373-2 ng-star-inserted">
                        {reqRes?.data?.data?.t2[1]?.gstatus == 0 ? (
                          <div className="player-bet-box ng-tns-c1798067373-2 suspended-box">
                            <span className="ng-tns-c1798067373-2">
                              <div className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[1]?.nat}
                              </div>
                              <div className="casino-volume ng-tns-c1798067373-2">
                                {" "}
                                <i className="fa fa-lock" />
                              </div>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="player-bet-box ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[1]?.b1,
                                  reqRes?.data?.data?.t2[1]?.nat,
                                  2
                                );
                                setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                              }}
                            >
                              <span className="ng-tns-c1798067373-2">
                                <div className="casino-odds ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[1]?.nat}
                                </div>
                                <div className="casino-volume ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[1]?.b1}
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele, index) =>
                              ele.number === 2 && (
                                <span
                                  className={` ${
                                    ele.number == 2 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                  key={index}
                                >
                                  {ele.number == 2 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>

                      <div className="player-bet ng-tns-c1798067373-2 ng-star-inserted">
                        {reqRes?.data?.data?.t2[2]?.gstatus == 0 ? (
                          <div className="player-bet-box ng-tns-c1798067373-2 suspended-box">
                            <span className="ng-tns-c1798067373-2">
                              <div className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[2]?.nat}
                              </div>
                              <div className="casino-volume ng-tns-c1798067373-2">
                                {" "}
                                <i className="fa fa-lock" />
                              </div>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="player-bet-box ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[2]?.b1,
                                  reqRes?.data?.data?.t2[2]?.nat,
                                  3
                                );
                                setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                              }}
                            >
                              <span className="ng-tns-c1798067373-2">
                                <div className="casino-odds ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[2]?.nat}
                                </div>
                                <div className="casino-volume ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[2]?.b1}
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele, index) =>
                              ele.number === 3 && (
                                <span
                                  className={` ${
                                    ele.number == 3 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                  key={index}
                                >
                                  {ele.number == 3 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>

                      <div className="playera-title ng-tns-c1798067373-2">
                        A
                      </div>
                    </div>
                    <div className="playera-bets ng-tns-c1798067373-2">
                      <div className="playera-title ng-tns-c1798067373-2">
                        B
                      </div>
                      <div className="player-sa ng-tns-c1798067373-2 ng-star-inserted">
                        {reqRes?.data?.data?.t2[3]?.gstatus == 0 ? (
                          <div className="player-sa-box ng-tns-c1798067373-2 suspended-box">
                            <span className="ng-tns-c1798067373-2">
                              <div className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[3]?.nat}
                              </div>
                              <div className="casino-volume ng-tns-c1798067373-2">
                                {" "}
                                <i className="fa fa-lock" />
                              </div>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="player-sa-box ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[3]?.b1,
                                  reqRes?.data?.data?.t2[3]?.nat,
                                  4
                                );
                                setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                              }}
                            >
                              <span className="ng-tns-c1798067373-2">
                                <div className="casino-odds ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[3]?.nat}
                                </div>
                                <div className="casino-volume ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[3]?.b1}
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele, index) =>
                              ele.number === 4 && (
                                <span
                                  className={` ${
                                    ele.number == 4 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                  key={index}
                                >
                                  {ele.number == 4 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>

                      <div className="player-bet ng-tns-c1798067373-2 ng-star-inserted">
                        {reqRes?.data?.data?.t2[4]?.gstatus == 0 ? (
                          <div className="player-bet-box ng-tns-c1798067373-2 suspended-box">
                            <span className="ng-tns-c1798067373-2">
                              <div className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[4]?.nat}
                              </div>
                              <div className="casino-volume ng-tns-c1798067373-2">
                                {" "}
                                <i className="fa fa-lock" />
                              </div>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="player-bet-box ng-tns-c1798067373-2"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[4]?.b1,
                                  reqRes?.data?.data?.t2[4]?.nat,
                                  5
                                );
                                setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                              }}
                            >
                              <span className="ng-tns-c1798067373-2">
                                <div className="casino-odds ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[4]?.nat}
                                </div>
                                <div className="casino-volume ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[4]?.b1}
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele, index) =>
                              ele.number === 5 && (
                                <span
                                  className={` ${
                                    ele.number == 5 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                  key={index}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>

                      <div className="player-bet ng-tns-c1798067373-2 ng-star-inserted">
                        {reqRes?.data?.data?.t2[5]?.gstatus == 0 ? (
                          <div className="player-bet-box ng-tns-c1798067373-2 suspended-box">
                            <span className="ng-tns-c1798067373-2">
                              <div className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[5]?.nat}
                              </div>
                              <div className="casino-volume ng-tns-c1798067373-2">
                                {" "}
                                <i className="fa fa-lock" />
                              </div>
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="player-bet-box ng-tns-c1798067373-2"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[5]?.b1,
                                  reqRes?.data?.data?.t2[5]?.nat,
                                  6
                                );
                                setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                              }}
                            >
                              <span className="ng-tns-c1798067373-2">
                                <div className="casino-odds ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[5]?.nat}
                                </div>
                                <div className="casino-volume ng-tns-c1798067373-2">
                                  {reqRes?.data?.data?.t2[5]?.b1}
                                </div>
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele, index) =>
                              ele.number === 6 && (
                                <span
                                  className={` ${
                                    ele.number == 6 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                  key={index}
                                >
                                  {ele.number == 6 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>

                      <div className="playera-title ng-tns-c1798067373-2">
                        B
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-box mt-3 ng-tns-c1798067373-2">
                    <div className="casino-table-left-box ng-tns-c1798067373-2">
                      <div className="ab2-box ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c1798067373-2 ng-star-inserted">
                          {" "}
                          ODD{" "}
                        </div>

                        {reqRes?.data?.data?.t2[23]?.gstatus == 0 ? (
                          <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                            <span className="casino-odds ng-tns-c1798067373-0">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[23]?.rate,
                                  reqRes?.data?.data?.t2[23]?.nat,
                                  24
                                );
                                setBetIndex(reqRes?.data?.data?.t2[23]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-0">
                                {reqRes?.data?.data?.t2[23]?.b1}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 24 && (
                                <span
                                  className={` ${
                                    ele.number == 24 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 24 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="ab2-box ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c1798067373-2 ng-star-inserted">
                          {" "}
                          EVEN{" "}
                        </div>

                        {reqRes?.data?.data?.t2[24]?.gstatus == 0 ? (
                          <div className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme suspended-box">
                            <span className="casino-odds ng-tns-c1798067373-0">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="ng-tns-c1798067373-0 back casino-odds-box casino-odds-box-theme"
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[24]?.rate,
                                  reqRes?.data?.data?.t2[24]?.nat,
                                  25
                                );
                                setBetIndex(reqRes?.data?.data?.t2[25]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-0">
                                {reqRes?.data?.data?.t2[24]?.b1}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 25 && (
                                <span
                                  className={` ${
                                    ele.number == 25 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 25 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-right-box ng-tns-c1798067373-2">
                      <div className="ab2-box ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c1798067373-2">
                          <img
                            className="ng-tns-c1798067373-2 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                          />
                        </div>
                        {reqRes?.data?.data?.t2[19]?.gstatus == 0 ? (
                          <div className="casino-odds-box back ng-tns-c1798067373-2 suspended-box">
                            <span className="casino-odds ng-tns-c1798067373-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[19]?.rate,
                                  reqRes?.data?.data?.t2[19]?.nat,
                                  20
                                );
                                setBetIndex(reqRes?.data?.data?.t2[19]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[19]?.b1}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 20 && (
                                <span
                                  className={` ${
                                    ele.number == 20 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 20 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="ab2-box ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c1798067373-2">
                          <img
                            className="ng-tns-c1798067373-2 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                          />
                        </div>
                        {reqRes?.data?.data?.t2[20]?.gstatus == 0 ? (
                          <div className="casino-odds-box back ng-tns-c1798067373-2 suspended-box">
                            <span className="casino-odds ng-tns-c1798067373-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[20]?.rate,
                                  reqRes?.data?.data?.t2[20]?.nat,
                                  21
                                );
                                setBetIndex(reqRes?.data?.data?.t2[20]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[20]?.b1}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 21 && (
                                <span
                                  className={` ${
                                    ele.number == 21 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 21 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="ab2-box ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c1798067373-2">
                          <img
                            className="ng-tns-c1798067373-2 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                          />
                        </div>
                        {reqRes?.data?.data?.t2[21]?.gstatus == 0 ? (
                          <div className="casino-odds-box back ng-tns-c1798067373-2 suspended-box">
                            <span className="casino-odds ng-tns-c1798067373-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[21]?.rate,
                                  reqRes?.data?.data?.t2[21]?.nat,
                                  22
                                );
                                setBetIndex(reqRes?.data?.data?.t2[21]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[21]?.b1}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 22 && (
                                <span
                                  className={` ${
                                    ele.number == 22 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 22 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="ab2-box ng-tns-c1798067373-2 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c1798067373-2">
                          <img
                            className="ng-tns-c1798067373-2 ng-star-inserted"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                          />
                        </div>
                        {reqRes?.data?.data?.t2[21]?.gstatus == 0 ? (
                          <div className="casino-odds-box back ng-tns-c1798067373-2 suspended-box">
                            <span className="casino-odds ng-tns-c1798067373-2">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div
                              className="casino-odds-box back ng-tns-c1798067373-2 "
                              onClick={() => {
                                handleModal(
                                  reqRes?.data?.data?.t2[21]?.rate,
                                  reqRes?.data?.data?.t2[21]?.nat,
                                  22
                                );
                                setBetIndex(reqRes?.data?.data?.t2[21]?.sid);
                              }}
                            >
                              <span className="casino-odds ng-tns-c1798067373-2">
                                {reqRes?.data?.data?.t2[21]?.b1}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="casino-nation-book text-center ng-tns-c1798067373-2">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 22 && (
                                <span
                                  className={` ${
                                    ele.number == 22 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 22 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-full-box ab2cards mt-3 ng-tns-c1798067373-2">
                    <div className="text-center w-100 ng-tns-c1798067373-2">
                      <div className="casino-bl-box casino-cards-odds-title ng-tns-c1798067373-2">
                        <div className="casino-bl-box-item ng-tns-c1798067373-2">
                          <b className="ng-tns-c1798067373-2">
                            {" "}
                            {reqRes?.data?.data?.t2[6]?.b1}{" "}
                          </b>
                        </div>
                      </div>
                    </div>
                    {reqRes?.data?.data?.t2
                      ?.slice(6, 19)
                      ?.map((item, index) => (
                        <div
                          className="card-odd-box ng-tns-c1798067373-0 ng-star-inserted"
                          key={index}
                        >
                          {item.gstatus == 0 ? (
                            <div className="ng-tns-c1798067373-0 suspended-box">
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                  CARD_ARRAY[index]
                                }.webp`}
                                alt={`Card ${CARD_ARRAY[index]}`}
                              />
                            </div>
                          ) : (
                            <div
                              className="ng-tns-c1798067373-0"
                              onClick={() => {
                                handleModal(
                                  item?.rate,
                                  item?.nation,
                                  index + 7
                                );
                              }}
                            >
                              <img
                                className="ng-tns-c1798067373-0 ng-star-inserted"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                  CARD_ARRAY[index]
                                }.webp`}
                                alt={`Card ${CARD_ARRAY[index]}`}
                              />
                            </div>
                          )}

                          <div className="casino-nation-book ng-tns-c1798067373-0">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === index + 7 && (
                                  <span
                                    className={` ${
                                      ele.number == index + 7 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == index + 7 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="ng-tns-c1798067373-2">
                <div className="casino-last-result-title ng-tns-c1798067373-2">
                  <span className="ng-tns-c1798067373-2">Last Result</span>
                  <span className="ng-tns-c1798067373-2">
                    <a className="ng-tns-c1798067373-2">View All</a>
                  </span>
                </div>
                <div className="casino-last-results ng-tns-c1798067373-2 ng-star-inserted">
                  {results?.data?.result?.map((result, index) => {
                    const buttonValue = reqRes?.data?.data?.t2.find(
                      (item) => item.sid === result?.result
                    );

                    return (
                      <span
                        className="ng-tns-c1798067373-0 result result-b ng-star-inserted"
                        key={index}
                      >
                        <span
                          className="ng-tns-c1798067373-0"
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                          }}
                          style={{ 
                            color:
                              result?.result === "1" ? "#ff4500" : "inherit",
                          }}
                        >
                          {buttonValue && (
                            <>
                              {buttonValue.nat[buttonValue.nat.length - 1] ==
                              "t"
                                ? "B"
                                : "A"}
                            </>
                          )}
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
           ) : (
            <CasinoPlacedBet bets={history} />
          )}
          <div className="ng-tns-c1798067373-2 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c1798067373-2">
              <table className="table ng-tns-c1798067373-2">
                <thead className="ng-tns-c1798067373-2">
                  <tr className="ng-tns-c1798067373-2">
                    <th className="ng-tns-c1798067373-2">Matched Bet</th>
                    <th className="text-end ng-tns-c1798067373-2">Odds</th>
                    <th className="text-end ng-tns-c1798067373-2">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CasinoBetModal
                id={id}
                name={name}
                modalOpen={showModal}
                betDetails={betDetails}
                setbetDetails={setbetDetails}
                setShowModal={setShowModal}
                betHistory={history}
                realTime={realTime}
                setRealTimeData={setRealTimeData}
              />
    </div>
  );
}
