import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../service/API";
import { logoutUser } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";

export default function AccountWalletStatement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [type, setType] = React.useState("ALL");
  const [leftreferralApi, setLeftReferralApi] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const userId = user?.data?.resultid;

  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default to 10 items per page
  const [noOfPages, setNoOfPages] = useState(0); // To store the total number of pages

  const referral_API = async () => {
    try {
      let responseRight = await API?.post(
        `Get_AccountStatementRecordUser`,
        {
          UserId: userId,
          username: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const accountData = responseRight?.data?.data[0];
      setLeftReferralApi(accountData);

      // Set the number of pages based on data length and rowsPerPage
      setNoOfPages(Math.ceil(accountData.length / rowsPerPage));
    } catch (error) {
      console.log("Error While calling API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const isMobileView = window.innerWidth < 768;

  // Get the current page data
  const currentPageData = leftreferralApi.slice(
    pageNumber * rowsPerPage,
    (pageNumber + 1) * rowsPerPage
  );

  return (
    <div
      style={{
        width: isMobileView ? "100%" : "82%",
      }}
      className=" report-container"
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Account Statement</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          <div className="row row5">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={6}
                  className="table b-table table-bordered"
                >
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col" className="text-center">
                        Date
                      </th>
                      <th role="columnheader" scope="col" className="text-right">
                        Credit
                      </th>
                      <th role="columnheader" scope="col" className="text-right">
                        Debit
                      </th>
                      <th role="columnheader" scope="col" className="text-right">
                        Balance
                      </th>
                      <th role="columnheader" scope="col" className="text-center">
                        Remark
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {currentPageData.map((item, index) => (
                      <tr role="row" tabIndex={0} key={index}>
                        <td role="cell" className="text-center">
                          <span>{item?.Edate1}</span>
                        </td>
                        <td role="cell" className="text-right">
                          <span className="text-success">{item?.InAmount}</span>
                        </td>
                        <td role="cell" className="text-right">
                          <span className="text-danger">{item?.OutAmount}</span>
                        </td>
                        <td role="cell" className="text-right">
                          <span>{item?.Balance?.toFixed(2)}</span>
                        </td>
                        <td role="cell" className="text-center">
                          {item?.Narration}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12">
              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                className="pagination mb-0 b-pagination justify-content-center"
              >
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <button
                    className="page-link"
                    onClick={() => setPageNumber(0)}
                    disabled={pageNumber === 0}
                  >
                    First
                  </button>
                </li>
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <button
                    className="page-link"
                    onClick={() => setPageNumber((prev) => prev - 1)}
                    disabled={pageNumber === 0}
                  >
                    Prev
                  </button>
                </li>

                {Array.from(Array(noOfPages).keys()).map((item) => (
                  <li
                    key={item}
                    className={`page-item ${pageNumber === item ? "active" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setPageNumber(item)}
                    >
                      {item + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    pageNumber === noOfPages - 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setPageNumber((prev) => prev + 1)}
                    disabled={pageNumber === noOfPages - 1}
                  >
                    Next
                  </button>
                </li>
                <li
                  className={`page-item ${
                    pageNumber === noOfPages - 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setPageNumber(noOfPages - 1)}
                    disabled={pageNumber === noOfPages - 1}
                  >
                    Last
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
