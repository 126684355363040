import { combineReducers,configureStore } from "@reduxjs/toolkit";
import gameSlice from "./features/gameSlice.js";
import accountSlice from "./features/accountSlice.js";
import ipReducer from "./features/ipSlice.js";
import authReducer from "./features/authSlice.js";
import fetchMatchNameReducer from "./features/matchNameSlice.js";
import SaveTeamsReducer from "./features/matchNameSlice";
import { persistReducer, persistStore } from "redux-persist";
import { AuthInterpertor } from "./middleware/authMiddleware";
import storageSession from "redux-persist/lib/storage/session";
import fetchBetButtonreducer  from "./features/betButtonSlice.js";
import betStackReducer from "./features/betStackSlice.js";
import sessionStackReducer from "./features/sessionStackSlice.js";
import dashbetHistoryReducer from "./features/dashbetHistorySlice.js";
import LastbetReducer from "./features/LastbetSlice.js";
import calculateProfitReducer from "./features/calculateProfitSlice.js";
import betHistoryReducer from "./features/betHistorySlice";
import updatePassReducer from "./features/updatePassSlice";
import thirtyTwoCardGameReducer from "./features/thirtyTwoCardGameSlice";
import casinobetReducer from "./features/casinobetSlice";

export const saveState = (state) => {
  try {
    const accountState = {
      account: state.account,
   
    };
    const serializedState = JSON.stringify(accountState);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.log(err);
  }
};

// export const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem("state");
//     if (serializedState === null) {
//       return undefined;
//     }
//     return JSON.parse(serializedState);
//   } catch (err) {
//     return undefined;
//   }
// };

// const persistedState = loadState();
// export default configureStore({
//   reducer: {
//     gameData: gameSlice,
//     account: accountSlice,
//   },
//   preloadedState: persistedState,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authReducer,
  ip: ipReducer,
  betHistory: betHistoryReducer,
  account: accountSlice,
  gameData: gameSlice,
  matchInfo: fetchMatchNameReducer,
  betStack: betStackReducer,
  userProfit: calculateProfitReducer,
  lastbetDetails: LastbetReducer,
  sessionStack: sessionStackReducer,
  dashbetHistory: dashbetHistoryReducer,
  saveTeams: SaveTeamsReducer,
  buttonData:fetchBetButtonreducer,
  updatePass: updatePassReducer,

  // lossProfit: lossProfitReducer,

  // walletLogin: walletLoginReducer,
  // accountStatement: accountStatementReducer,
 
  // placeBet: placeColorBetReducer,
  // depositHistory: depositHistoryReducer,

  // unsellectedBet: unsellectedBetReducer,
  // betawallet: betaWalletReducer,
 
  // userBalanceData: userBalanceReducer,
  // withdrawHistory: withdrawHistoryReducer,

  // iframeDetails: IframeReducer,
  casinobet: casinobetReducer,
  // casinobetHistory: casinobetHistoryReducer,
  // competition: competitionReducer,
  // rulesDetails: rulesReducer,
  // competitionwise: competionwiseReducer,
  thirtyTwoCardGameStack: thirtyTwoCardGameReducer,
  // casinoResultScheduler: resultSchedulerReducer,
  
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([AuthInterpertor]),
});



export const persistor = persistStore(store);
