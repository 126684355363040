/* eslint-disable react-hooks/rules-of-hooks */
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Authenticate, loginUser, logoutUser } from "../redux/features/authSlice";
import toast from "react-hot-toast";
import {fetchIP, selectIP} from "../redux/features/ipSlice"

export default function PublicOutletL(props) {
  // const userData = useSelector((state) => state.account.userData);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ip = useSelector(selectIP);
  const { user, isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchIP());
  }, [dispatch]);

  const login = async (e) => {
    e.preventDefault();

    dispatch(
      loginUser({
        isAuth: false,
        username: userName,
        password: password,
        ipaddress: ip,
      })
    );
  };
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  
  useEffect(() => {
    if (user) {
      let responce = user?.data?.result;
      if (responce === "Successfull") {
        if (user.data.loginstatus === "0") {
          setPassword(true);
          // setModalData({ ...user.data, token: user?.token });
        } else {
          toast.success("Logged In Successfully");
          let userdata = user.data.resultid;
          let token = user.token;
          let username = user.data.resultusername;
          // console.log("token", token);
          sessionStorage.setItem("token", token);
          dispatch(Authenticate());
          console.log("userdataID", userdata);
          sessionStorage.setItem("userData", user.data.resultid);
          console.log("userdataname", user);
          sessionStorage.setItem("userName", user.data.resultusername);
          // dispatch(fetchuserBalance({ id: user.data.resultid, token }));
          // dispatch(fetchBetButton({ id: user.data.resultid, token }));

          navigate("/home");
        }
        // console.log("loggedin");
      } else {
        // alert(responce);
        toast.error(responce);
        dispatch(logoutUser());
      }
    }
  }, [user]);


  return (
    <div className="wrapper">
      <div className="login-page">
        <div className="login-box">
          <div className="logo-login">
            <a href="/">
              <img src="/logo.png" />
            </a>
          </div>
          <div className="login-form mt-4">
            <h4 className="text-center login-title">
              Login <i className="fas fa-hand-point-down" />
            </h4>
            <form
              onSubmit={login}
            >
              <div className="mb-4 input-group position-relative">
                <input
                  name="username"
                  type="text"
                  className="form-control"
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Username"
                />
                <span className="input-group-text">
                  <i className="fas fa-user" />
                </span>
              </div>
              <div className="mb-4 input-group position-relative">
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <span className="input-group-text">
                  <i className="fas fa-key" />
                </span>
              </div>
              <div className="d-grid">
                <button
                  // onClick={login}
                  onHandleKeyDown={handleKeyDown}
                  className="btn btn-primary btn-block"
                >
                  Login
                  <i className="fas fa-sign-in-alt float-end mt-1" />
                </button>
                {/*  <button
                  type="button"
                  className="btn btn-primary btn-block mt-2"
                >
                  Login with demo ID
                  <i className="fas fa-sign-in-alt float-end mt-1" />
                </button> */}
              </div>
              <small className="recaptchaTerms mt-1">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{" "}
                and
                <a href="https://policies.google.com/terms">
                  Terms of Service
                </a>{" "}
                apply.
              </small>
              <p className="mt-1" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
