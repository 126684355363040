import axios from "axios";
import { API } from "../../service/API";

const unsellectedBetAPI = axios.create();

export const fetchUnsellectedBet = async (data, token) => {
  try {
    const response = await API.post('getUnSetteledBet', data, {
      headers: {
        Authorization: token,
      },
    });
    console.log("unsellectedBetAPI", response );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default unsellectedBetAPI;
