import React from "react";
import "./footer.css";
export default function index() {
  return (
    <>
      <section className="footer">
        <div className="footer-top">
          <div className="footer-links">
            <nav className="navbar navbar-expand-sm">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/terms-and-conditions"
                    target="_blank"
                  >
                    {" "}
                    Terms and Conditions{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/responsible-gaming"
                    target="_blank"
                  >
                    {" "}
                    Responsible Gaming{" "}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="support-detail">
            <h2>24X7 Support</h2>
            <p />
          </div>
          <div className="social-icons-box" />
        </div>
      </section>
      <div className="footer-bottom">
        <div className="secure-logo">
          <div>
            <img src="https://wver.sprintstaticdata.com/v3/static/front/img/ssl.png" />
          </div>
          <div className="ml-2">
            <b>100% SAFE</b>
            <div>Protected connection and encrypted data.</div>
          </div>
        </div>
        <div className="d-inline-block">
          <button className="btn p-0">
            <img src="https://versionobj.ecoassetsservice.com/v15/static/front/img/18plus.png" />
          </button>
          <a href="https://www.gamcare.org.uk/" target="_blank">
            <img src="https://versionobj.ecoassetsservice.com/v15/static/front/img/gamecare.png" />
          </a>
          <a href="https://www.gamblingtherapy.org/" target="_blank">
            <img src="https://versionobj.ecoassetsservice.com/v15/static/front/img/gt.png" />
          </a>
        </div>
      </div>
    </>
  );
}
