import React from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
export default function CasinoWar() {
  return (
    
        <div
          _ngcontent-ng-c3502172659
          className="center-main-container casino-page 0 ng-star-inserted"
        >
          <div _ngcontent-ng-c3502172659 className="center-container 0">
            <div
              _ngcontent-ng-c3502172659
              className="casino-page-container 0 casino-war"
            >
              <div
                _ngcontent-ng-c3502172659
                className="casino-title-header-mobile d-xl-none 0"
              >
                <ul
                  _ngcontent-ng-c3502172659
                  className="nav nav-tabs menu-tabs 0"
                >
                  <li _ngcontent-ng-c3502172659 className="nav-item 0">
                    <div
                      _ngcontent-ng-c3502172659
                      className="0 active nav-link"
                    >
                      Game
                    </div>
                  </li>
                  <li _ngcontent-ng-c3502172659 className="nav-item 0">
                    <div _ngcontent-ng-c3502172659 className="0 nav-link">
                      {" "}
                      Placed Bet (0){" "}
                    </div>
                  </li>
                </ul>
                <div _ngcontent-ng-c3502172659 className="pe-2 0">
                  <span _ngcontent-ng-c3502172659 className="casino-rid 0">
                    <a _ngcontent-ng-c3502172659 className="d-block 0">
                      <small _ngcontent-ng-c3502172659 className="0">
                        Rules
                      </small>
                    </a>{" "}
                    Round ID:{" "}
                    <span
                      _ngcontent-ng-c3502172659
                      className="0 ng-star-inserted"
                    >
                      31.240907165750
                    </span>
                    {/**/}
                  </span>
                </div>
              </div>
              <div _ngcontent-ng-c3502172659 className="casino-header 0">
                <span _ngcontent-ng-c3502172659 className="casino-name 0">
                  {" "}
                  Casino War
                  <a
                    _ngcontent-ng-c3502172659
                    className="ms-1 d-xl-inline d-none 0"
                  >
                    <small _ngcontent-ng-c3502172659 className="0">
                      Rules
                    </small>
                  </a>
                </span>
                <span
                  _ngcontent-ng-c3502172659
                  className="casino-rid d-none d-xl-inline-block 0"
                >
                  <small _ngcontent-ng-c3502172659 className="0">
                    Round ID:{" "}
                    <span
                      _ngcontent-ng-c3502172659
                      className="0 ng-star-inserted"
                    >
                      31.240907165750
                    </span>
                    {/**/}
                  </small>
                  <span
                    _ngcontent-ng-c3502172659
                    className="0 ng-star-inserted"
                  >
                    {" "}
                    | Min: 100 {/**/}
                    {/**/} | Max: 100000 {/**/}
                    {/**/}
                  </span>
                  {/**/}
                </span>
              </div>
              {/**/}
              {/**/}
              <div _ngcontent-ng-c3502172659 className="0 ng-star-inserted">
                <div _ngcontent-ng-c3502172659 className="casino-video 0">
                  <div
                    _ngcontent-ng-c3502172659
                    className="video-box-container 0"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-video-box 0 ng-star-inserted"
                    >
                      <iframe
                        _ngcontent-ng-c3502172659
                        className="0"
                        src={CASINO_VIDEO_URL + CASINO_VIDEO.casinoWar}
                      />
                    </div>
                    {/**/}
                  </div>
                  <div
                    _ngcontent-ng-c3502172659
                    className="0 ng-star-inserted"
                  >
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-video-cards 0 ng-star-inserted"
                    >
                      <h5 _ngcontent-ng-c3502172659 className="0">
                        Dealer
                      </h5>
                      <div
                        _ngcontent-ng-c3502172659
                        className="flip-card-container 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="me-lg-2 me-1 0 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c3502172659
                            className="0"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/ASS.webp"
                          />
                        </div>
                        {/**/}
                      </div>
                    </div>
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                    {/**/}
                  </div>
                  {/**/}
                  <div _ngcontent-ng-c3502172659 className="clock 0">
                    <div
                      _ngcontent-ng-c3502172659
                      className="count-no 0 ng-star-inserted"
                    >
                      <span _ngcontent-ng-c3502172659 className="0">
                        <div _ngcontent-ng-c3502172659 className="content 0">
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="0"
                          >
                            <div _ngcontent-ng-c3502172659 className="flip 0">
                              <div _ngcontent-ng-c3502172659 className="up 0">
                                <div _ngcontent-ng-c3502172659 className="0">
                                  0
                                </div>
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="down 0"
                              >
                                <div _ngcontent-ng-c3502172659 className="0">
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0"
                                  >
                                    0
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="flip-card-inner 0 ng-trigger ng-trigger-flip"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-front 0"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="up 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      0
                                    </div>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-back 0"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="down 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0"
                                      >
                                        0
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    {/**/}
                    <div
                      _ngcontent-ng-c3502172659
                      className="count-no 0 ng-star-inserted"
                      style={{ marginRight: "80px" }}
                    >
                      <span _ngcontent-ng-c3502172659 className="0">
                        <div _ngcontent-ng-c3502172659 className="content 0">
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="0"
                          >
                            <div _ngcontent-ng-c3502172659 className="flip 0">
                              <div _ngcontent-ng-c3502172659 className="up 0">
                                <div _ngcontent-ng-c3502172659 className="0">
                                  0
                                </div>
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="down 0"
                              >
                                <div _ngcontent-ng-c3502172659 className="0">
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0"
                                  >
                                    0
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            style={{ position: "absolute" }}
                            className="0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="flip-card-inner 0 ng-trigger ng-trigger-flip"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-front 0"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="up 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      0
                                    </div>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="flip-card-back 0"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="down 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0"
                                      >
                                        0
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>
                    {/**/}
                  </div>
                </div>
                {/**/}
                {/**/}
                <div _ngcontent-ng-c3502172659 className="casino-detail 0">
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  <div
                    _ngcontent-ng-c3502172659
                    className="casino-table 0 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-table-header w-100 0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-nation-detail 0"
                      />
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-odds-box 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="flip-card 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="flip-card-inner 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card-front 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/QCC.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-odds-box 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="flip-card 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="flip-card-inner 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card-front 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9CC.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-odds-box 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="flip-card 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="flip-card-inner 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card-front 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6DD.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-odds-box 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="flip-card 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="flip-card-inner flip 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card-front 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2CC.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-odds-box 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="flip-card 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="flip-card-inner 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card-front 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8HH.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-odds-box 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="flip-card 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="flip-card-inner 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="flip-card-front 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7SS.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/**/}
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-table-full-box d-none d-md-block 0"
                    >
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-table-header 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-nation-detail 0"
                        />
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-odds-box 0"
                        >
                          1
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-odds-box 0"
                        >
                          2
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-odds-box 0"
                        >
                          3
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-odds-box 0"
                        >
                          4
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-odds-box 0"
                        >
                          5
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-odds-box 0"
                        >
                          6
                        </div>
                      </div>
                      <div
                        _ngcontent-ng-c3502172659
                        className="casino-table-body 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <span _ngcontent-ng-c3502172659 className="0">
                                Winner
                              </span>
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar12"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar12"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar12"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.98
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.98
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.98
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.98
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.98
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.98
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <div _ngcontent-ng-c3502172659 className="0">
                                <span _ngcontent-ng-c3502172659 className="0">
                                  Black
                                </span>
                                <span
                                  _ngcontent-ng-c3502172659
                                  className="card-icon ms-1 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="card-icon ms-1 0"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="card-black 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                      />
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="card-icon ms-1 0"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="card-black 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                      />
                                    </span>
                                  </span>
                                </span>
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar22"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar22"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar22"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <div _ngcontent-ng-c3502172659 className="0">
                                <span _ngcontent-ng-c3502172659 className="0">
                                  Red
                                </span>
                                <span
                                  _ngcontent-ng-c3502172659
                                  className="card-icon ms-1 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="card-red 0"
                                  >
                                    <img
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                    />
                                  </span>
                                </span>
                                <span
                                  _ngcontent-ng-c3502172659
                                  className="card-icon ms-1 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="card-red 0"
                                  >
                                    <img
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                    />
                                  </span>
                                </span>
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar32"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar32"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar32"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.97
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <span _ngcontent-ng-c3502172659 className="0">
                                Odds
                              </span>
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar42"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar42"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar42"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              1.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <span _ngcontent-ng-c3502172659 className="0">
                                Even
                              </span>
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar52"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar52"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar52"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              2.12
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              2.12
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              2.12
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              2.12
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              2.12
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              2.12
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar62"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar62"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar62"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar72"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar72"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar72"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar82"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar82"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar82"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="casino-table-row 0"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-nation-detail 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="casino-nation-name 0"
                            >
                              <img
                                _ngcontent-ng-c3502172659
                                className="0"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                              />
                              <div
                                _ngcontent-ng-c3502172659
                                className="min_max_collapse 0"
                              >
                                <a
                                  _ngcontent-ng-c3502172659
                                  data-bs-toggle="collapse"
                                  href="#collapserangewar92"
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapserangewar92"
                                  className="minmax-btn collapsed 0"
                                >
                                  <i
                                    _ngcontent-ng-c3502172659
                                    className="fas fa-info-circle 0"
                                  />
                                </a>
                                <div
                                  _ngcontent-ng-c3502172659
                                  id="collapserangewar92"
                                  className="range-collpase collapse 0"
                                >
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-0 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Min:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100
                                    </span>
                                  </span>
                                  <span
                                    _ngcontent-ng-c3502172659
                                    className="min_max_span ms-1 0"
                                  >
                                    <b
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      Max:
                                    </b>
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="0"
                                    >
                                      100000
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          <div
                            _ngcontent-ng-c3502172659
                            className="0 back casino-odds-box suspended-box ng-star-inserted"
                          >
                            <span
                              _ngcontent-ng-c3502172659
                              className="casino-odds 0"
                            >
                              {" "}
                              3.83
                            </span>
                            <p _ngcontent-ng-c3502172659 className="0">
                              0
                            </p>
                          </div>
                          {/**/}
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-table-full-box d-md-none 0"
                    >
                      <ul
                        _ngcontent-ng-c3502172659
                        className="menu-tabs d-xl-none nav nav-tabs 0"
                      >
                        <li _ngcontent-ng-c3502172659 className="nav-item 0">
                          <button
                            _ngcontent-ng-c3502172659
                            type="button"
                            className="0 active nav-link"
                          >
                            {" "}
                            1{" "}
                          </button>
                        </li>
                        <li _ngcontent-ng-c3502172659 className="nav-item 0">
                          <button
                            _ngcontent-ng-c3502172659
                            type="button"
                            className="0 nav-link"
                          >
                            {" "}
                            2{" "}
                          </button>
                        </li>
                        <li _ngcontent-ng-c3502172659 className="nav-item 0">
                          <button
                            _ngcontent-ng-c3502172659
                            type="button"
                            className="0 nav-link"
                          >
                            {" "}
                            3{" "}
                          </button>
                        </li>
                        <li _ngcontent-ng-c3502172659 className="nav-item 0">
                          <button
                            _ngcontent-ng-c3502172659
                            type="button"
                            className="0 nav-link"
                          >
                            {" "}
                            4{" "}
                          </button>
                        </li>
                        <li _ngcontent-ng-c3502172659 className="nav-item 0">
                          <button
                            _ngcontent-ng-c3502172659
                            type="button"
                            className="0 nav-link"
                          >
                            {" "}
                            5{" "}
                          </button>
                        </li>
                        <li _ngcontent-ng-c3502172659 className="nav-item 0">
                          <button
                            _ngcontent-ng-c3502172659
                            type="button"
                            className="0 nav-link"
                          >
                            {" "}
                            6{" "}
                          </button>
                        </li>
                      </ul>
                      <div
                        _ngcontent-ng-c3502172659
                        className="tab-content 0"
                      >
                        <div
                          _ngcontent-ng-c3502172659
                          className="0 active fade show tab-pane"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-table-body 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="row row5 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Winner
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10291"
                                          aria-controls="collapserange2m10291"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10291"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.98
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Black
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10292"
                                          aria-controls="collapserange2m10292"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10292"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Red
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10293"
                                          aria-controls="collapserange2m10293"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10293"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Odds
                                      </span>
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10294"
                                          aria-controls="collapserange2m10294"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10294"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Even
                                      </span>
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10295"
                                          aria-controls="collapserange2m10295"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10295"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      2.12
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10296"
                                          aria-controls="collapserange2m10296"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10296"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10297"
                                          aria-controls="collapserange2m10297"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10297"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10298"
                                          aria-controls="collapserange2m10298"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10298"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                                      />
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m10299"
                                          aria-controls="collapserange2m10299"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m10299"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="0 fade show tab-pane"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-table-body 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="row row5 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Winner
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102911"
                                          aria-controls="collapserange2m102911"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102911"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.98
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Black
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102912"
                                          aria-controls="collapserange2m102912"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102912"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Red
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102913"
                                          aria-controls="collapserange2m102913"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102913"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Odds
                                      </span>
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102914"
                                          aria-controls="collapserange2m102914"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102914"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Even
                                      </span>
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102915"
                                          aria-controls="collapserange2m102915"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102915"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      2.12
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102916"
                                          aria-controls="collapserange2m102916"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102916"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102917"
                                          aria-controls="collapserange2m102917"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102917"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102918"
                                          aria-controls="collapserange2m102918"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102918"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                                      />
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102919"
                                          aria-controls="collapserange2m102919"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102919"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="0 fade show tab-pane"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-table-body 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="row row5 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Winner
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102921"
                                          aria-controls="collapserange2m102921"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102921"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.98
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Black
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102922"
                                          aria-controls="collapserange2m102922"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102922"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Red
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102923"
                                          aria-controls="collapserange2m102923"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102923"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Odds
                                      </span>
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102924"
                                          aria-controls="collapserange2m102924"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102924"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Even
                                      </span>
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102925"
                                          aria-controls="collapserange2m102925"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102925"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      2.12
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102926"
                                          aria-controls="collapserange2m102926"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102926"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102927"
                                          aria-controls="collapserange2m102927"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102927"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102928"
                                          aria-controls="collapserange2m102928"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102928"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                                      />
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102929"
                                          aria-controls="collapserange2m102929"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102929"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="0 fade show tab-pane"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-table-body 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="row row5 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Winner
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102931"
                                          aria-controls="collapserange2m102931"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102931"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.98
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Black
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102932"
                                          aria-controls="collapserange2m102932"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102932"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Red
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102933"
                                          aria-controls="collapserange2m102933"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102933"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Odds
                                      </span>
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102934"
                                          aria-controls="collapserange2m102934"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102934"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Even
                                      </span>
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102935"
                                          aria-controls="collapserange2m102935"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102935"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      2.12
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102936"
                                          aria-controls="collapserange2m102936"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102936"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102937"
                                          aria-controls="collapserange2m102937"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102937"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102938"
                                          aria-controls="collapserange2m102938"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102938"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                                      />
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102939"
                                          aria-controls="collapserange2m102939"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102939"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="0 fade show tab-pane"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-table-body 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="row row5 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Winner
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102941"
                                          aria-controls="collapserange2m102941"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102941"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.98
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Black
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102942"
                                          aria-controls="collapserange2m102942"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102942"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Red
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102943"
                                          aria-controls="collapserange2m102943"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102943"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Odds
                                      </span>
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102944"
                                          aria-controls="collapserange2m102944"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102944"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Even
                                      </span>
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102945"
                                          aria-controls="collapserange2m102945"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102945"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      2.12
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102946"
                                          aria-controls="collapserange2m102946"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102946"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102947"
                                          aria-controls="collapserange2m102947"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102947"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102948"
                                          aria-controls="collapserange2m102948"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102948"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                                      />
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102949"
                                          aria-controls="collapserange2m102949"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102949"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c3502172659
                          className="0 fade show tab-pane"
                        >
                          <div
                            _ngcontent-ng-c3502172659
                            className="casino-table-body 0"
                          >
                            <div
                              _ngcontent-ng-c3502172659
                              className="row row5 0"
                            >
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Winner
                                      </span>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102951"
                                          aria-controls="collapserange2m102951"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102951"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.98
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Black
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-black 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102952"
                                          aria-controls="collapserange2m102952"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102952"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="0"
                                        >
                                          Red
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                            />
                                          </span>
                                        </span>
                                        <span
                                          _ngcontent-ng-c3502172659
                                          className="card-icon ms-1 0"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="card-red 0"
                                          >
                                            <img
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                            />
                                          </span>
                                        </span>
                                      </div>
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102953"
                                          aria-controls="collapserange2m102953"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102953"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.97
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Odds
                                      </span>
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102954"
                                          aria-controls="collapserange2m102954"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102954"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      1.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <span
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                      >
                                        Even
                                      </span>
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102955"
                                          aria-controls="collapserange2m102955"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102955"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      2.12
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                              <div
                                _ngcontent-ng-c3502172659
                                className="col-6 0"
                              >
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/spade2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102956"
                                          aria-controls="collapserange2m102956"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102956"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/club2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102957"
                                          aria-controls="collapserange2m102957"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102957"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/heart2d.webp"
                                      />
                                      {/**/}
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102958"
                                          aria-controls="collapserange2m102958"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102958"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                <div
                                  _ngcontent-ng-c3502172659
                                  className="casino-table-row 0 ng-star-inserted"
                                >
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="casino-nation-detail 0"
                                  >
                                    <div
                                      _ngcontent-ng-c3502172659
                                      className="casino-nation-name 0"
                                    >
                                      {/**/}
                                      {/**/}
                                      {/**/}
                                      <img
                                        _ngcontent-ng-c3502172659
                                        className="0 ng-star-inserted"
                                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/diamond2d.webp"
                                      />
                                      {/**/}
                                      <div
                                        _ngcontent-ng-c3502172659
                                        className="min_max_collapse 0"
                                      >
                                        <a
                                          _ngcontent-ng-c3502172659
                                          data-bs-toggle="collapse"
                                          role="button"
                                          aria-expanded="false"
                                          className="minmax-btn collapsed 0"
                                          href="#collapserange2m102959"
                                          aria-controls="collapserange2m102959"
                                        >
                                          <i
                                            _ngcontent-ng-c3502172659
                                            className="fas fa-info-circle 0"
                                          />
                                        </a>
                                        <div
                                          _ngcontent-ng-c3502172659
                                          className="range-collpase collapse 0"
                                          id="collapserange2m102959"
                                        >
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-0 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Min:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              {" "}
                                              100 {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                          <span
                                            _ngcontent-ng-c3502172659
                                            className="min_max_span ms-1 0"
                                          >
                                            <b
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              Max:
                                            </b>
                                            <span
                                              _ngcontent-ng-c3502172659
                                              className="0"
                                            >
                                              <span
                                                _ngcontent-ng-c3502172659
                                                className="0 ng-star-inserted"
                                              >
                                                {" "}
                                                25000{" "}
                                              </span>
                                              {/**/}
                                              {/**/}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    _ngcontent-ng-c3502172659
                                    className="0 back casino-odds-box suspended-box"
                                  >
                                    <span
                                      _ngcontent-ng-c3502172659
                                      className="casino-odds 0"
                                    >
                                      3.83
                                    </span>
                                  </div>
                                </div>
                                {/**/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                  <div _ngcontent-ng-c3502172659 className="0">
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-last-result-title 0"
                    >
                      <span _ngcontent-ng-c3502172659 className="0">
                        Last Result
                      </span>
                      <span _ngcontent-ng-c3502172659 className="0">
                        <a _ngcontent-ng-c3502172659 className="0">
                          View All
                        </a>
                      </span>
                    </div>
                    <div
                      _ngcontent-ng-c3502172659
                      className="casino-last-results 0 ng-star-inserted"
                    >
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c3502172659
                        className="0 result result-b ng-star-inserted"
                      >
                        <span _ngcontent-ng-c3502172659 className="0">
                          {" "}
                          R
                        </span>
                      </span>
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                      {/**/}
                    </div>
                    {/**/}
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c3502172659
                className="0 d-none matchbettable w-100 ng-star-inserted"
              >
                <div _ngcontent-ng-c3502172659 className="table-responsive 0">
                  <table _ngcontent-ng-c3502172659 className="table 0">
                    <thead _ngcontent-ng-c3502172659 className="0">
                      <tr _ngcontent-ng-c3502172659 className="0">
                        <th _ngcontent-ng-c3502172659 className="0">
                          Matched Bet
                        </th>
                        <th _ngcontent-ng-c3502172659 className="text-end 0">
                          Odds
                        </th>
                        <th _ngcontent-ng-c3502172659 className="text-end 0">
                          Stake
                        </th>
                      </tr>
                    </thead>
                    {/**/}
                  </table>
                </div>
              </div>
              {/**/}
              {/**/}
            </div>
            {/**/}
          </div>
          <div
            _ngcontent-ng-c3502172659
            className="sidebar right-sidebar casino-right-sidebar 0"
          >
            <div
              _ngcontent-ng-c3502172659
              className="sidebar-box place-bet-container d-xl-block d-none 0"
            >
              {/**/}
              <div _ngcontent-ng-c3502172659 className="sidebar-title 0">
                <h4 _ngcontent-ng-c3502172659 className="0">
                  Place Bet
                </h4>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c3502172659
              className="sidebar-box my-bet-container d-xl-block d-none 0"
            >
              <div _ngcontent-ng-c3502172659 className="sidebar-title 0">
                <h4 _ngcontent-ng-c3502172659 className="0">
                  My Bet
                </h4>
                <a _ngcontent-ng-c3502172659 className="sideviewmore 0">
                  View more
                </a>
              </div>
              <div _ngcontent-ng-c3502172659 className="my-bets 0">
                <div
                  _ngcontent-ng-c3502172659
                  className="table-responsive w-100 0"
                >
                  <table _ngcontent-ng-c3502172659 className="table 0">
                    <thead _ngcontent-ng-c3502172659 className="0">
                      <tr _ngcontent-ng-c3502172659 className="0">
                        <th _ngcontent-ng-c3502172659 className="0">
                          Matched Bet
                        </th>
                        <th _ngcontent-ng-c3502172659 className="text-end 0">
                          Odds
                        </th>
                        <th _ngcontent-ng-c3502172659 className="text-end 0">
                          Stake
                        </th>
                      </tr>
                    </thead>
                    {/**/}
                  </table>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c3502172659
              className="sidebar-box sidebar-box-rules my-bet-container 0"
            >
              <app-casino-game-rules
                _ngcontent-ng-c3502172659
                className="0"
                _nghost-ng-c1761710207
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </app-casino-game-rules>
            </div>
          </div>
        </div>
      
    
  );
}
