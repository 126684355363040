import moment from "moment";
import React, { useEffect, useState } from "react";
import { getProfitLossReportAPI } from "../../service/AuthService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchLossProfit } from "../../redux/api/lossProfitAPI";

export default function ProfitLoss() {
  // const [fromDate, setFromDate] = React.useState(
  //   moment().subtract(7, "days").format("YYYY-MM-DD")
  // );
  // const userData = useSelector((state) => state.account.userData);
  // const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));
  // const [data, setData] = React.useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const username = user?.data?.resultid;
  const userId = user?.data?.resultid;
  const uid = user?.data?.resultid;

  // const invalidToken = useSelector((state) => state.lossProfit.invalidToken);
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  // const [currentPageData, setCurrentPageData] = useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [noOfPages, setNoOfPages] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, setTableData] = useState([]);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  useEffect(() => {
    // const token = sessionStorage.getItem("token");
    // const uid = sessionStorage.getItem("userData");

    const fetchData = async () => {
      try {
        const response = await fetchLossProfit(
          uid,
          fromdate,
          todate,
          token,
          username
        );
        console.log("profitloss", response);
        setNoOfPages(Math.ceil(response.length / rowsPerPage));

        setTableData(response);
      } catch (error) {
        console.error("Error fetching profit/loss data:", error);
      }
    };
    fetchData();
  }, [fromdate, todate]);

  const handleSearch = async () => {
    try {
      // const token = sessionStorage.getItem("token");
      // const userId = sessionStorage.getItem("userData");
      const response = await fetchLossProfit(
        userId,
        fromdate,
        todate,
        token,
        username
      );
      setTableData(response);
    } catch (error) {
      console.error("Error fetching profit/loss data:", error);
    }
  };

  const currentPageData = tableData.slice(
    pageNumber * rowsPerPage,
    (pageNumber + 1) * rowsPerPage
  );

  const isMobile = window.innerWidth < 786;
  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
      }}
      className={` m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Profit Loss</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          {/* <div className="row row5">
            <div className={isMobile ? "col-12" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  // value={fromDate}
                  // onChange={(e) => setFromDate(e.target.value)}
                  value={fromdate}
                  onChange={(e) => setfromdate(e.target.value)}
                  placeholder="Select start date"
                />
              </div>
            </div>
            <div className={isMobile ? "col-12 mt-2" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  // value={toDate}
                  placeholder="Select end date"    
                  value={todate}
                  onChange={(e) => settodate(e.target.value)}
                  // onChange={(e) => setToDate(e.target.value)}
                  
                />
              </div>
            </div>
            <div
              // onClick={handleSubmit}
              // onClick={() => handleSearch()}

              className={isMobile ? "col-12 mt-2" : "col-1"}
            >
              <button className="btn btn-primary btn-block">Submit</button>
            </div>
          </div> */}

          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={3}
                  className="table b-table table-striped table-bordered"
                  id="__BVID__109"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-center"
                      >
                        Event Type
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Event Name
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-center"
                      >
                        Match
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={4}
                        className="text-right"
                      >
                        Amount
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={5}
                        className="text-center"
                      >
                        Win Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {currentPageData?.map((item) => (
                      <tr role="row" className="">
                        <td role="cell" className="text-center">
                          {item?.team}
                        </td>
                        <td role="cell" className="text-center">
                          {item?.remark}
                        </td>
                        <td role="cell" className="text-center">
                          {item?.name}
                        </td>
                        <td
                          role="cell"
                          className={`text-right ${
                            item?.amount < 0 ? "text-danger" : "text-success"
                          }`}
                        >
                          {item?.amount}
                        </td>
                        <td role="cell" className="text-center">
                          {item?.winamount}
                        </td>
                        {/* <td role="cell" className="text-center">
                          {item?.name}
                        </td> */}
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
          <div className="col-12">
              <ul
                role="menubar"
                aria-disabled="false"
                aria-label="Pagination"
                className="pagination mb-0 b-pagination justify-content-center"
              >
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <button
                    className="page-link"
                    onClick={() => setPageNumber(0)}
                    disabled={pageNumber === 0}
                  >
                    First
                  </button>
                </li>
                <li className={`page-item ${pageNumber === 0 ? "disabled" : ""}`}>
                  <button
                    className="page-link"
                    onClick={() => setPageNumber((prev) => prev - 1)}
                    disabled={pageNumber === 0}
                  >
                    Prev
                  </button>
                </li>

                {Array.from(Array(noOfPages).keys()).map((item) => (
                  <li
                    key={item}
                    className={`page-item ${pageNumber === item ? "active" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setPageNumber(item)}
                    >
                      {item + 1}
                    </button>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    pageNumber === noOfPages - 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setPageNumber((prev) => prev + 1)}
                    disabled={pageNumber === noOfPages - 1}
                  >
                    Next
                  </button>
                </li>
                <li
                  className={`page-item ${
                    pageNumber === noOfPages - 1 ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => setPageNumber(noOfPages - 1)}
                    disabled={pageNumber === noOfPages - 1}
                  >
                    Last
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
