import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "../initialState";
export const accountSlice = createSlice({
  name: "accountData",
  initialState: INITIAL_STATE,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setMatchList: (state, action) => {
      state.matchList = {
        ...state.matchList,
        ...action.payload,
      };
    },
    setUserProfile: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
      };
    },
    setLoginPopSate: (state, action) => {
      state.ui.loginPopOpen = action.payload;
    },
    setChangePopSate: (state, action) => {
      state.ui.changePasswordPopOpen = action.payload;
    },
    setChangeButtonState: (state, action) => {
      state.ui.changeButtonPopOpen = action.payload;
    },
    setMyMarketPopState: (state, action) => {
      state.ui.myMarketPopOpen = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    logOut: (state, action) => {
      state.userData = {};
    },
  },
});

export default accountSlice.reducer;
export const {
  setUserData,
  setUserProfile,
  setLoginPopSate,
  setMatchListReducer,
} = accountSlice.actions;

  
