import { API } from "../service/API";


export const encryptData = async (payload) => {
  try {
    let response = await API.get("getPublicKey");
    let publicKey = response.data.publicKey;
    if (publicKey) {
      const data = JSON.stringify(payload);
      const textBuffer = new TextEncoder().encode(data);

      const binaryString = window.atob(publicKey);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const cryptoKey = await window.crypto.subtle.importKey(
        "spki",
        bytes,
        {
          name: "RSA-OAEP",
          hash: "SHA-256",
        },
        true,
        ["encrypt"]
      );
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        cryptoKey,
        textBuffer
      );

      // Convert encryptedData to base64
      const base64EncryptedData = btoa(
        String.fromCharCode(...new Uint8Array(encryptedData))
      );

      return base64EncryptedData;
    } else {
      throw new Error("Public key not available");
    }
  } catch (e) {
    console.log("Encryption error:", e);
    throw e;
  }
};
