import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updatePassword } from "../api/updatePassAPI";

const initialState = {
  isSuccess: false,
  response: {},
  error: null,
};

export const updatePasswordData = createAsyncThunk(
  "updatePass/updatePasswordData",
  async ({ uid, oldPassword, password, token }, { rejectWithValue }) => {
    try {
      const data = {
        uid,
        old_password: oldPassword,
        new_password: password,
      };
      const response = await updatePassword(data, token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassSlice = createSlice({
  name: "updatePass",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePasswordData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updatePasswordData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
        state.error = null;
      })
      .addCase(updatePasswordData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updatePassSlice.reducer;
