import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { fetchThirtyTwoCardStack } from "../../redux/features/thirtyTwoCardGameSlice";
import { API } from "../../service/API";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import CasinoBetModal from "./CasinoBetModal";
import Timer from "./components/Timer";

export default function Card32A() {
  const id = 1;
  const { user } = useSelector((state) => state.auth);
  const token = user?.token;
  const uid = user.data.resultid;
  console.log("id1", user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [midRound, setMidRound] = useState(0);
  const [showresultModal, setShowresultModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [betDetails, setbetDetails] = useState({});
  const [history, setHistory] = useState([]);

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult 32:", response);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("32cardA", parsedData[0].reqResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);
  const handleModalPopUp = () => {
    setShowModal(!showModal);
  };
  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    handleModalPopUp(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nation === betDetails.nation
      );
      console.log("check", filteredData, betDetails);

      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails.multipler === filteredData[0].l1 ||
        betDetails.multipler === filteredData[0].b1)
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  useEffect(() => {
    console.log("id2", id);

    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      // console.log("===Get", data);
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  // const findCardImage = (cardId) => {
  //   const card = card52War.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  // const parseCardData = (cardData) => {
  //   return cardData?.split(",").map((card) => {
  //     const cardName = card.trim(); // Extract card name and trim any whitespace
  //     return cardName;
  //   });
  // };

  // const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.desc ?? "");
  // console.log("arr", arrayData);

  return (
    <>
      <div className="center-main-container casino-page ng-tns-c1798067373-5 ng-star-inserted">
        <div className="center-container ng-tns-c1798067373-5">
          <div className="casino-page-container ng-tns-c1798067373-5 cards32a">
            <div className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-5">
              <ul className="nav nav-tabs menu-tabs ng-tns-c1798067373-5">
                <li className="nav-item ng-tns-c1798067373-5">
                  <div className="ng-tns-c1798067373-5 active nav-link">
                    Game
                  </div>
                </li>
                <li className="nav-item ng-tns-c1798067373-5">
                  <div className="ng-tns-c1798067373-5 nav-link">
                    {" "}
                    Placed Bet (0){" "}
                  </div>
                </li>
              </ul>
              <div className="pe-2 ng-tns-c1798067373-5">
                <span className="casino-rid ng-tns-c1798067373-5">
                  <a className="d-block ng-tns-c1798067373-5">
                    <small className="ng-tns-c1798067373-5">Rules</small>
                  </a>{" "}
                  Round ID:{" "}
                  <span className="ng-tns-c1798067373-5 ng-star-inserted">
                    {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                  </span>
                  {/**/}
                </span>
              </div>
            </div>
            <div className="casino-header ng-tns-c1798067373-5">
              <span className="casino-name ng-tns-c1798067373-5">
                {" "}
                32 Cards A
                <a className="ms-1 d-xl-inline d-none ng-tns-c1798067373-5">
                  <small className="ng-tns-c1798067373-5">Rules</small>
                </a>
              </span>
              <span className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-5">
                <small className="ng-tns-c1798067373-5">
                  Round ID:{" "}
                  <span className="ng-tns-c1798067373-5 ng-star-inserted">
                    {reqRes?.data?.data?.t1[0]?.mid || 0.0}
                  </span>
                  {/**/}
                </small>
                <span className="ng-tns-c1798067373-5 ng-star-inserted">
                  {" "}
                  | Min: 100 {/**/}
                  {/**/} | Max: 100000 {/**/}
                  {/**/}
                </span>
                {/**/}
              </span>
            </div>
            {/**/}
            {/**/}
            <div className="ng-tns-c1798067373-5 ng-star-inserted">
              <div className="casino-video ng-tns-c1798067373-5">
                <div className="video-box-container ng-tns-c1798067373-5">
                  <div className="casino-video-box ng-tns-c1798067373-5 ng-star-inserted">
                    {/* <iframe
                      className="ng-tns-c1798067373-5"
                      src={CASINO_VIDEO_URL + CASINO_VIDEO.thirtyTwoCardA}
                    /> */}
                     <iframe
                    height="100%"
                    width="100%"
                    src={`https://battlemoney.live/iframe/${id}`}
                    title=""
                  />
                  </div>
                  {/**/}
                </div>
                <div className="ng-tns-c1798067373-5 ng-star-inserted">
                  <div className="casino-video-cards ng-tns-c1798067373-5 ng-star-inserted">
                    <div className="ng-tns-c1798067373-5 ng-star-inserted">
                      <h5 className="ng-tns-c1798067373-5 ng-star-inserted">
                        {" "}
                        Player 8:{" "}
                        <span className="text-warning ng-tns-c1798067373-5 ng-star-inserted">
                          20
                        </span>
                      </h5>
                      <div className="flip-card-container ng-tns-c1798067373-5 ng-star-inserted">
                        <div className="flip-card ng-tns-c1798067373-5">
                          <div className="flip-card-inner ng-tns-c1798067373-5">
                            <div className="flip-card-front ng-tns-c1798067373-5">
                              <img
                                className="ng-tns-c1798067373-5"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/QSS.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-1 ng-tns-c1798067373-5">
                      <h5 className="ng-tns-c1798067373-5 text-success ng-star-inserted">
                        {" "}
                        Player 9:{" "}
                        <span className="text-warning ng-tns-c1798067373-5 ng-star-inserted">
                          22
                        </span>
                      </h5>
                      <div className="flip-card-container ng-tns-c1798067373-5 ng-star-inserted">
                        <div className="flip-card ng-tns-c1798067373-5">
                          <div className="flip-card-inner ng-tns-c1798067373-5">
                            <div className="flip-card-front ng-tns-c1798067373-5">
                              <img
                                className="ng-tns-c1798067373-5"
                                src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/KSS.webp"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-1 ng-tns-c1798067373-5" />
                    <div className="mt-1 ng-tns-c1798067373-5" />
                  </div>
                </div>

                <Timer value={reqRes?.data?.data?.t1[0]?.autotime || 0} />
              </div>

              <div className="casino-detail ng-tns-c1798067373-5">
                <div className="casino-table ng-tns-c1798067373-5 ng-star-inserted">
                  <div className="casino-table-box ng-tns-c1798067373-5">
                    <div className="casino-table-left-box ng-tns-c1798067373-5">
                      <div className="casino-table-header ng-tns-c1798067373-5">
                        <div className="casino-nation-detail ng-tns-c1798067373-5">
                          <div className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-5">
                            <span className="min_max_span ms-0 ng-tns-c1798067373-5">
                              <b className="ng-tns-c1798067373-5">Min:</b>
                              <span className="ng-tns-c1798067373-5">
                                {" "}
                                100 {/**/}
                                {/**/}
                              </span>
                            </span>
                            <span className="min_max_span ms-1 ng-tns-c1798067373-5">
                              <b className="ng-tns-c1798067373-5">Max:</b>
                              <span className="ng-tns-c1798067373-5">
                                {" "}
                                100000 {/**/}
                                {/**/}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="casino-odds-box back ng-tns-c1798067373-5">
                          Back
                        </div>
                        <div className="casino-odds-box lay ng-tns-c1798067373-5">
                          Lay
                        </div>
                      </div>
                      <div className="casino-table-body ng-tns-c1798067373-5">
                        <div className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted">
                          <div className="casino-nation-detail ng-tns-c1798067373-5">
                            <div className="casino-nation-name ng-tns-c1798067373-5">
                              {" "}
                              Player 8{" "}
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 1 ? (
                                    <span
                                    style={{
                                      color:
                                        ele.amount >= 0 ? "green" : "red",
                                    }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[0]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[0]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0].b1,
                                      reqRes?.data?.data?.t2[0].nation,
                                      1
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[0].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5"> 1M </p>
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[0].l1,
                                      reqRes?.data?.data?.t2[0].nation,
                                      2
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[0].l1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    509842{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted">
                          <div className="casino-nation-detail ng-tns-c1798067373-5">
                            <div className="casino-nation-name ng-tns-c1798067373-5">
                              {" "}
                              Player 9{" "}
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 2 ? (
                                    <span
                                    style={{
                                      color:
                                        ele.amount >= 0 ? "green" : "red",
                                    }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[1]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[1]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1].b1,
                                      reqRes?.data?.data?.t2[1].nation,
                                      3
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[1].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    722658{" "}
                                  </p>
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[1].l1,
                                      reqRes?.data?.data?.t2[1].nation,
                                      4
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[1].l1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    403467{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-box-divider ng-tns-c1798067373-5" />
                    <div className="casino-table-right-box ng-tns-c1798067373-5">
                      <div className="casino-table-header d-none d-md-flex ng-tns-c1798067373-5">
                        <div className="casino-nation-detail ng-tns-c1798067373-5" />
                        <div className="casino-odds-box back ng-tns-c1798067373-5">
                          Back
                        </div>
                        <div className="casino-odds-box lay ng-tns-c1798067373-5">
                          Lay
                        </div>
                      </div>
                      <div className="casino-table-body ng-tns-c1798067373-5">
                        <div className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted">
                          <div className="casino-nation-detail ng-tns-c1798067373-5">
                            <div className="casino-nation-name ng-tns-c1798067373-5">
                              {" "}
                              Player 10{" "}
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 3 ? (
                                    <span
                                    style={{
                                      color:
                                        ele.amount >= 0 ? "green" : "red",
                                    }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[2]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[2]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2].b1,
                                      reqRes?.data?.data?.t2[2].nation,
                                      5
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[2].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    829649{" "}
                                  </p>
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[2].l1,
                                      reqRes?.data?.data?.t2[2].nation,
                                      6
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[2].l1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    145973{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <div className="casino-table-row ng-tns-c1798067373-5 ng-star-inserted">
                          <div className="casino-nation-detail ng-tns-c1798067373-5">
                            <div className="casino-nation-name ng-tns-c1798067373-5">
                              {" "}
                              Player 11{" "}
                              <p className="ng-tns-c1798067373-5">
                                {" "}
                                {thirtyTwoCardStack.map((ele) =>
                                  ele.number === 4 ? (
                                    <span
                                    style={{
                                      color:
                                        ele.amount >= 0 ? "green" : "red",
                                    }}
                                    >
                                      {ele.amount}
                                    </span>
                                  ) : null
                                )}{" "}
                              </p>
                            </div>
                          </div>
                          {reqRes?.data?.data?.t2[3]?.gstatus === "SUSPENDED" ||
                          reqRes?.data?.data?.t2[3]?.gstatus === "CLOSED" ? (
                            <div className="ng-tns-c1798067373-5 casino_odds_row suspended-box">
                              <div className="casino-odds-box back ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                              <div className="casino-odds-box lay ng-tns-c1798067373-5">
                                <span className="casino-odds ng-tns-c1798067373-5">
                                  <i className="fa fa-lock" />
                                </span>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="ng-tns-c1798067373-5 casino_odds_row ">
                                <div
                                  className="casino-odds-box back ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3].b1,
                                      reqRes?.data?.data?.t2[3].nation,
                                      7
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[3].b1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    388485{" "}
                                  </p>
                                </div>
                                <div
                                  className="casino-odds-box lay ng-tns-c1798067373-5"
                                  onClick={() => {
                                    handleModal(
                                      reqRes?.data?.data?.t2[3].l1,
                                      reqRes?.data?.data?.t2[3].nation,
                                      6
                                    );
                                  }}
                                >
                                  <span className="casino-odds ng-tns-c1798067373-5">
                                    {reqRes?.data?.data?.t2[3].l1}
                                  </span>
                                  <p className="ng-tns-c1798067373-5">
                                    {" "}
                                    941510{" "}
                                  </p>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ng-tns-c1798067373-5">
                  <div className="casino-last-result-title ng-tns-c1798067373-5">
                    <span className="ng-tns-c1798067373-5">Last Result</span>
                    <span className="ng-tns-c1798067373-5">
                      <a className="ng-tns-c1798067373-5">View All</a>
                    </span>
                  </div>
                 <div className="casino-last-results ng-tns-c1798067373-5 ng-star-inserted">
                    {results?.data?.result?.map((result, index) => {
                      const buttonValue = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );
                      return (
                        <span
                          className="ng-tns-c1798067373-5 result result-b ng-star-inserted"
                          key={index}
                        >
                          <span className="ng-tns-c1798067373-5">
                            {" "}
                            {buttonValue && (
                              <>{buttonValue?.nation?.split(" ")[1]}</>
                            )}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="ng-tns-c1798067373-5 d-none matchbettable w-100 ng-star-inserted">
              <div className="table-responsive ng-tns-c1798067373-5">
                <table className="table ng-tns-c1798067373-5">
                  <thead className="ng-tns-c1798067373-5">
                    <tr className="ng-tns-c1798067373-5">
                      <th className="ng-tns-c1798067373-5">Matched Bet</th>
                      <th className="text-end ng-tns-c1798067373-5">Odds</th>
                      <th className="text-end ng-tns-c1798067373-5">Stake</th>
                    </tr>
                  </thead>
                  {/**/}
                </table>
              </div>
            </div>
          </div>
          {/**/}
        </div>
        <CasinoBetModal
                id={id}
                name={name}
                modalOpen={showModal}
                betDetails={betDetails}
                setbetDetails={setbetDetails}
                setShowModal={setShowModal}
                betHistory={history}
                realTime={realTime}
                setRealTimeData={setRealTimeData}
              />
      
      </div>
    </>
  );
}
