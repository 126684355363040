import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { placeBet } from "../../../redux/features/betSlice";
import { toast } from "react-hot-toast";
import { MdScreenshotMonitor } from "react-icons/md";

import { calculateProfit } from "../../../redux/api/calculateProfit";
import { resetProfitData } from "../../../redux/features/calculateProfitSlice";
import { fetchDashbetHistory } from "../../../redux/features/dashbetHistorySlice";
import { LastBetDetails } from "../../../redux/features/LastbetSlice";
import { fetchbetStackData } from "../../../redux/api/betStackAPI";
import { fetchSessionStackData } from "../../../redux/api/sessionStackAPI";
import { fetchBetButton } from "../../../redux/features/betButtonSlice";
import { getLiveStreamingUrlAPI } from "../../../service/AuthService";

export default function Modal({
  game,
  betHandler,
  chID,
  setModal,
  setbetType,
  setmarketType,
  setbetDetails,
  betDetails,
  betType,
  marketType,
  countryName,
  realtimeData,
  setRealtimeData,
  gameEvent,
  modalOpen,
}) {
  // const [channelId, setChannelId] = useState();
  // const getLiveStreamingUrl = async () => {
  //   const { response } = await getLiveStreamingUrlAPI(gameEvent?.eventId);
  //   setChannelId(response);
  // };

  // useEffect(() => {
  //   getLiveStreamingUrl();
  // }, [gameEvent?.eventId]);

  const [isTvOpen, setIsTvOpen] = useState(true);

  const { user } = useSelector((state) => state.auth);
  const token = user.token;
  console.log("ttttt", chID);

  const uid = user.data.resultid;
  // const token = sessionStorage.getItem("token");
  // const uid = sessionStorage.getItem("userData");
  // console.log("uid", uid);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // const [isTvOpen, setIsTvOpen] = useState(true);
  const { dashbetHistory } = useSelector((state) => state.dashbetHistory);

  const { id } = useSelector((state) => state.auth.currentMatch);
  const { Profitdata } = useSelector((state) => state.userProfit);
  const modalRef = useRef();

  const { buttonData } = useSelector((state) => state.buttonData);
  console.log("eeee", buttonData);
  const [count, setCount] = useState(0);
  useEffect(() => {
    dispatch(fetchBetButton({ id: uid, token }));
  }, [dispatch, uid]);

  const handleStake = (value) => {
    setbetDetails({ ...betDetails, stake: value, uid });
    dispatch(
      calculateProfit({
        stake: value,
        marketType,
        back:
          betType === "back"
            ? betDetails.Backprice || betDetails.BackPrice2
            : 0,
        lay:
          betType === "lay" ? betDetails.Layprice || betDetails.LayPrice2 : 0,
        token,
      })
    );
  };

  const getPrice = () => {
    if (betType === "back") {
      return betDetails?.Backprice ?? betDetails?.BackPrice2;
    }
    return betDetails?.Layprice ?? betDetails?.LayPrice2;
  };

  // console.log("sportModal", betDetails);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setModal(false);
    setCount(count + 1);
    // console.log("bet submit", realtimeData);
    try {
      if (!realtimeData) {
        toast.error("Odd Value is miss Match");
        return;
      }
      if (betDetails.stake <= 0) {
        toast.error("Invalid Stake");
        return;
      }
      console.log("ddddddd", betDetails, marketType, countryName);

      let responce = await dispatch(
        placeBet({ betDetails, marketType, token })
      ).unwrap();
      console.log("asd");

      if (responce.data === "Bet Placed.") {
        toast.success(responce.data);
        if (marketType === "Session Market") {
          dispatch(
            fetchSessionStackData({
              userId: uid,
              id: id,
              mname: countryName,
            })
          );
        } else {
          dispatch(
            fetchbetStackData({
              userId: uid,
              mId: id,
              matchType: marketType,
              token,
            })
          );
        }

        // if (marketType === "Match Odds") {
        dispatch(LastBetDetails({ userId: uid, id: id, token }));
        // }

        // dispatch(fetchuserBalance({ id: uid, token }));

        dispatch(resetProfitData());
        dispatch(fetchDashbetHistory({ userId: uid, token, id }));
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } else {
        toast.error(responce.data);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setRealtimeData(false);
      setbetType(null);
      setmarketType(null);
      setbetDetails({});
    }
  };

  useEffect(() => {
    if (betDetails) {
      modalRef?.current?.addEventListener("click", (e) => {
        if (e.target === modalRef?.current) {
          setbetDetails(null);
        }
      });
    }
  }, [betDetails]);
  // useEffect(() => {
  //   const modalClickHandler = (e) => {
  //     if (e.target === modalRef?.current) {
  //       setbetDetails({});
  //     }
  //   };
  //   if (modalOpen && betDetails) {
  //     modalRef?.current?.addEventListener("click", modalClickHandler);
  //   }

  //   return () => {
  //     modalRef?.current?.removeEventListener("click", modalClickHandler);
  //   };
  // }, [betDetails, modalOpen]);

  return (
    <>
      {window.innerWidth < 768 && betDetails && modalOpen && (
        <div
          role="dialog"
          aria-modal="true"
          ref={modalRef}
          className="fade modal show"
          tabIndex={0}
          style={{ display: "block" }}
          // onClick={() => {
          //   // setModal(false);
          //   setbetType(null);
          //   setmarketType(null);
          //   setbetDetails({});
          // }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">Place Bet</div>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setModal(false);
                    setbetDetails({});
                  }}
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="place-bet-modal back">
                  <div className="row align-items-end">
                    <div className="col-6">
                      <b>{betDetails.betRunnerName || betDetails.team}</b>
                    </div>
                    <div className="col-6">
                      <div className="float-end">
                        <button className="stakeactionminus btn">
                          <span className="fa fa-minus" />
                        </button>
                        <input
                          type="text"
                          className="stakeinput"
                          value={getPrice()}
                          defaultValue={getPrice()}
                          disabled={loading}
                        />
                        <button className="stakeactionminus btn">
                          <span className="fa fa-plus" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-4">
                      <input
                        type="number"
                        disabled={loading}
                        className="stakeinput w-100"
                        defaultValue={betDetails?.stake}
                        value={betDetails?.stake}
                        onChange={(e) => setbetDetails(e.target.value)}
                      />
                    </div>
                    <div className="col-4 d-grid">
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary btn-block"
                        disabled={loading}
                      >
                        {loading ? (
                          <i className="fa fa-spinner fa-spin" />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                    <div className="col-4 text-center pt-2">
                      <span>
                        {" "}
                        {parseFloat(Profitdata?.odds || 0).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="place-bet-buttons mt-2">
                    {buttonData.map((item, index) => (
                      <button
                        disabled={loading}
                        className="btn btn-place-bet"
                        key={index}
                        onClick={() => {
                          handleStake(item.price_value);
                        }}
                      >
                        {item.price_value}
                      </button>
                    ))}
                  </div>
                  <div className="mt-3 d-flex justify-content-between align-items-center">
                    <button disabled={loading} className="btn btn-info">
                      Edit
                    </button>
                  </div>
                  {/*{game == "cricket" &&
                    betDetails?.runnersAndExposure?.map((runner) => (
                      <div className="row mt-2">
                        <div className="col-4">
                          <span>{runner?.runnerName}</span>
                        </div>
                        <div
                          style={{
                            color: runner?.exposure > 0 ? "green" : "red",
                          }}
                          className="col-4 text-center"
                        >
                          <span>{runner?.exposure}</span>
                        </div>
                        <div
                          style={{
                            color: runner?.preExposure > 0 ? "green" : "red",
                          }}
                          className="col-4 text-end"
                        >
                          <span>{runner?.preExposure}</span>
                        </div>
                      </div>
                    ))}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="sidebar right-sidebar">
        <div className={isTvOpen ? "live-tv-card" : "live-tv-card-isTvOpen"}>
          <a
            className="bet-nation-game-name blink-message"
            href="/casino/3cardj"
          >
            <i className="fas fa-info-circle me-1" />
            <div>3 Cards Judgement</div>
          </a>
          <div className="sidebar-box">
            <div
              // onClick={() => {
              //   setIsTvOpen(!isTvOpen);
              // }}
              className="sidebar-title"
            >
              <div className="d-flex mt-1 ms-1 ">
                <MdScreenshotMonitor className="tv-icon" />
                <p className="ms-2 mb-2">LIVE Match</p>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  value={isTvOpen}
                  onChange={() => setIsTvOpen(!isTvOpen)}
                />
              </div>
            </div>
          </div>
          {isTvOpen && (
            <iframe
              allowFullScreen={true}
              style={{
                width: "100%",
                height: "210px",
                border: "none",
                opacity: "1",
                visibility: "visible",
                color: "white ",
              }}
              src={`https://video.proexch.in/tv/static?chid=${chID}`}

              // src={`https://video.proexch.in/tv/static?chid=${channelId}`}
            />
          )}
        </div>

        {betDetails && modalOpen && (
          <div className="sidebar-box place-bet-container">
            <div className="sidebar-title">
              <h4>Place Bet</h4>
            </div>
            <div className={`place-bet-box ${betDetails?.type}`}>
              <div className="place-bet-box-header">
                <div className="place-bet-for">(Bet for)</div>
                <div className="place-bet-odds">Odds</div>
                <div className="place-bet-stake">Stake</div>
                <div className="place-bet-profit">Profit</div>
              </div>
              <div className="place-bet-box-body">
                <div className="place-bet-for">
                  <span>{betDetails?.betRunnerName}</span>
                </div>
                <div className="place-bet-odds">
                  <input
                    type="text"
                    className="form-control"
                    disabled={loading}
                    value={getPrice()}
                    defaultValue={getPrice()}
                  />
                  <div className="spinner-buttons input-group-btn btn-group-vertical">
                    <button className="btn-default">
                      <i className="fa fa-angle-up" />
                    </button>
                    <button className="btn-default">
                      <i className="fa fa-angle-down" />
                    </button>
                  </div>
                </div>
                <div className="place-bet-stake">
                  <input
                    type="number"
                    className="form-control"
                    disabled={loading}
                    defaultValue={betDetails?.stake}
                    value={betDetails?.stake}
                    onChange={(e) => e.target.value}
                  />
                </div>
                <div className="place-bet-profit">
                  {parseFloat(Profitdata?.odds || 0).toFixed(2)}
                </div>
              </div>
              <div className="place-bet-buttons">
                {buttonData.map((item, index) => (
                  <button
                    disabled={loading}
                    className="btn btn-place-bet"
                    key={index}
                    onClick={() => {
                      handleStake(item.price_value);
                    }}
                  >
                    {item.price_value}
                  </button>
                ))}
              </div>
              <div className="place-bet-action-buttons">
                <div>
                  <button disabled={loading} className="btn btn-info">
                    Edit
                  </button>
                </div>
                <div>
                  <button
                    disabled={loading}
                    onClick={() => setbetDetails(null)}
                    className="btn btn-danger me-1"
                  >
                    Reset
                  </button>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-success"
                    disabled={loading}
                  >
                    {loading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="sidebar-box my-bet-container">
          <div className="sidebar-title">
            <h4>My Bet</h4>
          </div>
          <div className="my-bets">
            <div className="table-responsive w-100">
              <table className="table">
                <thead>
                  <tr>
                    <th>Matched Bet</th>
                    <th className="text-end">Odds</th>
                    <th className="text-end">Stake</th>
                  </tr>
                </thead>
                <tbody>
                  {dashbetHistory?.map((bet) => (
                    <tr className={bet?.back ? "back" : "lay"}>
                      <td>
                        <span className="bet-type">{bet?.team}</span>
                        <span className="bet-name">{bet?.back > 0 ? "BACK" : "LAY"}</span>
                      </td>
                      <td className="text-end">
                        <span className="bet-odd">
                          {bet?.back ? bet?.back : bet?.lay}
                        </span>
                      </td>
                      <td className="text-end">
                        <span className="bet-stake">{bet.stake}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

